import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BackupSubscription, GuidRegExPattern } from '@sk-models';
import { ApiService } from 'app/api-service/api.service';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'sk-subscription-search-input',
  templateUrl: './subscription-search-input.component.html',
  styleUrls: ['./subscription-search-input.component.scss']
})
export class SubscriptionSearchInputComponent implements OnInit {

  @Input() searchTerm: string = null;
  @Output() subscriptionSelect = new EventEmitter<BackupSubscription>();


  searchForm: FormGroup = new FormGroup({
    subscriptionSearchInput: new FormControl('', [Validators.pattern(GuidRegExPattern), Validators.required])
  });
  filteredSubscriptions: BackupSubscription[] = [];
  timeDelay = 300;
  isLoading = false;
  firstLoad = true;

  constructor(
    private apiService: ApiService
  ) {
    // do nothing more
  }

  get inputSearch(): AbstractControl {
    return this.searchForm.get('subscriptionSearchInput');
  }

  ngOnInit(): void {
    this.inputSearch
      .valueChanges
      .pipe(
        debounceTime(this.timeDelay),
        filter(() => {
          if(!this.searchForm.valid) {
            this.filteredSubscriptions = [];
            return false;
          }
          return true;
        }),
        tap(() => this.isLoading = true),
        switchMap((searchTerm) => this.searchBySubscriptionId(searchTerm))
      )
      .subscribe(subscriptions => {
        this.filteredSubscriptions = subscriptions;
        this.isLoading = false;
        if (this.firstLoad && this.searchTerm && this.filteredSubscriptions?.length === 1) {
          this.navigateToSubscription(this.filteredSubscriptions[0]);
        }
        this.firstLoad = false;
      });

    if (this.searchTerm) {
      this.inputSearch.setValue(this.searchTerm);
    }
  }

  searchBySubscriptionId(searchTerm: string | unknown): Observable<BackupSubscription[]> {
    // This gets fired when the user types OR when the user selects an item from the drop down
    if (!searchTerm || typeof searchTerm !== 'string') {
      return of([]);
    } else {
      return this.apiService.getSubscriptionsBySubscriptionId(searchTerm);
    }
  }

  backupServiceSelected(event: MatAutocompleteSelectedEvent): void {
    this.navigateToSubscription(<BackupSubscription>(event.option.value));
  }

  navigateToSubscription(data: BackupSubscription): void {
    this.subscriptionSelect.emit(data);
  }

}
