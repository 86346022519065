<div class="section-header">
  <div class="section-header-title flex-container flex-row">
    <div class="title flex-grow align-items-center">
      <ng-content select="[slot=title-prefix]"></ng-content>
      {{title}}
      <span *ngIf="info" title="{{info}}">
        <mat-icon>info</mat-icon>
      </span>
    </div>
    <div class="actions">
      <ng-content></ng-content>
    </div>
  </div>
</div>
