import {
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import * as moment from 'moment';

import {
  BackupJob,
  GenericModalOptions,
  ResourceKey,
  RestoreReindexRequest,
  RestoreRequeueRequest,
} from '@sk-models';
import {
  JobsService,
  MessageService,
} from '@sk-services';

import { ApiService } from '../../api-service/api.service';
import {
  RestoreResultExportComponent,
} from '../restore-result-export/restore-result-export.component';
import {
  RestoreSkippedItemsExportComponent,
} from '../restore-skipped-items-export/restore-skipped-items-export.component';
import {
  RestoredItemsModalComponent,
} from '../restored-items/restored-items-modal/restored-items-modal.component';
import {
  SkippedRestoreItemsModalComponent,
} from '../skipped-restore-items/skipped-restore-items-modal.component/skipped-restore-items-modal.component';
import {
  TargetRestoreItemsModalComponent,
} from '../target-restore-items/target-restore-items-modal.component/target-restore-items-modal.component';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'sk-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  @Input() job: BackupJob;

  resultsCollapsed = true;
  rawEventsCollapsed = true;
  isLoading = false;

  // time interval between heartbeats, used because when a cancellation event is received, it sets the
  // resource's machine state back to idle. The job itself doesn't actually spin down until the next heartbeat happens. We want to
  // ensure job is spun down before allowing new job to be started
  timeIntervalMinutes = 30;

  subscriptionJobTypes = [
    'restore',
    'redirectionrestore',
    'archivedorderrestore',
    'snapshot', // in the rare case where snapshot type can't be determined Snapshot is the job type
    'fullsnapshot',
    'fastsnapshot'
  ];

  // job types that allow force finish functionality
  jobTypesWithForceFinish = [
    'archivedorderrestore',
    'fastsnapshot',
    'fullsnapshot',
    'redirectionrestore',
    'restore',
    'snapshot',
  ];

  constructor(
    private apiService: ApiService,
    private jobsService: JobsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private messageService: MessageService
  ) {
    // Attempt to grab the job from the current nav object. The extra state data is lost
    // after the component construction so this is the only place you can attempt to do this.
    const currentNav = this.router.getCurrentNavigation();
    if (currentNav &&
        currentNav.extras &&
        currentNav.extras.state &&
        currentNav.extras.state.jobData) {
      const parsedJob: BackupJob = JSON.parse(currentNav.extras.state.jobData);
      this.job = parsedJob;
    }
  }

  ngOnInit(): void {
    // If the job wasn't passed into the component, go get it.
    if (!this.job) {
      this.getJob();
    }
  }

  getJob(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('id');
    const subscriptionId = this.activatedRoute.snapshot.paramMap.get('subscriptionId');
    const jobId = this.activatedRoute.snapshot.paramMap.get('jobId');

    let startedDate: string;

    this.activatedRoute.queryParams.subscribe(params => {
      startedDate = params['startDate'];
    });

    if (jobId) {
      this.isLoading = true;
      if (startedDate) {
        this.apiService.fetchJobByDate(jobId, startedDate)
          .subscribe({
            next: j => this.job = this.jobsService.computeJobProperties(j),
            complete: () => this.isLoading = false
          });
      } else if (subscriptionId) {
        this.apiService.fetchSubscriptionJob(jobId)
          .subscribe({
            next: j => this.job = this.jobsService.computeJobProperties(j),
            complete: () => this.isLoading = false
          });
      } else {
        this.apiService.fetchOrderJob(jobId, orderId)
          .subscribe({
            next: j => this.job = this.jobsService.computeJobProperties(j),
            complete: () => this.isLoading = false
          });
      }
    }
  }

  extendedRangeQuery(): void {
    this.isLoading = true;
    const jobId = this.activatedRoute.snapshot.paramMap.get('jobId');

    this.apiService.fetchSubscriptionJob(jobId, true)
      .subscribe({
        next: j => {
          this.job = this.jobsService.computeJobProperties(j);
          this.messageService.openModal('Older job details have been queried!');
        },
        complete: () => this.isLoading = false
      });
  }

  exitClicked(): void {
    const jobHistoryUrl = this.router.url.slice(0, this.router.url.lastIndexOf('/'));
    this.router.navigateByUrl(jobHistoryUrl);
  }

  collapseExpandedSections(): void {
    this.resultsCollapsed = true;
    this.rawEventsCollapsed = true;
  }

  cancelJob(): void {
    this.messageService.openConfirmModal("Are you sure you want to cancel this job?").subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.handleCancelJob();
      }
    });
  }

  handleCancelJob(): void {
    this.isLoading = true;
    this.apiService.setJobFinish(this.job.id, this.job.type, this.job.orderId, this.job.resourceKey)
      .subscribe({
        next: response => {
          if (response.message) {
            this.messageService.openModal(response.message);
          } else {
            this.messageService.openModal('Oops, something may have gone wrong. Contact SWAT.');
          }
        },
        complete: () => this.isLoading = false
      });
  }

  openDataDogLogs(): void {
    const toTimestamp =  this.job.finished ? moment(this.job.finished).add(1, 'hours').valueOf() : moment().valueOf();
    const fromTimestamp = moment(this.job.created).add(-1, 'hours').valueOf();
    const url = `https://app.datadoghq.com/logs?query=%40Properties.JobId%3A${this.job.id}&index=backup&from_ts=${fromTimestamp}`
      + `&to_ts=${toTimestamp}&live=false`;
    window.open(url);
  }

  canShowRestoredItems(): boolean {
    return this.job.finished && this.job.type.toLowerCase().includes('restore');
  }

  canShowShowEvents(): boolean {
    return this.subscriptionJobTypes.includes(this.job.type.toLowerCase());
  }

  canShowExtendedRangeQueryButton(): boolean {
    return !this.job.created &&
           !this.job.started &&
           !this.isLoading;
  }

  showSkippedItemsReport(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resourceKey: this.job.resourceKey,
      redirectSourceKey: this.job.restoreParameters?.sourceKey,
      jobId: this.job.id
    };
    const dialogRef = this.dialog.open(SkippedRestoreItemsModalComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  showExportSkippedItemsReportDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      resourceKey: this.job.resourceKey,
      redirectSourceKey: this.job.restoreParameters?.sourceKey,
      restoreId: this.job.id
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RestoreSkippedItemsExportComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  showRestoreTargets(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.job;
    const dialogRef = this.dialog.open(TargetRestoreItemsModalComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  showRestoredItems(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.job;
    const dialogRef = this.dialog.open(RestoredItemsModalComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  showExportRestoreResultDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.job;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RestoreResultExportComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  showErrorStackTrace(): void {
    this.messageService.openCustomModal(GenericModalComponent, this.job.errorStackTrace, "Stack Trace");
  }

  hasActions(): boolean {
    return this.hasForceFinishAction() || this.hasRequeueRestoreAction() || this.canReindexRestore();
  }

  hasForceFinishAction(): boolean {
    // Check if finished and if a job type with force finish functionality
    if (!this.job.finished && this.isJobTypeWithForceFish()) {
      return true;
    }
    return false;
  }

  isJobTypeWithForceFish(): boolean {
    return this.jobTypesWithForceFinish.includes(this.job.type.toLowerCase());
  }

  isOldJob(): boolean {
    const currentDate = moment();
    const startMoment = moment(this.job.started);
    const daysDifference = currentDate.diff(startMoment, 'days');

    return daysDifference > 90;
  }


  canReindexRestore(): boolean {
    // Job must be finished AND a restore job and NOT TeamChats
    return this.job.finished != null
      && this.job.type.toLowerCase().includes('restore')
      && this.job.resourceType != 'TeamChats';
  }

  reindexRestore(): void {
    this.messageService.openConfirmModal('Are you sure you want to reindex this restore?').subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.handleReindexRestore();
      }
    });
  }

  handleReindexRestore(): void {
    this.isLoading = true;

    const data: RestoreReindexRequest = {
      startDate: this.job.started,
      endDate: this.job.finished,
      resourceKey: this.job.resourceKey
    };

    this.apiService.reindexRestore(this.job.id, data)
      .subscribe({
        next: result => {
          if (result && result.success) {
            this.messageService.openModal('Restore reindex has been requested');
          } else {
            this.messageService.openModal('Unable to reindex restore');
          }
        },
        complete: () => this.isLoading = false
      });
  }

  hasRequeueRestoreAction(): boolean {
    if (!this.isOldJob() && this.job.finished) {
      return this.job.type.toLowerCase().includes('restore');
    }
    return false;
  }

  requeueRestoreJob(queueNewJob: boolean): void {
    const jobType = queueNewJob ? "NEW job" : "same job"

    this.messageService.openConfirmModal(`Are you sure you want to Requeue Restore (${jobType})?`).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.handleRequeueRestoreJob(queueNewJob);
      }
    });
  }

  handleRequeueRestoreJob(queueNewJob: boolean): void {
    this.isLoading = true;
    const orderId = this.activatedRoute.snapshot.paramMap.get('id');

    const restoreRequeueRequest = new RestoreRequeueRequest;
    restoreRequeueRequest.backupServiceId = orderId;
    restoreRequeueRequest.resourceKey = this.job.resourceKey;
    restoreRequeueRequest.jobSerialization = this.job.rawJobSerialization;
    restoreRequeueRequest.queueNewJob = queueNewJob;

    this.apiService.fetchRestoreMachineState(orderId, this.job.resourceKey)
      .subscribe({
        next: snapshotState => {
          const isNotProduction = environment.environment !== 'prod';
          const minSinceLastUpdate = moment().diff(snapshotState.updatedTime, 'minutes');
          if (minSinceLastUpdate >= this.timeIntervalMinutes || isNotProduction) {
            this.apiService.requeueRestoreJob(this.job.id, restoreRequeueRequest)
              .subscribe({
                next: result => {
                  if (result && result.success) {
                    this.messageService.openCustomModal(GenericModalComponent, 'Restore job has been requeued');
                  } else {
                    this.messageService.openCustomModal(GenericModalComponent, 'Unable to requeue job');
                  }
                },
                complete: () => this.isLoading = false
              });
          } else {
            const timeDiff = this.timeIntervalMinutes - minSinceLastUpdate;
            this.messageService.openCustomModal(GenericModalComponent, `Previous job is still running. Please wait ${timeDiff} minutes and try again`);
          }
        },
        complete: () => this.isLoading = false });
  }

  getResourceCompositeId(resourceKey: ResourceKey): string {
    if (resourceKey) {
      return resourceKey.subscriptionType + '.' + resourceKey.resourceType + '.' + resourceKey.subscriptionId;
    } else {
      return 'N/A';
    }
  }

  showRestoreDetails(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('id');
    const subscriptionId = this.activatedRoute.snapshot.paramMap.get('subscriptionId');
    const resourceType = this.activatedRoute.snapshot.paramMap.get('resourceType');
    this.router.navigateByUrl(
      `orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/jobs/${this.job.id}/restore-details`);
  }

  showSnapshotDetails(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('id');
    const subscriptionId = this.activatedRoute.snapshot.paramMap.get('subscriptionId');
    const resourceType = this.activatedRoute.snapshot.paramMap.get('resourceType');
    this.router.navigateByUrl(
      `orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/jobs/${this.job.id}/snapshot-details`);
  }

  showJobSerialization(): void {
    this.messageService.openCustomModal(GenericModalComponent, this.job.rawJobSerialization, `${this.job.type} Serialization`, GenericModalOptions.ShowJson);
  }
}
