import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../api-service/api.service';
import { BackupAccessDetails } from '@sk-models';

@Component({
  selector: 'sk-access-app-auth',
  templateUrl: './access-app-auth.component.html',
  styleUrls: ['./access-app-auth.component.scss']
})
export class AccessAppAuthComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  accessStatuses = {};
  isExpanded = {};
  errors = {};
  isLoading = {};
  totalCount = {};
  errorCount = {};
  errorCountList = {};
  successCount = {};
  statusIcon = {};

  accessTypes = [
    'Exchange',
    'SharePoint',
    'Graph'
  ];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService) {
    for (const accessType of this.accessTypes) {
      this.isExpanded[accessType] = false;
      this.isLoading[accessType] = true;
    }
  }

  ngOnInit(): void {
    this.loadAccess();
  }

  loadAccess(reload = false): void {
    const orderId = this.route.snapshot.paramMap.get('id');
    for (const accessType of this.accessTypes) {
      this.apiService.getAccessDetails(orderId, accessType, reload, true)
        .subscribe({
          next: accessDetails => {
            this.accessStatuses[accessType] = accessDetails;
            this.totalCount[accessType] = accessDetails?.roleValidationStatuses?.length ?? 0;
            this.successCount[accessType] = 0;
            this.errorCount[accessType] = 0;
            if (accessDetails?.roleValidationStatuses) {
              this.calculateStatuses(accessDetails, accessType);
            }
          },
          error: errorDetails => {
            this.errors[accessType] = errorDetails.error.errorMessage;
            this.isLoading[accessType] = false;
          },
          complete: () => {
            this.isLoading[accessType] = false;
          }
        });
    }
  }

  calculateStatuses(statuses: BackupAccessDetails, accessType: string): void {
    for(let i = 0; i < statuses.roleValidationStatuses.length; i++){
      if(statuses.roleValidationStatuses[i].roleStatus == true){
        this.successCount[accessType]++;
      } else {
        this.errorCount[accessType] = this.errorCount[accessType] + 1;
      }
    }
    if(this.totalCount[accessType] == 0){
      this.statusIcon[accessType] = "muted";
    } else if(this.errorCount[accessType] != 0){
      this.statusIcon[accessType] = "warning";
    } else {
      this.statusIcon[accessType] = "success";
    }
  }

  getAccessTypeStatusIcon(accessType: string): string{
    if(this.totalCount[accessType] == 0){
      return "close";
    } else if (this.errorCount[accessType] != 0){
      return "warning";
    } else {
      return "check";
    }
  }
}
