<div class="bsp-content-container">
  <div class="d-flex flex-grow-1 title-container">
    <span class="title">{{ titleText }}</span>
  </div>
  <sk-job-history-day-range *ngIf="availableDays?.length > 0"
		[days]="availableDays"
		(daySelected)="onDaySelected($event)">
  </sk-job-history-day-range>
	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					Filters
				</mat-panel-title>
			</mat-expansion-panel-header>

			<form *ngIf="!isRefreshing" [formGroup]="filterForm" class="filter-form-content">
				<mat-grid-list [cols]="4" rowHeight="75px" class="filter-selectors">
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
              <mat-label>Job Type</mat-label>
							<mat-select formControlName="jobType">
								<mat-option [value]="'All'">All</mat-option>
								<mat-option *ngFor="let jobType of jobTypes" [value]="jobType">
									{{jobType ? jobType : '--'}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</mat-grid-tile>

					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
              <mat-label>Status</mat-label>
							<mat-select formControlName="jobStatus">
								<mat-option [value]="'All'">All</mat-option>
								<mat-option *ngFor="let jobStatus of jobStatuses" [value]="jobStatus">
									{{jobStatus ? jobStatus : '--'}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</mat-grid-tile>

					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
              <mat-label>Error Type</mat-label>
							<mat-select formControlName="errorType">
								<mat-option [value]="'All'">All</mat-option>
								<mat-option *ngFor="let errorType of errorTypes" [value]="errorType">
									{{errorType ? errorType : '--'}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</mat-grid-tile>

					<mat-grid-tile [rowspan]="1" [colspan]="1">
            <button mat-fab extended (click)="resetCurrentFilter()">
              <mat-icon>clear</mat-icon>
              Reset Filters
            </button>
					</mat-grid-tile>
				</mat-grid-list>
			</form>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="mat-elevation-z8 grid-container">
		<sk-click-to-refresh
			class="refresh"
			[shouldDisplay]="jobs && jobs.length > 0"
			[spin]="isRefreshing"
			(clickEvent)="reload()">
		</sk-click-to-refresh>

		<div class="table-container">
			<mat-table [dataSource]="dataSource" matSort>
				<ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef>
						<span>Id</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<sk-click-to-copy
							hoverText="{{row.id}}"
							[copyText]="row.id">
						</sk-click-to-copy>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="orderName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Order Name</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<a href="https://backupsupportportal.skykick.com/orders/{{row.orderId}}"
							title="Open the order in a new tab"
							target="_blank">
							{{row.restoreParameters?.backupOrderData?.orderName  || '--'}}
							<mat-icon>open_in_new</mat-icon>
						</a>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="type">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Type</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
            <a routerLink="{{selectJob(row)}}">{{row.type || '--'}}</a>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="status">
					<mat-header-cell *matHeaderCellDef>
						<span>Status</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>
							<mat-icon
								[class]="row.statusIconClass"
								[title]="row.statusIconTitle">
								{{row.statusIcon}}
							</mat-icon>
						</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="attempts">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Attempts</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{row.attempts || '--'}}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="created">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Created</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span *ngIf="row.created">{{row.created | friendlyDate}}</span>
						<mat-icon
							*ngIf="!row.created && !row.started"
							class="older-job-icon"
							title="Job is too old. Ask SWAT to load more data">
							cloud_download
						</mat-icon>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="started">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Started</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{(row.started | friendlyDate) || '--'}}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="finished">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Finished</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{(row.finished | friendlyDate) || '--'}}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="duration">
					<mat-header-cell *matHeaderCellDef>
						<span>Duration</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{row.durationTotal || '--'}}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="errorMessage">
					<mat-header-cell *matHeaderCellDef>
						<span>Error</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
            <a *ngIf="row.failed && row.errorStackTrace"
              href=""
							[title]="row.errorStackTrace"
							target="_blank"
              (click)="showStackTrace(row.errorStackTrace); $event.preventDefault();">
							{{ row.errorType }}
						</a>
						<span *ngIf="!row.failed || !row.errorStackTrace">--</span>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns">
				</mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns;">
				</mat-row>
			</mat-table>

			<div *ngIf="!jobs || isRefreshing" class="loading-spinner">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	</div>
</div>
