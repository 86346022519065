import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GraphQueryResponse } from '@sk-models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class GraphJourneyApiService {

  constructor(private httpClient: HttpClient) { }

  request(orderId: string, requestUrl: string): Observable<GraphQueryResponse> {

    const queryUrl = `${environment.api_url()}/graph/${orderId}/query`;

    const body = {
      uri: requestUrl,
      method: 'GET',
      betaEndpoint: false
    };
    return this.httpClient.post<GraphQueryResponse>(queryUrl, body);
  }
}
