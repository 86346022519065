// Just disable rules for the file, don't want to touch this if we don't have to.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable()
export class MomentDatesInterceptor implements HttpInterceptor {
  iso8601RegEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(tap(
        (event: HttpEvent<any>) => {
          if (!(event instanceof HttpResponse)) {
            return;
          }

          const newBody = this.convertDates((event as HttpResponse<any>).body);
          return (event as HttpResponse<any>).clone({
            body: newBody
          });
        }
      ));
  }

  convertDates = function(o: any ) {
    for (const key in o) {
      if (Object.prototype.hasOwnProperty.call(o, key)) {
        const value = o[key];
        const valueType = typeof(value);
        if (valueType === 'string' && this.iso8601RegEx.test(value)) {
          o[key] = moment(value);
        }

        if (valueType === 'object') {
          o[key] = this.convertDates(o[key]);
        }
      }
    }

    return o;
  };
}
