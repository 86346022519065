import { IRealUserMonitoring } from './environment.model';

export function getRealUserMonitoringClientConfig(isProd: boolean ): IRealUserMonitoring {
    
  return {
    applicationId: '0a9472db-77d6-4161-a7fb-cb7f4d235481',
    clientToken: 'pub517bd27399c7a13eea14e1c9b1297daa',    
    // Rum Recording, or session replay should only be enabled in PROD
    enableRumRecording: isProd,
    // APM Tracing should only be made available in PROD
    apmTracingEnable: isProd,
    // Disable in non-prod environments.  If we want to run this in lower environments, we can play with with this switch
    isEnabled: isProd
  };
}


