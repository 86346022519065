import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({ name: 'startCase' })
export class StartCasePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return input;
    }

    return _.startCase(input);
  }
}
