<div *ngIf="autoLoad == false" class="confirm-load">
  <p>You are about the execute the below Graph API call on the tenant:</p>
  <p>{{requestedUrl}}</p>
  <p><button mat-raised-button (click)="handleLoad()">Execute</button></p>
</div>
<div *ngIf="autoLoad == true">
  <sk-graph-collapsible-container
    [collapsed]="collapsed$ | async"
    [hasMore]="nextJourney != null"
    [journey]="journey"
    [displayName]="displayName"
    [requestedUrl]="requestedUrl"
    (changed)="onCollapseChange($event)"
  >
  <div *ngIf="isRefreshing" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!isRefreshing && isError" class="message-container">
    <sk-no-data header="An error was encountered while processing your request" [subheader]="message"></sk-no-data>
  </div>
  <div *ngIf="!isRefreshing && !isError && data?.length == 0" class="message-container">
    <sk-no-data header="No Data"></sk-no-data>
  </div>
  <div *ngIf="!isRefreshing && !isError && data?.length > 0">
    <sk-graph-data-table
      [columnData]="columnData"
      [data]="data"
      (rowClick)="onRowClick($event)"
      (actionClick)="onActionClick($event)"
    ></sk-graph-data-table>
  </div>
  <div class="details-table-loading" *ngIf="skipUrl && isMoreLoading">Loading More...</div>
  <div expanded-footer class="footer-container" >
    <div *ngIf="data?.length > 0">{{data?.length}} Displayed</div>
    <button mat-raised-button *ngIf="skipUrl && !collapsed && !isMoreLoading" (click)="loadMore()">MORE</button>
  </div>
</sk-graph-collapsible-container>
  <div *ngIf="nextJourney">
    <sk-graph-journey-stop [orderId]="orderId" [journey]="nextJourney" [params]="nextParams" [displayName]="nextDisplayName"></sk-graph-journey-stop>
  </div>
</div>
