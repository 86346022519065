<div>
  <input type="file" accept=".csv" (change)="changeListener($event.target.files)" class="bulkOperationFormField upload">
  <mat-form-field class="bulkOperationFormField">
    <mat-label>Operation Type</mat-label>
    <mat-select [(value)]="operationType" (selectionChange)="onFormChanged()">
      <mat-option [value]="bulkOperationTypeEnum.EnableSubscriptions">Enable</mat-option>
      <mat-option [value]="bulkOperationTypeEnum.DisableSubscriptions">Disable</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="bulkOperationFormField">
    <mat-label>Subscription Type</mat-label>
    <mat-select [(value)]="subscriptionType" (selectionChange)="onFormChanged()">
      <mat-option [value]="backupSubscriptionTypeEnum.SharePoint">{{backupSubscriptionTypeEnum.SharePoint}}</mat-option>
      <mat-option [value]="backupSubscriptionTypeEnum.Groups">{{backupSubscriptionTypeEnum.Groups}}</mat-option>
      <mat-option [value]="backupSubscriptionTypeEnum.Teams">{{backupSubscriptionTypeEnum.Teams}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button *ngIf="uiState == bulkOperationUiStates.Unchecked" mat-raised-button (click)="submitPrecheck()">Check</button>
  <button *ngIf="uiState == bulkOperationUiStates.Checked" [disabled]="isLoading" mat-raised-button (click)="submitAct()">Act</button>
</div>
<div *ngIf="formValidationErrors && formValidationErrors !== []" class="validationErrors">
  <p *ngFor="let error of formValidationErrors">{{error}}</p>
</div>


<div class="resultsTable" [hidden]="uiState == bulkOperationUiStates.Unchecked">
  <div class="table-container" >
    <div class="table-operations-container">
      <button mat-raised-button (click)="exporter.exportTable('csv', {fileName: 'precheckResults'})">Export Precheck Results</button>
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <mat-select (selectionChange)="applyFilter($event)" #filter multiple>
          <mat-option value="Disable">Disable</mat-option>
          <mat-option value="Enable">Enable</mat-option>
          <mat-option value="Duplicate">Duplicate</mat-option>
          <mat-option value="DoesNotExist">DoesNotExist</mat-option>
          <mat-option value="InvalidEmail">InvalidEmail</mat-option>
          <mat-option value="NoChange">NoChange</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-table [dataSource]="dataSource"
      matTableExporter #exporter="matTableExporter"
      matSort class="mat-elevation-z8 precheck-results-table">
      <ng-container matColumnDef="entityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.entityName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="outcome">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Precheck Outcome</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.outcome}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{filter.value}}"</td>
      </tr>
    </mat-table>

    <div *ngIf="isLoading" class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <mat-paginator
    [pageSize]="5"
    [length]="totalLength"
    showFirstLastButtons
    hidePageSize>
  </mat-paginator>
</div>

<div class="actionResults" *ngIf="uiState == bulkOperationUiStates.PostAction">
  <mat-icon
    [class]="isSuccess() ? 'success' : 'error'">
    {{isSuccess() ? 'done' : 'close'}}
  </mat-icon>
  <div [class]="isSuccess() ? 'success' : 'error'">
    <p [hidden]="isSuccess()" *ngFor="let error of actionErrors">{{error}}</p>
    <p *ngIf="isSuccess()">Success!</p>
  </div>
</div>

<div class="dialogControls">
  <button mat-raised-button (click)="dialogRef.close()">Close</button>
</div>
