import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sk-click-to-refresh',
  templateUrl: './click-to-refresh.component.html',
  styleUrls: ['./click-to-refresh.component.scss']
})
export class ClickToRefreshComponent {
  @Input() shouldDisplay: boolean;
  @Input() spin: boolean;
  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  determineContainerStyle(): string {
    return this.spin ? 'spin' : '';
  }

  invokeClickEvent(): void {
    if (this.clickEvent) {
      this.clickEvent.emit();
    }
  }
}
