
export class BackupEntityFilter {
  resourceType: string;
  searchString: string;
  expanded: boolean;

  constructor() {
    this.resourceType = 'All';
    this.expanded = false;
  }
}