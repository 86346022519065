export enum BackupEntityType {
  Web = 0,
  Folder = 1,
  File = 2,
  Mailbox = 3,
  Message = 4,
  Contact = 5,
  Appointment = 6,
  Group = 7,
  Task = 8,
  Note = 9,
  JournalEntry = 10,
  Share = 11,
  List = 12,
  ListItem = 13,
  ListItemAttachment = 14,
  Conversation = 15,
  ConversationMessage = 16,
  Post = 17,
  Team = 18,
  TeamChannel = 19,
  TeamChannelMembers = 20,
  TeamChannelPostsTeam = 21,
  TeamChannelPostsChannel = 22,
  TeamChannelPosts = 23,
  TeamsChats = 24, // root level type
  TeamsChat = 25, // lower level type for TeamsChats
  Planner = 28,
  PlannerPlan = 29,
  PlannerBucket = 30,
  PlannerTask = 31
}

export enum RetentionPurgeState {
  None = 0,
  Marked = 1
}

// This is a hack for creating an extension method off of the enum named the same as the namespace
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BackupEntityType {
  export function iconName(type: BackupEntityType | undefined): string {
    switch (type) {
    case BackupEntityType.File:
      return 'insert_drive_file';
    case BackupEntityType.Folder:
      return 'folder';
    case BackupEntityType.Web:
      return 'language';
    case BackupEntityType.List:
      return 'list';
    case BackupEntityType.Mailbox:
      return 'markunread_mailbox';
    case BackupEntityType.Message:
      return 'mail';
    case BackupEntityType.Contact:
      return 'person_outline';
    case BackupEntityType.Task:
      return 'edit';
    case BackupEntityType.Appointment:
      return 'calendar_today';
    case BackupEntityType.Team:
    case BackupEntityType.Group:
      return 'groups';
    case BackupEntityType.Conversation:
      return 'question_answer';
    case BackupEntityType.ConversationMessage:
      return 'chat_bubble_outline';
    case BackupEntityType.TeamChannel:
      return 'group';
    case BackupEntityType.TeamChannelMembers:
      return 'groups';
    default:
      return 'insert_drive_file';
    }
  }

  export function hasChildContent(type: BackupEntityType | undefined): boolean {
    return type === BackupEntityType.Web ||
            type === BackupEntityType.Folder ||
            type === BackupEntityType.List ||
            type === BackupEntityType.Mailbox ||
            type === BackupEntityType.Team ||
            type === BackupEntityType.TeamChannel ||
            type === BackupEntityType.TeamChannelPostsTeam ||
            type === BackupEntityType.TeamChannelPostsChannel ||
            type === BackupEntityType.Planner ||
            type === BackupEntityType.PlannerPlan ||
            type === BackupEntityType.TeamsChats
  }
}

export enum BackupSubscriptionTypes {
  Exchange = 'Exchange',
  SharePoint = 'SharePoint',
  OneDrive = 'OneDrive',
  Groups = 'Groups',
  Teams = 'Teams',
  TeamsChats = 'TeamsChats'
}

export enum PreparationState {
  Unprepared = 0,
  StorageAssigned = 1,
  BlobContainersCreated = 2,
  ResourceRegistered = 3,
  PreparationFinished = ResourceRegistered
}
