export class AuditFilter {
  pageNumber: number;
  pageSize: number;
  sortByColumn: string;
  sortByAscending: boolean;

  constructor() {
    this.pageNumber = 0;
    this.pageSize = null;
    this.sortByColumn = null;
    this.sortByAscending = true;
  }
}
