<div class="licenses-summary" *ngIf="licenseDetails">
  <div class="icon">
    <mat-icon
      [class.success]="licenseDetails.length != 0 && provisioned === licenseDetails.length"
      [class.warning]="provisioned < licenseDetails.length"
      [class.error]="provisioned === 0 || (provisioned == 0 && licenseDetails.length === 0)"
      title="{{ licenseDetails.length === 0
        ? 'No Licenses For Subscription'
        : (provisioned === licenseDetails.length 
          ? 'All Licenses Are Provisioned'
          : 'Some Licenses Are Not Provisioned')
      }}">
      local_police
    </mat-icon>
  </div>
  <div>
    {{provisioned}}/{{licenseDetails.length}}
  </div>
</div>

<div [hidden]="!dataSource" [class.mat-elevation-z8]="!dataSource" class="grid-container">
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="skuId">
        <mat-header-cell *matHeaderCellDef>
          <span>Sku ID</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <sk-click-to-copy
            hoverText="Click to copy Sku ID"
            [copyText]="item.skuId">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="skuName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Sku Name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <sk-click-to-copy
            [displayText]="item.skuName"
            hoverText="Click to copy Sku Name"
            [copyText]="item.skuName">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="servicePlanId">
        <mat-header-cell *matHeaderCellDef>
          <span>Plan ID</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <sk-click-to-copy
            hoverText="Click to copy Service Plan ID"
            [copyText]="item.servicePlanId">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="servicePlanName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Plan Name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <sk-click-to-copy
            [displayText]="item.servicePlanName"
            hoverText="Click to copy Service Plan Name"
            [copyText]="item.servicePlanName">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="servicePlanProvisioningStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Provisioning Status</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.servicePlanProvisioningStatus}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
</div>

<div *ngIf="!isLoading && (!licenseDetails || licenseDetails?.length == 0)" class="no-results">
  <span>No License Details Found</span>
</div>

<div *ngIf="isLoading" class="loading-spinner-modal">
  <mat-spinner></mat-spinner>
</div>
