import { Location } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AzureIpAddressCheck } from '@sk-models';
import { MessageService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';
import { GenericModalComponent } from 'app/shared/generic-modal/generic-modal.component';

@Component({
  selector: 'sk-ip-checker',
  templateUrl: './ip-checker.component.html',
  styleUrls: ['./ip-checker.component.scss']
})
export class IpCheckerComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  ipCheckerForm: FormGroup;
  ipAddress: FormControl;

  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.ipAddress = new FormControl('', [this.validateIpAddress]),
    this.ipCheckerForm = new FormGroup({
      ipAddress: this.ipAddress
    });
  }

  validateIpAddress(control: FormControl): { [key: string]: boolean } | null {
    const ipAddress = control.value;
    const ipv4Regex = new RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
    // eslint-disable-next-line max-len
    const ipv6Regex = new RegExp('(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))');

    if (ipv4Regex.test(ipAddress) ||
        ipv6Regex.test(ipAddress)) {
      return null;
    } else {
      return { ipAddress : false };
    }
  }

  checkIPAddress(): void {
    const ipAddressInput = this.ipCheckerForm.get('ipAddress').value;
    this.apiService.getIsAzureIpAddress(ipAddressInput)
      .subscribe(result => {
        this.popAlertModal(result);
      });

  }

  popAlertModal(ipAddressCheck: AzureIpAddressCheck): void {
    const message = ipAddressCheck.inRange
      ? `This address is associated with the Azure service ${ipAddressCheck.service}.`
      : 'This address is not associated with an Azure service.';
    this.messageService.openCustomModal(GenericModalComponent, message);
  }

  returnClicked(): void {
    this.location.back();
  }
}
