import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import {
  SwatTicket,
  SwatTickets,
} from '@sk-models';

@Component({
  selector: 'sk-swat-ticket-list',
  templateUrl: './swat-ticket-list.component.html'
})
export class SwatTicketListComponent {
  @Input() swatTickets: SwatTickets;
  @Output() navigateSwatTicket = new EventEmitter<SwatTicket>();
  @Output() navigateToSwatBoard = new EventEmitter();

  onNavigateSwatTicket(swatTicket: SwatTicket): void {
    this.navigateSwatTicket.emit(swatTicket);
  }

  onNavigateToSwatBoard(): void {
    this.navigateToSwatBoard.emit();
  }
}
