export enum MigrationOrderType {
  MaaS = 772370006,
  SMBMigration = 772370000,
  EnterpriseMigration = 772370001,
  DataOnly = 772370003,
  DataOnlyPlusSKOA = 772370007,
  Backup = 772370002,
  Syndication = 772370004,
  SyndicationProvisionOnly = 772370005,
  MigrationsVNext = 772370008
}
