import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { GenericModalComponent } from '../../shared/generic-modal/generic-modal.component';
import { GenericModalOptions } from '@sk-models';
import { Observable } from 'rxjs';
import { GenericConfirmationComponent } from 'app/shared/generic-confirmation/generic-confirmation.component';
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class MessageService {

  constructor(
    private dialog: MatDialog
  ) { }

  openCustomModal<T>(
    component: ComponentType<T>,
    data: Record<string, unknown>,
    title?: string,
    options?: GenericModalOptions
  ): void;
  openCustomModal<T>(
    component: ComponentType<T>,
    data: string,
    title?: string,
    options?: GenericModalOptions
  ): void;
  openCustomModal<T>(
    component: ComponentType<T>,
    data: string | Record<string, unknown>,
    title?: string,
    options?: GenericModalOptions
  ): void {
    const dialogConfig = new MatDialogConfig();

    if (typeof data === 'string') {
      dialogConfig.data = { title: title, text: data, options: options };
    } else {
      dialogConfig.data = { ...data, title: title, options: options };
    }

    dialogConfig.maxWidth = "750px";
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed();
  }

  /** @deprecated Use `openCustomModal<T>()` instead. */
  openModal(message: string, title: string = null, options: GenericModalOptions = null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: title, text: message, options: options };
    dialogConfig.maxWidth = "750px";
    const dialogRef = this.dialog.open(GenericModalComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  openConfirmModal(message: string, confirmButtonText: string = null, cancelButtonText: string = null): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { message: message, confirmButtonText, cancelButtonText };
    const dialogRef = this.dialog.open(GenericConfirmationComponent, dialogConfig);
    return dialogRef.afterClosed();
  }
}
