import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideDrawerComponent, SideDrawerContentDirective } from './side-drawer/side-drawer.component';
import { SideDrawerService } from './side-drawer/side-drawer.service';
import { NoDataComponent } from './no-data/no-data.component';
import { WidgetComponent } from './widget/widget.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import {
  ClickToCopyComponent,
} from './click-to-copy/click-to-copy.component';

import {
  DecodeUriPipe,
  FileSizePipe,
  FriendlyDatePipe,
  OrderByPipe,
  SanitizeHtmlPipe,
  StartCasePipe,
} from '@sk-pipes';
import { CustomMaterialModule } from 'app/custom-material/custom-material.module';
import { WidgetDataComponent } from './widget-data/widget-data.component';
import { WidgetDataRowComponent } from './widget-data/widget-data-row/widget-data-row.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { RouterModule } from '@angular/router';
import { GenericConfirmationComponent } from './generic-confirmation/generic-confirmation.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { ClickToRefreshComponent } from "./click-to-refresh/click-to-refresh.component";
import { AuditComponent } from "./audit/audit.component";
import { NavItemComponent } from "./side-nav/nav-item.component";

@NgModule({
  declarations: [
    SideDrawerComponent,
    SideDrawerContentDirective,
    NoDataComponent,
    WidgetComponent,
    StartCasePipe,
    OrderByPipe,
    DecodeUriPipe,
    FileSizePipe,
    FriendlyDatePipe,
    SanitizeHtmlPipe,
    SectionHeaderComponent,
    ClickToCopyComponent,
    WidgetDataComponent,
    WidgetDataRowComponent,
    PageHeaderComponent,
    GenericConfirmationComponent,
    LoadingOverlayComponent,
    ClickToRefreshComponent,
    AuditComponent,
    NavItemComponent
  ],
  providers: [
    SideDrawerService
  ],
  exports: [
    SideDrawerComponent,
    SideDrawerContentDirective,
    NoDataComponent,
    WidgetComponent,
    SectionHeaderComponent,
    StartCasePipe,
    OrderByPipe,
    DecodeUriPipe,
    FileSizePipe,
    FriendlyDatePipe,
    SanitizeHtmlPipe,
    ClickToCopyComponent,
    WidgetDataComponent,
    WidgetDataRowComponent,
    PageHeaderComponent,
    LoadingOverlayComponent,
    // Module Exports
    NgxJsonViewerModule,
    ClickToRefreshComponent,
    AuditComponent,
    NavItemComponent
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    NgxJsonViewerModule,
    RouterModule
  ],
  entryComponents: [ SideDrawerComponent ]
})
export class SharedModule { }
