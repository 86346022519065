<div *ngIf="data">
  <div class="title">
    <h5>Download Restore Results</h5>
  </div>

  <div>
    <p>This report will show all items that were restored as part of restore job <b>{{data.id}}</b>.<br>
      Depending on the number of items that were included in the restore, it may take some time to download this report.
    </p>
    <p><b>Please Note:</b> For restores with a high number of restored items, the export may timeout.</p>
  </div>

  <div>
    <div *ngIf="isLoading"
      class="loading-dialog">
      <mat-spinner
      class="spinner"
      diameter="30">
      </mat-spinner>
      <span>Downloading...</span>
    </div>

    <div class="dialog-buttons">
      <button mat-raised-button
        (click)="onClose()"
        [disabled]="isLoading">
        Close
      </button>
      <button mat-raised-button
        (click)="onExportRestoreResult()"
        [disabled]="isLoading">
        Download Results
      </button>
    </div>
  </div>
</div>