<mat-dialog-content>
  <h4 *ngIf="title">{{ title }}</h4>

  <p *ngIf="showParagraph"
    class="card">
    {{text}}
  </p>

  <code *ngIf="showCodeBlock"
    class="card">
    {{text}}
  </code>

  <div *ngIf="showHtml"
    [innerHTML]="text | sanitizeHtml"
    class="card">
  </div>

  <div *ngIf="showJson" class="card">
    <ngx-json-viewer class="json-font-size" [json]="text"></ngx-json-viewer>
  </div>
</mat-dialog-content>
