import { ResourceKey } from '@sk-models';
import { Moment } from 'moment';

export interface Group {
  id: string;
  backupServiceId: string;
  name: string;
  enabled: boolean;
  overridden: boolean;
  emailAddress: string;
  memberCount: number;
  created: Moment;
  nativeId: string;
  disabledDueToAccess?: Moment;
  groupType: number;
  collaborationResourceInfo: ResourceInfo;
}

export interface ResourceInfo {
  resourceKey: ResourceKey,
  name: string,
  enabled: boolean
  isTeam?: boolean;
}

export enum AzureGroupType {
  Default = 0,
  M365Group = 1,
  Security = 2,
  MailEnabledSecurity = 3,
  Distribution = 4
}
