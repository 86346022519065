<h4>Graph Token</h4>

<div class="notification">
  <div>
    <mat-icon inline class="blue">info</mat-icon>
    NOT PARTNER FACING
  </div>
  Please remember that this is an engineering tool. The value reported below represents the customer's graph token
  which can be used to interact with their tenant on behalf of SkyKick. Usage and retrieval of this value is heavily auditted.
</div>

<ng-container *ngIf="this.isLoading === false; else spinner">
  <ng-container *ngIf="token; else error">
    <div class="response">
      <sk-click-to-copy
        class="copy-token"
        hoverText="Click to copy token"
        [copyText]="token"
        displayText="Copy"
        displayIcon="content_copy">
      </sk-click-to-copy>
      <p class="token">{{ token }}</p>
    </div>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <mat-spinner class="loading-spinner"></mat-spinner>
</ng-template>

<ng-template #error>
  <div class="response">
    <mat-icon inline class="error">cloud_off</mat-icon>
    There was a problem retrieving a valid graph token from the tenant. Please contact SWAT.
  </div>
</ng-template>
