import { Component, Input } from '@angular/core';

import { Partner } from '@sk-models';

@Component({
  selector: 'sk-partner-card',
  templateUrl: './partner-card.component.html',
  styleUrls: ['./partner-card.component.scss']
})
export class PartnerCardComponent {
  @Input() partner: Partner;
}
