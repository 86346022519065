import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  BackupEntityType,
  TreeEntity,
} from '@sk-models';
import { EntityDownloadService } from '@sk-services';

import { ApiService } from '../../../api-service/api.service';

@Component({
  selector: 'sk-tree-viewer',
  templateUrl: './tree-viewer.component.html',
  styleUrls: ['./tree-viewer.component.scss']
})
export class TreeViewerComponent implements OnInit {
  @Input() treeRoot: TreeEntity;
  @Input() orderId: string;
  @Input() subscriptionId: string;
  @Input() subscriptionNativeId: string;
  @Input() resourceType: string;
  @Output() entitySelectedEvent = new EventEmitter<TreeEntity>();

  children: TreeEntity[];

  private isExpandable = false;
  private showPopover = false;
  private isExpanded = false;
  private childrenLoaded = false;
  private childrenTooltip = '';
  private isLoading = false;
  private page = 1;
  private pageSize = 500;

  constructor(private apiService: ApiService, private entityDownloadService: EntityDownloadService) { }

  ngOnInit(): void {
    if (this.treeRoot){
      this.children = this.treeRoot.children;
      if(this.treeRoot.children) {
        this.isExpanded = true;
      }
    }
    this.isExpandable = BackupEntityType.hasChildContent(this.treeRoot.type);
  }

  getChildren(): void {
    if (this.treeRoot && !this.childrenLoaded) {
      this.isLoading = true;
      this.apiService.findChildrenOfSubscriptionEntity(this.orderId, this.subscriptionId, this.resourceType,
        this.treeRoot.skyKickId, this.treeRoot.parentId)
        .subscribe(children => {
          if (children) {
            if (this.treeRoot.children) {
              // merge with previously loaded child
              children = children.map(
                node => node.skyKickId === this.treeRoot.children[0].skyKickId
                  ? this.treeRoot.children[0]
                  : node);
            }
            this.treeRoot.children = children
              .sort(function(a, b) {
                if (a.type === b.type) {
                  return (a.name < b.name) ? -1 : 1;
                } else {
                  return (a.type < b.type) ? -1 : 1;
                }
              });
            this.childrenLoaded = true;
            this.isExpanded = true;
            this.buildChildTooltip(children)
          }
          this.children = children;
          this.isLoading = false;
        });
    } else {
      this.children = this.treeRoot.children;
      this.isExpanded = true;
    }
  }

  buildChildTooltip(children: TreeEntity[]): void {
    const childrenCounts = {};
    children.forEach(child => {
      if (childrenCounts[BackupEntityType[child.type]]) {
        childrenCounts[BackupEntityType[child.type]] += 1;
      } else {
        childrenCounts[BackupEntityType[child.type]] = 1;
      }
    });
    for (const childtype in childrenCounts) {
      this.childrenTooltip += '(' + childrenCounts[childtype] + ') ' + childtype + ' ';
    }
  }

  applySearch(childName: string): void {
    if (childName) {
      const searchRegex = new RegExp(childName, 'i');
      this.children = this.treeRoot.children.filter(item => searchRegex.test(item.name));
      this.page = 1; // needs to reset pagination
    } else {
      this.children = this.treeRoot.children;
    }
  }

  treeEntityClicked(): void {
    this.entitySelectedEvent.emit(this.treeRoot);
    if (!this.isExpanded) {
      this.getChildren();
    } else {
      this.children = [];
      this.isExpanded = !this.isExpanded;
    }
  }

  togglePopover(): void {
    this.showPopover = !this.showPopover;
  }

  canBeExpanded(): boolean {
    if (this.childrenLoaded) {
      return this.isExpandable && this.treeRoot.children && this.treeRoot.children.length > 0;
    } else {
      return this.isExpandable;
    }
  }

  getExpandableIcon(): string {
    return this.isExpanded ? 'indeterminate_check_box' : 'add_box';
  }

  getIconNameByType(): string {
    return BackupEntityType.iconName(this.treeRoot.type);
  }

  rethrowEvent($event: TreeEntity): void {
    this.entitySelectedEvent.emit($event);
  }

  downloadData(): void {
    this.entityDownloadService.downloadTreeEntitiesAsCsv(this.treeRoot.children, "TreeItems.csv");
  }
}
