<div class="side-drawer-container">
  <div class="side-drawer-header">
    <div class="text">
      SWAT Tickets
    </div>
    <div class="actions">
      <mat-icon (click)="onNavigateToSwatBoard()" class="navigatable" title="Open SWAT Board">arrow_outward</mat-icon>
    </div>
  </div>
  <div class="side-drawer-content-container">
    <sk-swat-ticket
      (navigateSwatTicket) = "onNavigateSwatTicket($event)"
      [swatTickets]="swatTickets">
    </sk-swat-ticket>
  </div>
</div>
