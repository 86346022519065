<div class="card">
  <div class="card-row">
    <div class="card-section-small">
      <mat-icon *ngIf="partner.isTestPartner" class="icon" title="Test Partner">person_off</mat-icon>
    </div>

    <div class="card-section">
      <span class="bold">{{partner.name}}</span>
    </div>
  </div>

  <div class="card-row">
    <div class="card-section-small">
    </div>

    <div class="card-section">
      <span>{{partner.emailAddress}}</span>
    </div>
  </div>
</div>
