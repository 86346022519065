import { IAppEnvironment } from 'environments/config/environment.model';
import { BaseRealUserMonitoringService } from './base-real-user-monitoring.service';

export class NullRealUserMonitoringService extends BaseRealUserMonitoringService {

  // Pass through initialization function
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public initialize(config: IAppEnvironment): void {
    // Eat it.
  }

  // Pass through user context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setUserContext(data: unknown): void {
    // Eat it.
  }

  // Pass through log error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public logError(error: unknown): void {
    // Eat it.
  }
}