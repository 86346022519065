import { Component, OnInit, Input } from '@angular/core';

import { TabDefinition } from '../tab-nav/tab-definition';

@Component({
  selector: 'sk-subtab-nav',
  templateUrl: './subtab-nav.component.html',
  styleUrls: ['./subtab-nav.component.scss']
})
export class SubtabNavComponent implements OnInit {
  pathParts: string[];
  activeSubTab: string;

  @Input()
  tabs: TabDefinition[];

  @Input()
  subTabUrlSection: number;

  ngOnInit(): void {
    this.subTabUrlSection = this.subTabUrlSection || 4;
    const currentUrl = new URL(window.location.href);
    const currentPath = currentUrl.pathname;
    this.pathParts = currentPath.split('/');
    this.activeSubTab = this.pathParts[this.subTabUrlSection];
  }

  isActive(subTabName: string): boolean{
    return this.activeSubTab === subTabName;
  }

  buildLink(tab: TabDefinition): string {
    let link = '/orders/';
    for (let i = 2; i < this.subTabUrlSection; ++i) {
      link += this.pathParts[i] + '/';
    }
    link += tab.route;
    return link;
  }
}
