import { Component, Input, OnInit } from '@angular/core';
import { ResourceKey } from "@sk-models";

@Component({
  selector: 'sk-users-groups-related-resources',
  templateUrl: './users-groups-related-resources.component.html',
  styleUrls: ['./users-groups-related-resources.component.scss']
})
export class UsersGroupsRelatedResourcesComponent implements OnInit {

  @Input() backupServiceId: string;
  @Input() resourceKeys: ResourceKey[];
  @Input() alwaysShow = true;

  public allResources = [{
    title: 'OneDrive',
    img: '../../../../../assets/images/icon-onedrive.svg',
    subscriptionType: 'O365SharePoint',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/onedrive/subscription-details`
  }, {
    title: 'Exchange',
    img: '../../../../../assets/images/icon-exchange.svg',
    subscriptionType: 'O365Exchange',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/subscription-details`
  }, {
    title: 'Teams Chats',
    img: '../../../../../assets/images/icon-teams.svg',
    subscriptionType: 'Teams',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/subscription-details`
  }, {
    title: 'Team',
    img: '../../../../../assets/images/icon-teams.svg',
    subscriptionType: 'O365Groups',
    resourceType: 'Team',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/team/subscription-details`
  }, {
    title: 'Channel Files',
    img: '../../../../../assets/images/icon-folder.svg',
    subscriptionType: 'O365Groups',
    resourceType: 'TeamChannelFiles',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/teamchannelfiles/subscription-details`
  }, {
    title: 'Channel Posts',
    img: '../../../../../assets/images/icon-chat.svg',
    subscriptionType: 'O365Groups',
    resourceType: 'TeamChannelPosts',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/teamchannelposts/subscription-details`
  }, {
    title: 'Files',
    img: '../../../../../assets/images/icon-folder.svg',
    subscriptionType: 'O365Groups',
    resourceType: 'TeamSite',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/teamSite/subscription-details`
  }, {
    title: 'Planner',
    img: '../../../../../assets/images/icon-planner.svg',
    subscriptionType: 'O365Groups',
    resourceType: 'Planner',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/planner/subscription-details`
  }, {
    title: 'Conversations',
    img: '../../../../../assets/images/icon-exchange.svg',
    subscriptionType: 'O365Groups',
    resourceType: 'ConversationMailbox',
    url: (resourceKey: ResourceKey): string => `orders/${this.backupServiceId}/subscriptions/${resourceKey.subscriptionId}/conversationmailbox/subscription-details`
  }];

  public availableResources = [];

  ngOnInit(): void {
    this.availableResources = this.resourceKeys?.map((resource) => {
      const allResource = this.allResources
        .find((allResource) => resource.subscriptionType === allResource.subscriptionType &&
          (!allResource.resourceType || resource.resourceType === allResource.resourceType));
      if (allResource) {
        return {
          title: allResource.title,
          img: allResource.img,
          type: allResource.subscriptionType,
          url: allResource.url(resource)
        }
      } else {
        return null;
      }
    }).filter((resource) => resource != null) ?? [];
  }
}
