import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';

import {
  AssignedLicenseDetails,
  LicensingItem,
  LicensingItemChild,
  LicensingItemType,
  LicensingResponse,
  SkuDetails,
  UserDetails,
} from '@sk-models';

import { ApiService } from '../../api-service/api.service';

@Component({
  selector: 'sk-order-licensing',
  templateUrl: './order-licensing.component.html',
  styleUrls: ['./order-licensing.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class OrderLicensingComponent implements OnInit {
  @Input() orderId: string;

  @ViewChildren('innerLicenseTables') innerLicenseTables: QueryList<MatTable<SkuDetails>>;
  @ViewChildren('innerUserTables') innerUserTables: QueryList<MatTable<UserDetails>>;

  displayedColumns = ['id', 'idName', 'displayName' ];
  drawerOpened = false;
  licensesIsActive = true;
  skusIsActive = false;
  usersIsActive = false;

  detailsArray: LicensingItemChild[] = [];

  dataSource: MatTableDataSource<LicensingItem>;
  isOversize: boolean;

  licenseDetails: AssignedLicenseDetails[] = [];
  licensesList: LicensingItem[] = [];
  skusList: LicensingItem[] = [];
  usersList: LicensingItem[] = [];
  selectedItem: LicensingItem;
  itemType: LicensingItemType;
  isLoading = false;
  sort: MatSort;

  constructor(
      private cd: ChangeDetectorRef,
      private apiService: ApiService
  ) { }

  @ViewChild(MatSort) set matSort(sort: MatSort){
    this.sort = sort;
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.apiService.fetchSubscriptionAssignedLicenseDetails(this.orderId)
      .subscribe({
        next: results => this.setDataSource(results),
        complete: () => this.isLoading = false
      });
  }

  setDataSource(response: LicensingResponse): void {
    if (response) {
      const items = response.licenses;
      this.isOversize = response.isOversize;
      items.forEach(license => {
        if(!license.displayName){
          license.displayName = "Error: Missing License Information";
        }

        if(!license.id){
          license.id = "Error: Missing License Information";
        }

        if(!license.guid){
          license.guid = "Error: Missing License Information";
        }

        if(!license.skus){
          license.skus = [];
        }

        if (license.users && Array.isArray(license.users) && license.users.length &&
            license.skus && Array.isArray(license.skus) && license.skus) {

          this.licenseDetails = [...this.licenseDetails, { ...license,
            users: license.users,
            skus: license.skus }];

        } else {
          this.licenseDetails = [...this.licenseDetails, license];
          this.licensesList = [...this.licensesList, { displayName: license.displayName, itemType: LicensingItemType.License, id: license.guid, idName: license.id, childItems: [], isMissingLicense: license.isMissingLicense }]
        }
      });

      this.licenseDetails.forEach(lic => {

        const userChildList = [];
        lic.users.forEach(u => {
          const userChild = { name: u.email, id: u.id, idName: u.email };
          userChildList.push(userChild);
        });

        const skuChildList = [];
        lic.skus.forEach(s => {
          const skuChild = { name: s.servicePlansIncludedFriendlyNames, id: s.servicePlanId, idName: s.servicePlanName };
          skuChildList.push(skuChild);
        });

        const childList: LicensingItemChild[] = [];

        const userChildType: LicensingItemChild = { type: LicensingItemType.User, details: userChildList };
        const skuChildType: LicensingItemChild = { type: LicensingItemType.SKU, details: skuChildList };
        childList.push(userChildType, skuChildType);

        const newLicense: LicensingItem = {
          displayName: lic.displayName,
          itemType: LicensingItemType.License,
          id: lic.guid,
          idName: lic.id,
          isMissingLicense: lic.isMissingLicense,
          childItems: childList
        };

        this.licensesList.push(newLicense);

        lic.users.forEach(user => {
          const licenseDetails = { name: lic.displayName, id: lic.guid, idName: lic.id }
          const licenseChildTypeList: LicensingItemChild[] = [];
          const licensingItemChildLicenseList = [];

          licensingItemChildLicenseList.push(licenseDetails);

          const itemChildLicense: LicensingItemChild = { type: LicensingItemType.License, details: licensingItemChildLicenseList };

          licenseChildTypeList.push(itemChildLicense);

          let thisUser = this.usersList.find(u => u.id == user.id);
          const thisUserIndex = this.usersList.findIndex(u => u.id == user.id);

          if(thisUser == null){
            let name= "";
            if(user.email){
              name = user.email.toLowerCase();
            }
            thisUser = { displayName: name, id: user.id, idName: name, itemType: LicensingItemType.User, isMissingLicense: false,
              childItems: [ { type: LicensingItemType.License, details: [{ name: lic.displayName, id: lic.guid, idName: lic.id }] } ] };
            this.usersList.push(thisUser);
          } else {
            thisUser.childItems[0].details.push({ name: lic.displayName, id: lic.guid, idName: lic.id });
            this.usersList[thisUserIndex] = thisUser;
          }
        });

        lic.skus.forEach(sku => {
          const licenseDetails = { name: lic.displayName, id: lic.guid, idName: lic.id }
          const licenseChildTypeList: LicensingItemChild[] = [];
          const licensingItemChildLicenseList = [];

          licensingItemChildLicenseList.push(licenseDetails);

          const itemChildLicense: LicensingItemChild = { type: LicensingItemType.License, details: licensingItemChildLicenseList };

          licenseChildTypeList.push(itemChildLicense);

          let thisSku = this.skusList.find(s => s.id == sku.servicePlanId);
          const thisSkuIndex = this.skusList.findIndex(s => s.id == sku.servicePlanId);

          if(thisSku == null){
            thisSku = { displayName: sku.servicePlansIncludedFriendlyNames, id: sku.servicePlanId, idName: sku.servicePlanName, itemType: LicensingItemType.SKU, isMissingLicense: false,
              childItems: [ { type: LicensingItemType.License, details: [{ name: lic.displayName, id: lic.guid, idName: lic.id }] } ] };
            this.skusList.push(thisSku);
          } else {
            thisSku.childItems[0].details.push({ name: lic.displayName, id: lic.guid, idName: lic.id });
            this.skusList[thisSkuIndex] = thisSku;
          }
        });
      });

      this.dataSource = new MatTableDataSource(this.licensesList);
      this.dataSource.sort = this.sort;
      this.itemType = LicensingItemType.License;
      this.isLoading = false;
    }
  }

  selectItem(item: LicensingItem): void {
    this.drawerOpened = true;
    this.selectedItem = item;
  }

  onNavigateToItem(grandChild: LicensingItemChild): void {
    let navigateToItem;

    switch(grandChild.type) {
    case LicensingItemType.License:
      navigateToItem = this.licensesList.find(l => l.id == grandChild.details[0].id);
      break;
    case LicensingItemType.SKU:
      navigateToItem = this.skusList.find(s => s.id == grandChild.details[0].id);
      break;
    case LicensingItemType.User:
      navigateToItem = this.usersList.find(u => u.id == grandChild.details[0].id);
      break;
    }

    this.selectItem(navigateToItem);
  }

  closeDrawer(): void {
    this.drawerOpened = false;
  }

  activateItemType(type: string): void {
    if(type == "license"){
      this.licensesIsActive = true;
      this.skusIsActive = false;
      this.usersIsActive = false;
      this.itemType = LicensingItemType.License;
      this.dataSource = new MatTableDataSource(this.licensesList);
      this.dataSource.sort = this.sort;
    }
    if(type == "sku"){
      this.licensesIsActive = false;
      this.skusIsActive = true;
      this.usersIsActive = false;
      this.itemType = LicensingItemType.SKU;
      this.dataSource = new MatTableDataSource(this.skusList);
      this.dataSource.sort = this.sort;
    }
    if(type == "user"){
      this.licensesIsActive = false;
      this.skusIsActive = false;
      this.usersIsActive = true;
      this.itemType = LicensingItemType.User;
      this.dataSource = new MatTableDataSource(this.usersList);
      this.dataSource.sort = this.sort;
    }
  }
}
