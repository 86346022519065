import { Component, OnInit, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { JobsService, MessageService } from '@sk-services';
import { ApiService } from '../../api-service/api.service';
import { BackupJob } from '@sk-models';

@Component({
  selector: 'sk-order-restore-history',
  templateUrl: './order-restore-history.component.html',
  styleUrls: ['./order-restore-history.component.scss']
})
export class OrderRestoreHistoryComponent implements OnInit {
  @Input() orderId: string;
  @Input() startDate: string;
  @Input() endDate: string;

  restoreJobs: BackupJob[];
  isRefreshing: boolean;

  constructor(
    private apiService: ApiService,
    private jobsService: JobsService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.isRefreshing = false;
    this.loadRestoreJobs(false);
  }

  loadRestoreJobs(reload: boolean): void {
    this.isRefreshing = true;
    this.apiService.getRestoreJobsForOrder(this.orderId, reload)
      .pipe(
        map(jobs => jobs.map(j => this.jobsService.computeJobProperties(j))),
        map(arr => arr.sort(this.restoreJobHistoryCompareFn)))
      .subscribe(jobs => {
        if (jobs.length === 0) {
          this.messageService.openModal('No restore jobs found in the last 30 days\n Try searching for a specific date range');
        }
                      
        this.restoreJobs = jobs;
      }, error => {
        this.messageService.openModal('Error getting restore jobs: ' + error);
      })
      .add(() => this.isRefreshing = false);
  }

  loadRestoreJobsDateRange(startDate: string, endDate: string): void {
    this.isRefreshing = true;
    this.apiService.getRestoreJobsForOrderDateRange(this.orderId, true, startDate, endDate)
      .pipe(map(jobs => jobs.map(j => this.jobsService.computeJobProperties(j))),
        map(arr => arr.sort(this.restoreJobHistoryCompareFn)))
      .subscribe(jobs => {
        if (jobs.length === 0) {
          this.messageService.openModal('No restore jobs found in the selected date range');
        }
        
        this.restoreJobs = jobs;      
      }, error => {
        this.messageService.openModal('Error getting restore jobs: ' + error);
      })
      .add(() => this.isRefreshing = false);
  }

  restoreJobHistoryCompareFn(left: BackupJob, right: BackupJob): number {
    const nextMoment = right.created || moment('1900-01-01');
    const currentMoment = left.created || moment('1900-01-01');

    return nextMoment.valueOf() - currentMoment.valueOf();
  }
}
