import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthRoles, BackupOrder, OrderState, Partner, PartnerDetails, PartnerOrdersFilter, PartnerOrdersPage, PartnerServiceInfo, authUserInRole } from '@sk-models';
import { AuthService, MessageService, OrderService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';
import { CsvExportService } from 'app/services/csv-service/csv-export.service';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sk-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent extends SkyKickComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1 flex-column';

  public isLoading = true;
  public partnerId: string;
  public partnerDetails: Partner  = null;
  public serviceInfo: PartnerServiceInfo = null;
  public isOrdersLoading = true;
  public v2TransitionAllowed = false;
  public isTransitioningPartnerToV2 = false;
  public partnerCrmUrl: string;

  userCanTransition = false;
  apiFilters: PartnerOrdersFilter;
  partnerOrdersPage: PartnerOrdersPage;
  transitionStatus: 'full' | 'partial' | 'none' = 'none';

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private csvService: CsvExportService,
    private orderService: OrderService,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    super();
    this.authService.authData$.pipe(filter(x => x != null), take(1)).subscribe((data) => {
      this.userCanTransition = authUserInRole(data, AuthRoles.Engineer);
    });
  }

  ngOnInit(): void {
    this.initFilters();
    this.isLoading = true;
    this.partnerId = this.route.snapshot.paramMap.get('partnerId');

    this.subs.sink = this.apiService.getPartner(this.partnerId)
      .subscribe((details: PartnerDetails) => {
        this.serviceInfo = {
          totalServices: details.totalServices,
          v2Services: details.v2Services,
          eligibleV2Services: details.eligibleV2Services
        };
        this.partnerCrmUrl = details.partnerCrmUrl;
        this.partnerDetails = details.partner;

        this.v2TransitionAllowed = this.userCanTransition;

        if(details.partner.isV2Partner == true) {
          // The partner is already a V2 partner, we need to ensure that all orders are transitioned.
          if(details.v2Services == details.eligibleV2Services) {
            this.transitionStatus = 'full';
          } else {
            this.transitionStatus = 'partial';
          }
        } else if((details.v2Services > 0)) {
          // In this case, the partner has some order transitioned to V2, but does NOT have the V2 partner flag
          this.transitionStatus = 'partial';
        } else {
          // In this case, the partner has no orders transitioned to V2 and does NOT have the V2 partner flag
          this.transitionStatus = 'none';
        }

        this.refreshPartnerOrders();
      },
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      });
  }


  initFilters(): void {
    this.apiFilters = new PartnerOrdersFilter();
    this.apiFilters.orderState = OrderState.Active;
    this.apiFilters.pageSize = 10;
    this.apiFilters.createdDateFrom = moment();
    this.apiFilters.createdDateTo = moment();
  }

  refreshPartnerOrders(): void {
    this.loadPartnerOrders(this.partnerId);
  }

  loadPartnerOrders(partnerId: string, reload = false): void {
    this.isOrdersLoading = true;

    if (partnerId) {
      this.apiService.findOrdersByPartnerId(partnerId, this.apiFilters, reload)
        .subscribe(
          data => {
            this.partnerOrdersPage = this.setOrderProperties(data);
            this.isOrdersLoading = false;
          }
        );
    } else {
      // no partner to load jobs for
      this.isLoading = false;
      if (this.partnerOrdersPage) {
        this.partnerOrdersPage.partnerOrders = [];
      }
    }
  }

  setOrderProperties(pop: PartnerOrdersPage): PartnerOrdersPage {
    pop.partnerOrders.forEach(value => value = this.orderService.computeOrderProperties(value));
    return pop;
  }

  orderCompareFn(left: BackupOrder, right: BackupOrder): number {
    return right.createdOn.valueOf() - left.createdOn.valueOf();
  }

  exportCSV(): void {
    const fileName = 'PartnerOrders_' + this.partnerId;

    const flattenerFn = (order: BackupOrder) => {
      const flatOrder = {};
      flatOrder['id'] = order.id;
      flatOrder['state'] = order.state;
      flatOrder['name'] = order.name;
      flatOrder['orderType'] = order.orderType;
      flatOrder['salesOrderId'] = order.salesOrderId;
      flatOrder['createdOn'] = order.createdOn;
      flatOrder['placedOn'] = order.placedOn;
      flatOrder['exchangeEnabledSeats']
        = order.products['exchange'] ? order.products['exchange'].enabledSeats : null;
      flatOrder['exchangeDisabledDueToAccessSeats']
        = order.products['exchange'] ? order.products['exchange'].disabledDueToAccessSeats : null;
      flatOrder['exchangeDisabledSeats']
        = order.products['exchange'] ? order.products['exchange'].disabledSeats : null;
      flatOrder['sharePointEnabledSeats']
        = order.products['sharePoint'] ? order.products['sharePoint'].enabledSeats : null;
      flatOrder['sharePointDisabledDueToAccessSeats']
        = order.products['sharePoint'] ? order.products['sharePoint'].disabledDueToAccessSeats : null;
      flatOrder['sharePointDisabledSeats']
        = order.products['sharePoint'] ? order.products['sharePoint'].enabledSeats : null;
      flatOrder['groupsEnabledSeats'] = order.products['groups'] ? order.products['groups'].enabledSeats : null;
      flatOrder['groupsDisabledDueToAccessSeats'] = order.products['groups'] ? order.products['groups'].disabledDueToAccessSeats : null;
      flatOrder['groupsDisabledSeats']  = order.products['groups'] ? order.products['groups'].disabledSeats : null;
      return flatOrder;
    };
    this.csvService.exportToCSVFile(this.partnerOrdersPage.partnerOrders, fileName, flattenerFn);
  }

  confirmTransitionModal(): void {
    this.messageService.openConfirmModal("Are you sure you want to transition this partner to V2?  This cannot be undone.").subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isTransitioningPartnerToV2 = true;
        this.apiService.transitionPartnerToV2(this.partnerId).subscribe(() => {
          this.isTransitioningPartnerToV2 = false;
          this.partnerDetails.isV2Partner = true;
          this.refreshPartnerOrders();
          this.messageService.openModal("Partner transitioned to V2 successfully.");
        }, () => {
          this.isTransitioningPartnerToV2 = false;
          this.messageService.openModal("Partner transition to V2 failed, please contact SRE.");
        });
      }
    });
  }


  openDataDogLogs(): void {
    const toTimestamp =  moment().valueOf();
    const fromTimestamp = moment().add(-7, 'days').valueOf();
    const url = `https://app.datadoghq.com/logs?query=%40Properties.PartnerId%3A${this.partnerId}&from_ts=${fromTimestamp}&to_ts=${toTimestamp}&live=true`;
    window.open(url);
  }

  openDataDogRum(): void {
    const toTimestamp =  moment().valueOf();
    const fromTimestamp = moment().add(-7, 'days').valueOf();
    const url = `https://app.datadoghq.com/rum/sessions?query=%40type%3Asession%20%40application.id%3A6bf24784-1afb-49e1-845d-c99b1981ae82%20%40usr.partnerId%3A` +
                `${this.partnerId}&cols=&from_ts=${fromTimestamp}&to_ts=${toTimestamp}&live=true`;
    window.open(url);
  }

}
