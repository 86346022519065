import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BackupJob, GuidRegExPattern } from '@sk-models';
import { ApiService } from 'app/api-service/api.service';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'sk-job-search-input',
  templateUrl: './job-search-input.component.html',
  styleUrls: ['./job-search-input.component.scss']
})
export class JobSearchInputComponent implements OnInit {
  @Input() searchTerm: string = null;
  @Output() jobSelect = new EventEmitter<BackupJob>();

  searchForm: FormGroup = new FormGroup({
    jobSearchInput: new FormControl('', [Validators.pattern(GuidRegExPattern), Validators.required])
  });
  filteredJob: BackupJob;
  timeDelay = 300;
  isLoading = false;
  firstLoad = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {
    // do nothing more
  }

  get inputSearch(): AbstractControl {
    return this.searchForm.get('jobSearchInput');
  }

  ngOnInit(): void {
    this.inputSearch
      .valueChanges
      .pipe(
        debounceTime(this.timeDelay),
        filter(() => {
          if(!this.searchForm.valid) {
            this.filteredJob = null;
            return false;
          }
          return true;
        }),
        tap(() => this.isLoading = true),
        switchMap((searchTerm) => this.searchByJobId(searchTerm))
      )
      .subscribe(job => {
        this.filteredJob = job;
        this.isLoading = false;
        if (this.firstLoad && this.searchTerm && this.filteredJob) {
          this.navigateToJob(this.filteredJob);
        }
        this.firstLoad = false;
      });

    if (this.searchTerm) {
      this.inputSearch.setValue(this.searchTerm);
    }
  }

  searchByJobId(searchTerm: string | unknown): Observable<BackupJob> {
    // This gets fired when the user types OR when the user selects an item from the drop down
    if (!searchTerm || typeof searchTerm !== 'string') {
      return of();
    } else {
      this.searchTerm = searchTerm;
      return this.apiService.fetchSubscriptionJob(searchTerm);
    }
  }

  navigateToJob(data: BackupJob): void {
    this.jobSelect.emit(data);
  }
}
