import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SkyKickComponent } from '../../../../shared/core/skykick-component.base';

@Component({
  selector: 'sk-users-groups-user-audit',
  templateUrl: './users-groups-user-audit.component.html',
  styleUrls: ['./users-groups-user-audit.component.scss']
})
export class UsersGroupsUserAuditComponent extends SkyKickComponent implements OnInit{
  public userId: string;

  constructor(
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('userId');
  }
}
