<h4>Compressed Blob Storage Size</h4>

<div class="notification">
  <div>
    <mat-icon inline class="blue">info</mat-icon>
    NOT PARTNER FACING
  </div>
  Please remember that this is an engineering tool. The value reported below represents the size
  of the storage container where the data is stored, which consists of compressed data and all
  collected revisions. The value is NOT intended to match any size information reported by Office 365.
</div>

<ng-container *ngIf="this.isLoading === false; else spinner">
  <ng-container *ngIf="!elasticSerachLastIndexDate; else error">
    <div class="response">
      <mat-icon inline class="success">done</mat-icon>
      <b>{{blobSize}} bytes ({{(blobSize / (1024*1024*1024)).toFixed(2)}} Gb)</b>
    </div>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <mat-spinner class="loading-spinner"></mat-spinner>
</ng-template>

<ng-template #error>
  <div class="response">
    <mat-icon inline class="error">cloud_off</mat-icon>
    There was a problem contacting Azure, or blob size could not be calculated because it is too large. Please try again or contact SWAT.
  </div>
</ng-template>
