import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';

import { JobsService } from '@sk-services';
import { BackupJob } from '@sk-models';
import { ApiService } from '../../api-service/api.service';

@Component({
  selector: 'sk-order-job-history',
  templateUrl: './order-job-history.component.html',
  styleUrls: ['./order-job-history.component.scss']
})
export class OrderJobHistoryComponent {
  @Input() orderId: string;

  backupOrderJobs: BackupJob[];
  isRefreshing: boolean;

  constructor(
      private apiService: ApiService,
      private jobsService: JobsService,
      private cdr: ChangeDetectorRef
  ) { }

  loadBackupOrderJobs(data: {days:number, refresh: boolean}): void {
    this.isRefreshing = true;

    this.apiService.findBackupOrderJobs(this.orderId, data.days, data.refresh)
      .pipe(map(jobs => jobs.map(j => this.jobsService.computeJobProperties(j))),
        map(arr => arr.sort(this.jobHistoryCompareFn)))
      .subscribe(jobs => {
        this.backupOrderJobs = jobs;
        this.isRefreshing = false;
        this.cdr.detectChanges();
      });
  }

  jobHistoryCompareFn(left: BackupJob, right: BackupJob): number {
    return right.created.valueOf() - left.created.valueOf();
  }
}
