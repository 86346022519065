<div>
  <div *ngIf="isGettingRequster || isGettingRestoreStatsSummary" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="requester">
      <mat-header-cell *matHeaderCellDef>
        <span>Requester</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{element.requester}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="attempts">
      <mat-header-cell *matHeaderCellDef>
        <span>Attempts</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{element.attempts}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef>
        <span>Created</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{(element.created | friendlyDate:"MM/DD/YY hh:mm A") || '--'}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastItemAttempted">
      <mat-header-cell *matHeaderCellDef>
        <span>Last Attempted</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{(element.lastItemAttempted | friendlyDate:"MM/DD/YY hh:mm A") || '--'}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalItemsAttempted">
      <mat-header-cell *matHeaderCellDef>
        <span>Items Attempted</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{element.totalItemsAttempted}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalItemsRestored">
      <mat-header-cell *matHeaderCellDef>
        <span>Items Restored</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{element.totalItemsRestored}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalBytesRestored">
      <mat-header-cell *matHeaderCellDef>
        <span>Total Data Size Restored</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{element.totalBytesRestored || 0}} bytes ({{element.totalBytesRestored | fileSize}})</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pointInTime">
      <mat-header-cell *matHeaderCellDef>
        <span>Point in Time</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{(job.restoreParameters?.pointInTime | friendlyDate) || '--'}}</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns">
    </mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>

  </mat-table>
</div>
