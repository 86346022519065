<div class="component-container">
    <div *ngIf="groupMembership" class="content">
        <div *ngFor="let group of groupMembership">
            <span>
                {{group}}
            </span>
        </div>
    </div>
    <div *ngIf="isLoading" class="loading-spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
