import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackupJob } from '@sk-models';

@Component({
  selector: 'sk-restored-items-modal',
  templateUrl: './restored-items-modal.component.html',
  styleUrls: ['./restored-items-modal.component.scss']
})
export class RestoredItemsModalComponent {
  job: BackupJob;

  constructor(@Inject(MAT_DIALOG_DATA) data: BackupJob) {
    this.job = data;
  }
}
