<div class="page-title-container">
  <div class="page-title">
    <div class="title-icon" *ngIf="icon">
      <mat-icon>{{icon}}</mat-icon>
    </div>
    <div class="title-main">
      <div class="sub-title">
        <span *ngFor="let crumb of breadcrumb" class="breadcrumb">
          <a [routerLink]="crumb.link">{{crumb.title}}</a>
        </span>
      </div>
      <div>
        {{title}}
      </div>
    </div>
    <div class="title-content">
      <ng-content select="[slot=header-content]"></ng-content>
    </div>
  </div>
  <div class="page-title-actions">
    <ng-content select="[slot=header-actions]"></ng-content>
  </div>
</div>
