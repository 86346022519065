import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackupSubscription } from '@sk-models';
import { ApiService } from '../../../api-service/api.service';

@Component({
  selector: 'sk-blob-size-modal',
  templateUrl: './blob-size-modal.component.html',
  styleUrls: ['./blob-size-modal.component.scss']
})
export class BlobSizeModalComponent implements OnInit {
  subscription: BackupSubscription;
  blobSize: number;
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { subscription: BackupSubscription },
    private apiService: ApiService) {
    this.subscription = data?.subscription;
  }

  ngOnInit(): void {
    this.apiService.fetchSubscriptionBackupSize(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe({
        next: size => this.blobSize = size,
        complete: () => this.isLoading = false
      });
  }
}
