<div class="loading-overlay">
  <div class="loading-content">
    <div class="loading-message">
      {{loadingMessage}}
    </div>
    <div class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
