import { Component, Input } from '@angular/core';

@Component({
  selector: 'sk-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent{

  @Input() loadingMessage = "Please Wait..."

}
