import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, BehaviorSubject, Subject, throwError, of, merge } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';

import { AuditResource, AuditPage, AuditFilter } from '@sk-models';
import { ApiService } from '../../api-service/api.service';

interface State {
  page: number;
  pageSize: number;
  pageSizeOptions: number[];
  sortByColumn: string;
  sortDirection: SortDirection;
  pageTotal: number;
}

@Component({
  selector: 'sk-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})

export class AuditComponent implements OnInit, AfterViewInit  {
  @Input() domainEntityId: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'domainEntity_Id',
    'actor_UserIdentifier',
    'actor_Source',
    'subCategory',
    'eventInfo',
    'eventDetails',
    'dateCreated'
  ];

  currentFilters: AuditFilter;
  auditData: MatTableDataSource<AuditResource[]>;
  totalAuditCount: number;

  constructor(private apiService: ApiService) { }

  private search = new Subject<void>();
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  private defaultPageSizeOptions = [12, 50, 100];

  private state: State = {
    page: 0,
    pageSize: this.defaultPageSizeOptions[0],
    pageSizeOptions: this.defaultPageSizeOptions,
    sortByColumn: '',
    sortDirection: 'asc',
    pageTotal: 0
  };

  get page(): number {
    return this.state.page;
  }

  set page(page: number) {
    this._set({ page });
  }

  get pageSize(): number {
    return this.state.pageSize;
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  get pageSizeOptions(): number[] {
    return this.state.pageSizeOptions;
  }

  set pageSizeOptions(pageSizeOptions: number[]) {
    this._set({ pageSizeOptions });
  }

  get pageTotal(): number {
    return this.state.pageTotal;
  }

  set pageTotal(pageTotal: number) {
    this._set({ pageTotal });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this.state, patch);
    this.search.next();
  }

  ngOnInit(): void {
    this.initFilters();
    this.loadAuditPage(true);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange
      .subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.syncFilters()))
      .subscribe();
  }

  initFilters(): void {
    this.currentFilters = new AuditFilter();
    // init page size for api query
    this.currentFilters.pageSize = this.state.pageSize;
    this.currentFilters.sortByColumn = 'dateCreated';
    this.currentFilters.sortByAscending = false;
  }

  syncFilters(filterState?: AuditFilter): void {
    const filter = this.currentFilters;

    if (filterState &&
        filterState !== this.currentFilters) {
      this.paginator.firstPage();
      this.pageSize = this.paginator.pageSize;
    }

    filter.pageNumber = !this.paginator.pageIndex ? 0 : this.paginator.pageIndex;
    filter.pageSize = this.paginator.pageSize;

    if (this.sort.active) {
      filter.sortByColumn = this.sort.active;
      filter.sortByAscending = this.sort.direction === 'asc';
    }

    this.currentFilters = filter;

    this.pageSizeOptions = this.defaultPageSizeOptions
      .filter(x => x <= this.pageTotal )
      .concat(this.pageTotal);

    this.loadAuditPage(true);
  }

  loadAuditPage(reload?: boolean): void {
    this.loadingSubject.next(true);
    this.findAudits(this.domainEntityId, this.currentFilters, reload)
      .pipe(
        map(pg => pg.audits),
        catchError((error) => this.errorHandler(error)),
        finalize(() => this.loadingSubject.next(false)))
      .subscribe(audits => {
        this.auditData = new MatTableDataSource(audits);
        this.auditData.sort = this.sort;
      });
  }

  findAudits(domainEntityId: string,
    filter: AuditFilter = null,
    reload = false): Observable<AuditPage> {
    return this.apiService
      .findHumanAudits(domainEntityId, filter, reload)
      .pipe(
        tap(pg => {
          this.page = pg.currentPage;
          this.pageSize = pg.pageSize;
          this.pageTotal = pg.totalCount;
          this.pageSizeOptions = this.defaultPageSizeOptions;
        })
      );
  }

  // unless you want to spend the time adding a strongly typed exception class, just ignore this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private errorHandler(error: any): Observable<any[]> {
    if (error.status === 503) {
      return throwError(error);
    } else {
      return of([]);
    }
  }
}


