<div class="job-history-day-range">
  You are viewing data from the past
    <mat-select [value]="userDays" (selectionChange)="daysQuery($event)">
      <mat-option *ngFor="let day of days" [value]="day">
        {{day}} days
      </mat-option>
    </mat-select>
</div>


