<div>
  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="relativeUrl">
      <mat-header-cell *matHeaderCellDef>
        <span>Relative Url</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{row.relativeUrl}}</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns">
    </mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>

  </mat-table>
</div>
