import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { saveAs } from 'file-saver';
import { BackupJob } from '@sk-models';
import { ApiService } from '../../api-service/api.service';

@Component({
  selector: 'sk-restore-result-export',
  templateUrl: './restore-result-export.component.html',
  styleUrls: ['./restore-result-export.component.scss']
})
export class RestoreResultExportComponent {
  isLoading: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BackupJob,
    public dialogRef: MatDialogRef<RestoreResultExportComponent>,
    private apiService: ApiService
  ) { }

  onExportRestoreResult(): void {
    this.errorMessage = null;
    this.isLoading = true;
    this.apiService.exportRestore(this.data.id, this.data.resourceKey)
      .subscribe(data => this.downloadFile(data))
      .add(() => this.isLoading = false);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  downloadFile(data: any): void {
    if (data) {
      saveAs(data, `Restore Results ${this.data.id}.xlsx`);
    }
  }
}
