import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sk-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BadgeComponent {
  @HostBinding('class') class ='d-flex';

  @Input() text: string;
  @Input() colorClass: string;
  @Input() icon: string;
}
