import { Injectable } from '@angular/core';
import { IGraphJourney, PathReturnType } from '../models/graph-journey.interface';

@Injectable({
  providedIn: 'root'
})
export class GraphJourneyRegisterService {

  private _registrations = {};
  private _pathRegistrations = {};

  private confirmNotRegistered(name: string): boolean {
    if (this._registrations[name] != null) {
      throw ("Duplicate Registration " + name);
    } else if (this._pathRegistrations[name] != null) {
      throw ("Duplicate Path " + name);
    }
    return false;
  }

  getRegistration(name: string): IGraphJourney {
    return this._registrations[name];
  }

  getPathRegistration(name: string): PathReturnType {
    return this._pathRegistrations[name];
  }

  register(journey: IGraphJourney): void {
    this.confirmNotRegistered(journey.key);
    this._registrations[journey.key] = journey;
  }

  isRegistered(name: string): boolean {
    return this._registrations[name] != null;
  }

  registerPath(name: string, func: PathReturnType): void {
    this.confirmNotRegistered(name);
    this._pathRegistrations[name] = func;
  }

  isPathRegistered(name: string): boolean {
    return this._pathRegistrations[name] != null;
  }

}
