<mat-tab-group class="tab-container" mat-stretch-tabs="false" mat-align-tabs="center" (selectedTabChange)="onTabChange($event)">
  <mat-tab [label]="usersSearchTabName">
    <ng-template mat-tab-label>
      <div class="tab">
        <mat-icon>person</mat-icon>
        Users
      </div>
    </ng-template>
    <div *ngIf="usersSearchLoaded || currentTabName === usersSearchTabName">
      <sk-users-groups-user-search class="d-flex flex-column page-container-fixed-width" [backupServiceId]="orderId"></sk-users-groups-user-search>
    </div>
  </mat-tab>
  <mat-tab [label]="groupsSearchTabName">
    <ng-template mat-tab-label>
      <div class="tab">
        <mat-icon>group</mat-icon>
        Groups
      </div>
    </ng-template>
    <div *ngIf="groupsSearchLoaded || currentTabName === groupsSearchTabName">
      <sk-users-groups-group-search class="d-flex flex-column page-container-fixed-width" [backupServiceId]="orderId"></sk-users-groups-group-search>
    </div>
  </mat-tab>
  <mat-tab [label]="collaborationSearchTabName">
    <ng-template mat-tab-label>
      <div class="tab">
        <mat-icon fontSet="material-icons-outlined">polyline</mat-icon>
        Collaboration
      </div>
    </ng-template>
    <div *ngIf="collaborationSearchLoaded || currentTabName === collaborationSearchTabName">
      <sk-collaboration-search class="d-flex flex-column page-container-fixed-width" [backupServiceId]="orderId"></sk-collaboration-search>
    </div>
  </mat-tab>
</mat-tab-group>
