import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Bookmark } from '@sk-models';

@Component({
  selector: 'sk-bookmark',
  templateUrl: './bookmark.component.html'
})
export class BookmarkComponent implements OnInit {
  @Output() navigate = new EventEmitter<Bookmark>();
  @Output() bookmarkRemoved = new EventEmitter<Bookmark>();
  @Input()  bookmarks: Bookmark[];
  @Input()  editMode = false;
  @Input()  title: string;
  canBeRemoved: boolean;

  ngOnInit(): void {
    this.canBeRemoved = this.bookmarkRemoved.observers.length > 0;
  }


  onRemove(bookmark: Bookmark): void {
    this.bookmarkRemoved.emit(bookmark);
  }

  onNavigate(bookmark: Bookmark): void {
    if (this.editMode === false) {
      this.navigate.emit(bookmark);
    }
  }
}


