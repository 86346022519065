import { Component, Input, OnInit } from '@angular/core';
import { BackupSubscription } from '@sk-models';

@Component({
  selector: 'sk-sub-menu-links',
  templateUrl: './subtab-menu-links.component.html',
  styleUrls: ['./subtab-menu-links.component.scss']
})

export class SubMenuLinksComponent implements OnInit {
  @Input() subscription: BackupSubscription = null;

  tabs: Array<{route: string, name: string}>;

  ngOnInit(): void {
    this.tabs = [
      { route: 'subscription-details', name: 'Summary' },
      { route: 'jobs', name: 'Job History' },
      { route: 'audit', name: 'Audit' },
      { route: 'subscription-health', name: 'Subscription Health' }
    ];

    if (this.subscription?.resourceKey?.resourceType !== 'TeamsChats') {
      this.tabs.push(
        { route: 'subscription-browser', name: 'Browse Subscription' }
      );
    }

    this.tabs.push(
      { route: 'subscription-reindex-history', name: 'Reindexes' }
    );
  }
}
