<div class="component-container">
  <div *ngIf="!loading">
    <sk-promo-details *ngIf="activeTab === 'promo-details' && promo" [promo]="promo">
    </sk-promo-details>

    <sk-billing *ngIf="activeTab === 'billing'">
    </sk-billing>
  </div>
  <div *ngIf="loading" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
