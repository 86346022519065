<div class="order-actions" *ngIf="order">
    <div class="d-flex flex-grow-1 title-container">
        <span class="title">Order Actions</span>
    </div>

    <div class="actions-form">
        <div class="actions-list">
            <div *ngFor="let action of actions"
                (click)="selectAction(action)"
                [class.selected]="selectedAction && action.id==selectedAction.id && !isActionInProgress"
                [class.disabled]="isActionInProgress"
                id="{{action.id}}">
                <span [title]="action.hoverText">{{action.name | startCase}}</span>
            </div>
        </div>
    </div>

    <div class="change-partner-select action-setup" *ngIf="selectedAction && selectedAction.id == 'changePartner'">
        <div *ngIf="!oldPartner" class="loading-spinner">
            <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="oldPartner" class="old-partner">
            <div>
                <label>Current:</label>
                <span>{{oldPartner.name}}</span>
            </div>
            <div *ngIf="newPartnerErrorMessage" class="new-partner-error-message">
                <p>{{newPartnerErrorMessage}}</p>
            </div>
            <div *ngIf="!newPartner"
                class="new-partner-input">
                <input #newPartnerId
                    class="form-control"
                    placeholder="New Partner Id..."
                    (keyup.enter)="loadNewPartner(newPartnerId.value)" />

                <div *ngIf="!newPartnerIsLoading"
                    class="button clickable"
                    (click)="loadNewPartner(newPartnerId.value)">
                    <span>Load</span>
                </div>
                <div *ngIf="newPartnerIsLoading">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </div>

        <div *ngIf="newPartner"
            class="new-partner-confirm">
            <label>New:</label>
            <span>{{newPartner.name}}</span>
            <mat-icon
                class="error clickable new-partner-clear"
                title="Clear"
                (click)="clearNewPartner()">
                close
            </mat-icon>
        </div>
    </div>

    <div *ngIf="selectedAction"
        class="execute-action clickable btn"
        [class.disabled]="!canExecuteSelectedAction"
        [class.danger]="selectedAction.dangerLevel == 'high'"
        [class.warn]="selectedAction.dangerLevel == 'medium'"
        [class.safe]="selectedAction.dangerLevel == 'low'"
        (click)="executeSelectedAction()"
        id="{{selectedAction.id}}Button">
        <span>{{selectedAction.name}}</span>
        <div *ngIf="isActionInProgress" class="loading-spinner">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>

<div *ngIf="!order" class="loading-spinner">
    <mat-spinner></mat-spinner>
</div>
