import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JobsService, MessageService } from '@sk-services';
import { BackupJob } from '@sk-models';
import { ApiService } from '../api-service/api.service';

import { Guid } from 'guid-typescript';

@Component({
  selector: 'sk-restore-job-history',
  templateUrl: './restore-job-history.component.html',
  styleUrls: ['./restore-job-history.component.scss']
})
export class RestoreJobHistoryComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1 flex-column';

  restoreJobs: BackupJob[];
  isAuthenticating: boolean;
  isRefreshing: boolean;
  partnerId: string;
  orderId: string;
  subscriptionId: string;
  loadEverything: boolean;
  orderPosition = 1;

  displayedColumns: string[] = [
    'id',
    'orderName',
    'type',
    'status',
    'attempts',
    'created',
    'started',
    'finished',
    'duration',
    'errorMessage'
  ];

  idTypes: string[] = [
    'Subscription',
    'Order',
    'Partner',
    'All'
  ];

  searchForm: FormGroup;

  constructor(
      private apiService: ApiService,
      private jobsService: JobsService,
      private activatedRoute: ActivatedRoute,
      private messageService: MessageService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.initFormFilters();
    this.loadIncomingQueryParams();
    this.loadRestoreJobs(false);
  }

  loadIncomingQueryParams(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.orderId = params['orderId'];
      this.subscriptionId = params['subscriptionId'];
      this.partnerId = params['partnerId'];
    });
  }

  initFormFilters(): void {
    this.searchForm = new FormGroup({
      idType: new FormControl(),
      idInput: new FormControl()
    });
  }

  loadRestoreJobs(reload: boolean): void {
    this.isRefreshing = true;

    const idInput = this.searchForm.get('idInput');
    const idType = this.searchForm.get('idType');

    let jobsObservable: Observable<BackupJob[]>;
    if (this.subscriptionId) {
      jobsObservable = this.apiService.getRestoreJobsForSubscription(this.subscriptionId, reload);
    } else if (this.orderId) {
      idInput.setValue(this.orderId);
      idType.setValue(this.idTypes[this.orderPosition]);
      jobsObservable = this.apiService.getRestoreJobsForOrder(this.orderId, reload);
    } else if (this.partnerId) {
      jobsObservable = this.apiService.getRestoreJobsForPartner(this.partnerId, reload);
    } else if (this.loadEverything === true) {
      jobsObservable = this.apiService.getRestoreJobs(reload);
    } else {
      // no id to load jobs for
      this.isRefreshing = false;
      this.restoreJobs = [];
      return;
    }

    if (!jobsObservable) {
      this.messageService.openModal(`No jobs found. Make sure you provided valid search criteria.`);
      this.isRefreshing = false;
      return;
    }
    jobsObservable
      .pipe(map(jobs => jobs.map(j => this.jobsService.computeJobProperties(j))))
      .subscribe(jobs => {
        this.restoreJobs = jobs;
      })
      .add(() => this.isRefreshing = false);
  }

  validateIdType(): boolean {
    const idTypeFormControl = this.searchForm.get('idType');
    if (!idTypeFormControl || this.idTypes.indexOf(idTypeFormControl.value) < 0) {
      return false;
    } else {
      return true;
    }
  }

  validateIdInput(): boolean {
    // no need for ID if "All" is selected, so don't bother validating
    const idTypeFormControl = this.searchForm.get('idType');
    if (idTypeFormControl &&
        idTypeFormControl.value === 'All') {
      return true;
    }

    const idInputFormControl = this.searchForm.get('idInput');

    // do null check then type check before guid check to avoid errors
    if ((idInputFormControl) &&
      (typeof idInputFormControl.value === 'string') &&
      (Guid.isGuid(idInputFormControl.value))) {
      return true;
    } else {
      return false;
    }
  }

  searchButtonDisabled(): boolean {
    const apiCallInProgress = this.isRefreshing ||
                              this.isAuthenticating;
    const invalidSearchParameters = !this.validateIdType() ||
                                    !this.validateIdInput();

    return apiCallInProgress || invalidSearchParameters;
  }

  idInputDisabled(): void {
    const apiCallInProgress = this.isRefreshing ||
                              this.isAuthenticating;

    const idInputFormField = this.searchForm.get('idInput');
    const idTypeFormField = this.searchForm.get('idType');
    if (apiCallInProgress) {
      // clear when disabling to avoid confusion in the UI
      idInputFormField.disable();
      idTypeFormField.disable();
    } else {
      idInputFormField.enable();
      idTypeFormField.enable();
    }
  }

  search(): void {
    this.restoreJobs = [];
    this.subscriptionId = null;
    this.orderId = null;
    this.partnerId = null;
    this.loadEverything = false;

    const idInput = this.searchForm.get('idInput').value;
    const idType = this.searchForm.get('idType').value;
    let queryParam: string;
    switch (idType) {
    case 'Subscription':
      this.subscriptionId = idInput;
      queryParam = `subscriptionId=${idInput}`;
      break;
    case 'Order':
      this.orderId = idInput;
      queryParam = `orderId=${idInput}`;
      break;
    case 'Partner':
      this.partnerId = idInput;
      queryParam = `partnerId=${idInput}`;
      break;
    case 'All':
      this.loadEverything = true;
      queryParam = `all`;
      break;
    }

    this.loadRestoreJobs(false);
    this.router.navigateByUrl(`${this.activatedRoute.snapshot.url}?${queryParam}`);
  }

  exportCSV(): void {
    const items = this.restoreJobs;
    const replacer = (key, value) => value === null ? '' : value;
    const header = ['Order Name'].concat(Object.keys(items[0]));
    const csvEntities = items.map(row => [row.restoreParameters?.backupOrderData?.orderName]
      .concat(header.map(fieldName => JSON.stringify(row[fieldName], replacer)))
      .join(','));
    csvEntities.unshift(header.join(','));
    const csv = csvEntities.join('\r\n');
    const fileName = 'RestoreHistory' + window.location.search + '.csv';
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
