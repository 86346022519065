import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../api-service/api.service';
import { SkippedRestoreItem } from '@sk-models';
import { MessageService } from '@sk-services';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'sk-skipped-restore-items-report',
  templateUrl: './skipped-restore-items-report.component.html',
  styleUrls: ['./skipped-restore-items-report.component.scss']
})
export class SkippedRestoreItemsReportComponent implements OnInit {

  skippedItems: SkippedRestoreItem[];
  dataSource: MatTableDataSource<SkippedRestoreItem>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isLoading = false;
  benchmark: number = Date.now() - 14;
  displayedColumns: string[] = [
    'skyKickId',
    'parentSkyKickId',
    'abandonedReason',
    'timestamp'
  ];

  constructor(private apiService: ApiService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.messageService.openCustomModal(GenericModalComponent, "We think this component is no longer used, if you're seeing this please reach out to SWAT", "MISSING COMPONENT");

    this.isLoading = true;
    this.apiService.findSkippedRestoreItemAggregate()
      .subscribe({
        next: results => this.setDataSource(results),
        complete: () => this.isLoading = false
      });
  }

  setDataSource(items: SkippedRestoreItem[]): void {
    if (items) {
      this.skippedItems = items;
      this.dataSource = new MatTableDataSource(this.skippedItems);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  showErrorStackTrace(stackTrace: string): void {
    this.messageService.openCustomModal(GenericModalComponent, stackTrace, "Stack Trace");
  }
}
