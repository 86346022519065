import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SkyKickComponent } from '../../../../shared/core/skykick-component.base';

@Component({
  selector: 'sk-users-groups-group-audit',
  templateUrl: './users-groups-group-audit.component.html',
  styleUrls: ['./users-groups-group-audit.component.scss']
})
export class UsersGroupsGroupAuditComponent extends SkyKickComponent implements OnInit{
  public groupId: string;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.groupId = this.route.snapshot.paramMap.get('groupId');
  }
}
