import { Moment } from 'moment';

import { BackupEntityType, RetentionPurgeState } from './backup-enums';
import { RevisionEntity } from './backup-revision-entity';

export class TreeEntity {
  name: string;
  relativeURL: string;
  friendlyURL: string;
  skyKickId: string;
  parentId: string;
  createdBy: string;
  lastModifiedBy: string;
  size: number;
  type: BackupEntityType = BackupEntityType.Folder;
  children: TreeEntity[];
  revisions: RevisionEntity[];
  timeDiscovered: Moment;
  timeDeleteDetected: Moment;
  isRestored: boolean;
  includeInSearch: boolean;
  purgeState: RetentionPurgeState = RetentionPurgeState.None;
  nativeId: string;
  nativeChangeToken: string;
  errorMessage?: string;
  public equals(obj: TreeEntity) : boolean {
    return this.skyKickId === obj.skyKickId;
  }
}
