import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { AzureGroupType, ResourceInfo, Group } from 'app/users-groups/models/group.model';
import { UsersGroupsService } from 'app/users-groups/services/users-groups.service';
import { UsersSearchGridComponent } from '../../users-groups-user/users-groups-user-search/users-search-grid/users-search-grid.component';
import { GroupsSearchGridComponent } from '../users-groups-group-search/groups-search-grid/groups-search-grid.component';
import { SearchFilter } from 'app/users-groups/models/users-groups-search.model';

interface Resource {
  subscriptionType: string;
  resourceType: string;
  subscriptionId: string;
}

@Component({
  selector: 'sk-users-groups-group-detail',
  templateUrl: './users-groups-group-detail.component.html',
  styleUrls: ['./users-groups-group-detail.component.scss']
})
export class UsersGroupsGroupDetailComponent  extends SkyKickComponent implements OnInit {

  public isLoading = true;
  public backupServiceId: string;
  public groupId: string;
  public groupDetails: Group = null;
  public resources: Resource[] = [];
  public azureGroupTypeEnum: AzureGroupType;

  @ViewChild('groupMembershipSearch') groupMembershipSearch: GroupsSearchGridComponent;
  @ViewChild('userMembershipSearch') userMembershipSearch: UsersSearchGridComponent;

  constructor(
    private route: ActivatedRoute,
    private usersGroupsService: UsersGroupsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.backupServiceId = this.route.parent.snapshot.paramMap.get('id');
    this.groupId = this.route.snapshot.paramMap.get('groupId');

    this.subs.sink = this.usersGroupsService
      .getGroup(this.backupServiceId, this.groupId)
      .subscribe((res) => {
        this.groupDetails = res.value[0];
        this.resources = this.buildAvailableResources(this.groupDetails.collaborationResourceInfo);
      },
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      });
  }

  buildAvailableResources(collabResInfo: ResourceInfo): Resource[] {
    const resources = [];
    if (collabResInfo?.resourceKey !== null) {
      resources.push({
        subscriptionType: collabResInfo?.resourceKey.subscriptionType,
        id: collabResInfo?.resourceKey.subscriptionId,
        resourceType: collabResInfo?.resourceKey.resourceType,
      });
    }
    return resources;
  }

  getGroupTypeName(groupType: number): string {
    return AzureGroupType[groupType] || 'Unknown';
  }

  private groupMembershipExpanded = false;
  refreshGroupMembership(): void {
    if (this.groupMembershipExpanded === false) {
      this.groupMembershipSearch.refresh({ term: '' });
      this.groupMembershipExpanded = true;
    }
  }

  onGroupSearchFilterChanged(filter: SearchFilter): void {
    this.groupMembershipSearch.refresh(filter);
  }

  private userMembershipExpanded = false;
  refreshUserMembership(): void {
    if (this.userMembershipExpanded === false) {
      this.userMembershipSearch.refresh({ term: '' });
      this.userMembershipExpanded = true;
    }
  }

  onUserSearchFilterChanged(filter: SearchFilter): void {
    this.userMembershipSearch.refresh(filter);
  }
}
