<div class="component-container">
  <div class="d-flex flex-grow-1 title-container">
    <span class="title">Snapshot Health</span>
  </div>

  <div class="filters">
    <mat-form-field class="date-filter">
      <mat-label>From</mat-label>
      <input matInput [matDatepicker]="fromDatePicker" [min]="minDate" (dateChange)="loadSnapshotRates()"
        [formControl]="fromDate">
      <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="date-filter">
      <mat-label>To</mat-label>
      <input matInput [matDatepicker]="toDatePicker" [max]="maxDate" (dateChange)="loadSnapshotRates()"
        [formControl]="toDate">
      <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div *ngIf="snapshotHealth && !isLoading" class="table">
    <div class="snapshot-rates">
      <span class="row header">Subscription Type</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <a routerLink="/orders/{{_orderId}}/subscriptions"
          [queryParams]="{subscriptionType: count.type | subscriptionType}"
          class="clickable type">{{count.type | startCase}}
        </a>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header" title="5+ times/day">5+</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null d-flex justify-content-center" *ngIf="count.fivePlus.length > 0">
          <a routerLink="/orders/{{_orderId}}/subscriptions"
            [queryParams]="{searchString: count.fivePlus.slice(0, 197).join(',')}" target="_blank"
            title="Show first 197 subscriptions (copy ids to see all)"
            class="clickable type">{{count.fivePlus.length}}</a>
          <sk-click-to-copy class="null" hoverText="Click to copy all ids" [copyText]="count.fivePlus.join(',')">
          </sk-click-to-copy>
        </div>
        <div class="null" *ngIf="count.fivePlus.length == 0">0</div>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header" title="3+ times/day">3+</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null d-flex justify-content-center" *ngIf="count.threePlus.length > 0">
          <a routerLink="/orders/{{_orderId}}/subscriptions"
            [queryParams]="{searchString: count.threePlus.slice(0, 197).join(',')}" target="_blank"
            title="Show first 197 subscriptions (copy ids to see all)"
            class="clickable type">{{count.threePlus.length}}</a>
          <sk-click-to-copy class="null" hoverText="Click to copy all ids" [copyText]="count.threePlus.join(',')">
          </sk-click-to-copy>
        </div>
        <div class="null" *ngIf="count.threePlus.length == 0">0</div>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header" title="1+ times/day">1+</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null d-flex justify-content-center" *ngIf="count.onePlus.length > 0">
          <a routerLink="/orders/{{_orderId}}/subscriptions"
            [queryParams]="{searchString: count.onePlus.slice(0, 197).join(',')}" target="_blank"
            title="Show first 197 subscriptions (copy ids to see all)"
            class="clickable type">{{count.onePlus.length}}</a>
          <sk-click-to-copy class="null" hoverText="Click to copy all ids" [copyText]="count.onePlus.join(',')">
          </sk-click-to-copy>
        </div>
        <div class="null" *ngIf="count.onePlus.length == 0">0</div>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header" title="0 times/day">0</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null d-flex justify-content-center" *ngIf="count.zero.length > 0">
          <a routerLink="/orders/{{_orderId}}/subscriptions"
            [queryParams]="{searchString: count.zero.slice(0, 197).join(',')}" target="_blank"
            title="Show first 197 subscriptions (copy ids to see all)" class="clickable type">{{count.zero.length}}</a>
          <sk-click-to-copy class="null" hoverText="Click to copy all ids" [copyText]="count.zero.join(',')">
          </sk-click-to-copy>
        </div>
        <div class="null" *ngIf="count.zero.length == 0">0</div>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header" title="Excluded from snapshots">Excluded</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null d-flex justify-content-center" *ngIf="count.excluded.length > 0">
          <a routerLink="/orders/{{_orderId}}/subscriptions"
            [queryParams]="{searchString: count.excluded.slice(0, 197).join(',')}" target="_blank"
            title="Show first 197 subscriptions (copy ids to see all)"
            class="clickable type">{{count.excluded.length}}</a>
          <sk-click-to-copy class="null" hoverText="Click to copy all ids" [copyText]="count.excluded.join(',')">
          </sk-click-to-copy>
        </div>
        <div class="null" *ngIf="count.excluded.length == 0">0</div>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header" title="Total error counts of snapshots">Failures</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null d-flex justify-content-center" *ngIf="count.errors.length > 0">
          <a routerLink="/orders/{{_orderId}}/subscriptions"
            [queryParams]="{searchString: count.errors.slice(0, 197).join(',')}" target="_blank"
            title="Show first 197 subscriptions (copy ids to see all)"
            class="clickable type">{{count.errors.length}}</a>
          <sk-click-to-copy class="null" hoverText="Click to copy all ids" [copyText]="count.errors.join(',')">
          </sk-click-to-copy>
        </div>
        <div class="null" *ngIf="count.errors.length == 0">0</div>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header">Last Success Time</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null">{{count.lastSuccessTime | friendlyDate}}
        </div>
      </span>
    </div>

    <div class="snapshot-rates">
      <span class="row header">Last Failure Time</span>
      <span *ngFor="let count of snapshotHealth" class="row value">
        <div class="null" *ngIf="count.lastFailTime">{{count.lastFailTime | friendlyDate}}</div>
        <div class="null" *ngIf="!count.lastFailTime">-</div>
      </span>
    </div>
  </div>

  <div *ngIf="!snapshotHealth || isLoading" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
