import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BackupEntity, BackupEntityType, BackupEntityFilter, BackupSubscription, BackupSubscriptionTypes, ElasticSearchRequest, ResourceKey, authUserInRole, AuthRoles } from '@sk-models';
import { AuthService, EntityDownloadService, ToastService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FilterService } from '@sk-services';
import { filter, map, take } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';

export class SubscriptionBackupEntity extends BackupEntity {
  typeDesc: string;
  browseSubscriptionLink: string;
  iconName: string;

  constructor(backupEntity: BackupEntity) {
    super();
    this.name = backupEntity.name;
    this.type = backupEntity.type;
    this.typeDesc = BackupEntityType[backupEntity.type];
    this.iconName = BackupEntityType.iconName(backupEntity.type),
    this.size = backupEntity.size;
    this.friendlyUrl = backupEntity.friendlyUrl;
    this.relativeUrl = backupEntity.relativeUrl;
    this.parentSkyKickId = backupEntity.parentSkyKickId;
    this.skyKickId = backupEntity.skyKickId;
    this.nativeId = backupEntity.nativeId;
    this.browseSubscriptionLink = this.getBrowseSubscriptionLink(this.skyKickId, this.parentSkyKickId);
  }

  getBrowseSubscriptionLink(skyKickId: string, parentSkyKickId: string): string {
    const baseUrl = `${window.location.href.substring(0, window.location.href.indexOf('/subscription-search'))}/subscription-browser`;
    if (skyKickId === '00000000-0000-0000-0000-000000000000') {
      return baseUrl;
    }
    return `${baseUrl}?parentSkId=${parentSkyKickId}&skId=${skyKickId}`;
  }
}

@Component({
  selector: 'sk-subscription-search',
  templateUrl: './subscription-search.component.html',
  styleUrls: ['./subscription-search.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class SubscriptionSearchComponent extends SkyKickComponent implements OnInit, OnDestroy {
  @Input() backupSubscription: BackupSubscription;

  response$: Observable<SubscriptionBackupEntity[]>;
  isVisible = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private filterService: FilterService,
    private entityDownloadService: EntityDownloadService,
    private authService: AuthService
  ) { 
    super();
    this.authService.authData$.pipe(filter(x => x != null), take(1)).subscribe((data) => {
      this.isVisible = authUserInRole(data, AuthRoles.Engineer);
    });
  }

  //Note: these column names need to match the data field names for sorting to work
  displayedColumns: string[] = ['iconName', 'name', 'friendlyUrl', 'size', 'skyKickId', 'parentSkyKickId', 'browseSubscriptionLink'];

  isRefreshing = false;
  expandedRow: SubscriptionBackupEntity;
  subscriptionTypes: string[];
  searchForm: FormGroup;
  filterForm: FormGroup;
  filter = new BackupEntityFilter();
  searchString: string;
  subscription: Subscription;
  dataStore: SubscriptionBackupEntity[];
  dataSource: MatTableDataSource<SubscriptionBackupEntity>;
  resourceTypes: string[];
  maxResults = 5000;

  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  ngOnInit(): void {
    this.dataStore = [];
    this.initSearchControl();
    this.initFilter();
    this.subscriptionTypes = this.buildSubscriptionTypeList();
  }

  private buildSubscriptionTypeList(): string[] {
    const valueList: string[] = Object.keys(BackupSubscriptionTypes);
    return valueList;
  }

  get selectedSearchQuery(): string {
    return this.searchForm.get('searchInput').value;
  }

  get selectedFilterSearchQuery(): string {
    return this.filterForm.get('textInput').value;
  }

  get selectedFilterResourceType(): string {
    return this.filterForm.get('resourceType').value;
  }

  public searchElastic(full: boolean): void {

    const resourceKey = new ResourceKey();
    resourceKey.resourceType = this.backupSubscription.resourceKey.resourceType;
    resourceKey.subscriptionId = this.backupSubscription.id;

    const request = new ElasticSearchRequest();
    request.query = this.selectedSearchQuery == "" ? null : this.selectedSearchQuery;
    request.maxResults = this.maxResults;
    request.includeUrl = full;

    this.loadingSubject.next(true);
    this.subs.sink = this.apiService.searchInfo(this.backupSubscription.backupServiceId, resourceKey, request, true)
      .pipe(
        map((entities: BackupEntity[]) => entities.map(item => (new SubscriptionBackupEntity(item)))),
      )
      .subscribe(entities => {
        this.dataStore = entities;
        this.loadData();
        this.loadingSubject.next(false);
      })
  }

  downloadData(): void {
    this.entityDownloadService.downloadEntitiesAsCsv(this.dataSource.filteredData, "ElasticSearchResults.csv");
  }

  loadData(): void {
    if (this.dataStore && this.dataStore.length === this.maxResults) {
      this.toastService.success("Maximum result size returned", 4);
    }
    // some previousValue indicates that currentValue has changed - refresh grid source
    if (this.dataSource) {
      this.dataSource.data = this.dataStore;
    } else {
      // no previousValue means it has just been initialized
      this.dataSource = new MatTableDataSource(this.dataStore);
      this.sort?.sort({
        id: 'created',
        start: 'desc',
        disableClear: false
      });
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (entity, property) => this.getProperty(entity, property);
      this.dataSource.filterPredicate = this.filterData;
      this.resourceTypes = Array.from(new Set(this.dataSource.data.map((item: SubscriptionBackupEntity) => item.typeDesc)));
    }
  }

  filterData(entity: SubscriptionBackupEntity, filterString: string): boolean {
    // to figure out what's in this delimited list, check
    // wherever the MatTableDataSource.filter is set.
    const filters = filterString?.split(',');

    if (!filters || filters.length === 0){
      return false;
    }

    // check type
    const selectedType = filters[0];
    if (selectedType !== 'All' && selectedType !== entity.typeDesc) {
      return false;
    }

    // check text
    const selectedTextQuery = filters[1];
    if (selectedTextQuery !== 'null' && entity.name?.toLowerCase().indexOf(selectedTextQuery?.toLowerCase()) === -1) {
      return false;
    }

    return true;
  }

  initSearchControl(): void {
    this.searchForm = new FormGroup({
      searchInput: new FormControl(this.searchString),
    });
  }

  initFilter(): void {
    let existingFilter = this.filterService.getFilter('entitySearchFilter') as BackupEntityFilter;
    if (!existingFilter) {
      existingFilter = new BackupEntityFilter();
    }

    this.filterForm = new FormGroup({
      resourceType: new FormControl(existingFilter.resourceType),
      textInput: new FormControl()
    });

    this.subs.sink = this.filterForm.valueChanges
      .subscribe(() => this.updateCurrentFilter());
  }

  updateCurrentFilter(): void {
    const filter = new BackupEntityFilter();
    filter.resourceType = this.selectedFilterResourceType;
    filter.searchString = this.selectedFilterSearchQuery;

    this.filterService.setFilter('entitySearchFilter', filter);
    this.dataSource.filter = `${this.selectedFilterResourceType},${this.selectedFilterSearchQuery}`;
  }

  resetCurrentFilter(): void {
    this.filterService.clearFilter('entitySearchFilter');
    this.initFilter();
    this.updateCurrentFilter();
  }

  getProperty(entity: SubscriptionBackupEntity, columnName: string): string | number {
    const propertyName = columnName.replace(/\s/g, '');
    return entity[propertyName];
  }

  clickedChevron(row: SubscriptionBackupEntity): void {
    this.expandedRow = this.expandedRow === row ? null : row;
  }
}
