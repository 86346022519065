import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sk-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {

  @Input() title: string;
  @Input() info: string;
  @Input() collapsible = false;
  @Input() expanded = false;
  @Input() noBorder = false;

  @Output() expandEvent = new EventEmitter<boolean>();

  onExpand(expanded: boolean): void {
    this.expandEvent.emit(expanded);
  }
}
