import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { LicenseDetails } from '@sk-models';
import { ApiService } from '../../../api-service/api.service';

@Component({
  selector: 'sk-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss']
})
export class LicenseDetailsComponent implements OnInit {
  @Input() orderId: string;
  @Input() o365Id: string;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'skuId',
    'skuName',
    'servicePlanId',
    'servicePlanName',
    'servicePlanProvisioningStatus'
  ];

  dataSource: MatTableDataSource<LicenseDetails>;
  licenseDetails: LicenseDetails[];
  isLoading = false;
  provisioned: number;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.fetchSubscriptionLicenseDetails(this.orderId, this.o365Id)
      .subscribe({
        next: results => this.setDataSource(results),
        complete: () => this.isLoading = false
      });
  }

  setDataSource(items: LicenseDetails[]): void {
    if (items) {
      this.licenseDetails = items;
      this.provisioned = items.filter(item => item.servicePlanProvisioningStatus == 'Success').length;
      this.dataSource = new MatTableDataSource(this.licenseDetails);
      this.dataSource.sort = this.sort;
    }
  }
}
