import { ApplicationRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGraphJourney, IGraphJourneyError, IGraphJourneyResult, IRowActionEvent } from 'app/graph-journey/models/graph-journey.interface';
import { GraphJourneyService } from 'app/graph-journey/services/graph-journey.service';
import { SideDrawerService } from 'app/shared/side-drawer/side-drawer.service';
import { TableColumnDefinition } from '../../models/table-column-definition.model';
import { GraphDetailsComponent } from '../graph-details/graph-details.component';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { BehaviorSubject } from 'rxjs';

enum PageState {
 InitialLoad,
 LoadingMore,
 Loaded,
 Error
}

@Component({
  selector: 'sk-graph-journey-stop',
  templateUrl: './graph-journey-stop.component.html',
  styleUrls: ['./graph-journey-stop.component.scss']
})
export class GraphJourneyStopComponent extends SkyKickComponent implements OnInit {

  @Input() journey: IGraphJourney;
  @Input() params: { [key:string]: unknown }
  @Input() orderId: string;
  @Input() displayName: string;
  @Input() autoLoad = false;

  requestedUrl: string = null;

  nextJourney: IGraphJourney = null;
  nextParams: unknown;
  nextDisplayName: string;

  collapsedSubject = new BehaviorSubject<boolean>(false);
  collapsed$ = this.collapsedSubject.asObservable();

  columnData: TableColumnDefinition[] = null;
  data: Array<unknown>;
  skipUrl: string = null;

  message: string;
  // pageStates
  isRefreshing = true;
  isError = false;
  isMoreLoading = false;

  constructor(
    private graphJourneyService: GraphJourneyService,
    private appRef: ApplicationRef,
    private router: Router,
    private sideDrawerService: SideDrawerService) {
    super();
  }

  ngOnInit(): void {
    this.requestedUrl = this.graphJourneyService.buildUrl(this.journey, this.params);
    if(this.autoLoad) {
      this.handleLoad();
    }
  }

  handleLoad(): void {
    this.autoLoad = true;
    this.setPageState(PageState.InitialLoad);
    this.requestedUrl = this.graphJourneyService.buildUrl(this.journey, this.params);
    this.subs.sink = this.graphJourneyService.build(this.orderId, this.journey, this.params).subscribe(
      result => this.handleSuccess(result),
      error => this.handleError(error)
    );
  }

  loadMore(): void {
    this.setPageState(PageState.LoadingMore);
    this.subs.sink = this.graphJourneyService.loadData(this.orderId, this.skipUrl, this.journey).subscribe((result) => {
      this.data = this.data.concat(result.data);
      this.skipUrl = result.next;
      this.setPageState(PageState.Loaded);
    }
    )
  }

  private handleError(error: IGraphJourneyError): void {
    this.requestedUrl = error.url;
    this.message = error.message;
    this.setPageState(PageState.Error);
  }

  private handleSuccess(result: IGraphJourneyResult): void {

    this.data = result.data;

    this.columnData = this.journey.fields.filter( (x) => x.hidden == null || x.hidden == false).map( (x) => {
      return <TableColumnDefinition>{
        field: x.field,
        header: x.name ?? x.field,
        sortable: false
      }
    });

    if(this.journey.childJourneys.length > 0) {
      this.columnData.push(<TableColumnDefinition>{
        renderTemplate: 'actions',
        field: '__paths',
        header: 'Actions',
        sortable: false
      });
    }

    this.setPageState(PageState.Loaded);
    this.skipUrl = result.next;
  }

  onRowClick(event:unknown): void {

    this.sideDrawerService.openDrawer({
      appendTo: null,
      drawerContentComponent: GraphDetailsComponent,
      data: {
        data: event,
        journey: this.journey
      },
      drawerWidth: '700'
    });
  }

  public onCollapseChange(event: boolean): void {
    this.collapsedSubject.next(event);
  }

  public onActionClick(event: IRowActionEvent): void {
    this.collapsedSubject.next(true);
    this.nextJourney = null;
    this.appRef.tick();

    const requestParams = this.graphJourneyService.buildNextRequestParams(this.journey, this.params, event.requestedJourney, event.data);
    const request = this.graphJourneyService.buildNextRequest(event.requestedJourney);
    const nextTitle = this.graphJourneyService.buildNextTitle(this.journey, this.params, event.requestedJourney, event.data);

    this.nextJourney = request;
    this.nextParams = requestParams;

    // Update the URL so we can deep link
    this.updateUrl(this.nextJourney, requestParams);

    this.nextDisplayName = nextTitle;
  }

  private updateUrl(nextJourney: IGraphJourney, requestParams: unknown): void {
    // Update the URL with the selected parameters - this will make our interactions deep linkable
    const urlTree = this.router.createUrlTree([], {
      queryParams: { bspJourney: nextJourney.key ,...requestParams as Record<string, unknown> },
      queryParamsHandling: "merge",
      preserveFragment: true });
    this.router.navigateByUrl(urlTree);
  }

  private setPageState(state: PageState): void {
    switch(state) {
    case PageState.Error:
      this.isError = true;
      this.isRefreshing = false;
      this.isMoreLoading = false;
      break;
    case PageState.InitialLoad:
      this.isError = false;
      this.isRefreshing = true;
      this.isMoreLoading = false;
      break;
    case PageState.Loaded:
      this.isError = false;
      this.isRefreshing = false;
      this.isMoreLoading = false;
      break;
    case PageState.LoadingMore:
      this.isError = false;
      this.isRefreshing = false;
      this.isMoreLoading = true;
      break;
    default:
      throw "Don't know what page state is";
    }
  }

}

