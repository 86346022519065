import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from 'app/custom-material/custom-material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';

import { UsersGroupsComponent } from './users-groups.component';
import { UsersGroupsRoutes } from './users-groups.routing';
import { UsersGroupsLandingComponent } from './components/users-groups-landing/users-groups-landing.component';
// Users
import { UsersGroupsUserDetailComponent } from './components/users-groups-user/users-groups-user-detail/users-groups-user-detail.component';
import { UsersGroupsUserSearchComponent } from './components/users-groups-user/users-groups-user-search/users-groups-user-search.component';
import { UsersSearchGridComponent } from './components/users-groups-user/users-groups-user-search/users-search-grid/users-search-grid.component';
import { UserResourcesSearchGridComponent } from './components/users-groups-user/users-groups-user-search/user-resources-search-grid/user-resources-search-grid.component';
// Groups
import { UsersGroupsGroupDetailComponent } from './components/users-groups-group/users-groups-group-detail/users-groups-group-detail.component';
import { UsersGroupsGroupSearchComponent } from './components/users-groups-group/users-groups-group-search/users-groups-group-search.component';
import { GroupsSearchGridComponent } from './components/users-groups-group/users-groups-group-search/groups-search-grid/groups-search-grid.component';
// Collaboration
import { TeamsGroupsDetailComponent } from './components/users-groups-collaboration/teamsgroups-detail/teamsgroups-detail.component';
import { CollaborationSearchComponent } from './components/users-groups-collaboration/collaboration-search/collaboration-search.component';
import { TeamsGroupsSearchGridComponent } from './components/users-groups-collaboration/collaboration-search/teamsgroups-search-grid/teamsgroups-search-grid.component';
import { CollaborationResourcesSearchGridComponent } from './components/users-groups-collaboration/collaboration-search/collaboration-resources-search-grid/collaboration-resources-search-grid.component';
// Shared
import { UsersGroupsService } from './services/users-groups.service';
import { CollaborationService } from './services/collaboration.service';
import { UsersGroupsStatusIndicatorComponent } from './shared/users-groups-status-indicator/users-groups-status-indicator.component';
import { UserResourceStatusIndicatorComponent } from './shared/user-resource-status-indicator/user-resource-status-indicator.component';
import { UsersGroupsSearchFilterComponent } from './shared/users-groups-search-filter/users-groups-search-filter.component';
import { UsersGroupsRelatedResourcesComponent } from './shared/users-groups-related-resources/users-groups-related-resources.component';
import { UsersGroupsUserAuditComponent } from './components/users-groups-user/user-groups-user-audit/users-groups-user-audit.component';
import { UsersGroupsGroupAuditComponent } from './components/users-groups-group/user-groups-group-audit/users-groups-group-audit.component';

@NgModule({
  declarations: [
    UsersGroupsComponent,
    UsersGroupsLandingComponent,
    // Users
    UsersGroupsUserDetailComponent,
    UsersGroupsUserSearchComponent,
    UsersSearchGridComponent,
    UserResourcesSearchGridComponent,
    // Groups
    UsersGroupsGroupDetailComponent,
    UsersGroupsGroupSearchComponent,
    GroupsSearchGridComponent,
    // Collaboration Resources
    TeamsGroupsDetailComponent,
    CollaborationSearchComponent,
    TeamsGroupsSearchGridComponent,
    CollaborationResourcesSearchGridComponent,
    // Shared
    UsersGroupsStatusIndicatorComponent,
    UserResourceStatusIndicatorComponent,
    UsersGroupsSearchFilterComponent,
    UsersGroupsRelatedResourcesComponent,
    UsersGroupsUserAuditComponent,
    UsersGroupsGroupAuditComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(UsersGroupsRoutes),
    SharedModule,
    CustomMaterialModule,
    ReactiveFormsModule
  ],
  providers: [
    UsersGroupsService,
    CollaborationService
  ]
})
export class UsersGroupsModule { }
