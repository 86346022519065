<div class="filter">
  <form [formGroup]="filterForm">
    <mat-grid-list [cols]="7" rowHeight="75px" class="filter-selectors">
      <mat-grid-tile class="filter-form-content mat-grid-left-justify" [rowspan]="1" [colspan]="2">
        <ng-content select="[slot=first-grid-tile]"></ng-content>
      </mat-grid-tile>
      <mat-grid-tile class="filter-form-content mat-grid-left-justify" [rowspan]="1" [colspan]="3">
        <mat-form-field class="search-form-field">
          <mat-label>Search...</mat-label>
          <input matInput formControlName="term" [(ngModel)]="value" type="text" #input>
          <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''; termControl.reset()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>
