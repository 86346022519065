import {
  Component, OnInit, ViewChild, Input, Output,
  EventEmitter, OnChanges, SimpleChanges
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';

import { FilterService, MessageService } from '@sk-services';
import { BackupJob, BackupJobFilter } from '@sk-models';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'sk-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.scss']
})
export class JobHistoryComponent implements OnInit, OnChanges {
  @Input() titleText: string;
  @Input() jobs: BackupJob[];
  @Input() isRefreshing: boolean;
  @Input() displayedColumnsOverride: string[];
  @Input() days: number[] = [];
  @Input() extendedDays: number[] = [];
  @Output() refreshEvent: EventEmitter<{ refresh: boolean, days: number }> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  displayedColumnsDefault: string[] = [
    'id',
    'type',
    'status',
    'attempts',
    'created',
    'started',
    'finished',
    'duration',
    'errorMessage'
  ];

  dataSource: MatTableDataSource<BackupJob>;
  userDays?: number;
  availableDays: number[];
  hasExtendedDayRange = false;
  // filtering variables
  jobTypes: string[];
  jobStatuses: string[];
  errorTypes: string[];
  filterForm: FormGroup;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private filterService: FilterService,
    private messageService: MessageService) {
    this.hasExtendedDayRange = JSON.parse(this.activatedRoute.snapshot?.queryParamMap?.get('extended')) ?? false;
  }

  get selectedJobType(): string { return this.filterForm.get('jobType').value; }
  get selectedJobStatus(): string { return this.filterForm.get('jobStatus').value; }
  get selectedErrorType(): string { return this.filterForm.get('errorType').value; }
  get displayedColumns(): string[] {
    if (this.displayedColumnsOverride) {
      return this.displayedColumnsOverride;
    } else {
      return this.displayedColumnsDefault;
    }
  }

  ngOnInit(): void {
    this.availableDays = this.days;
    if(this.hasExtendedDayRange && this.extendedDays) {
      this.availableDays = this.days.concat(this.extendedDays);
    }

    // Grab the first entry from the available days
    this.userDays =  this.availableDays?.length > 0 ? this.availableDays[0] : null;
    this.initFilter();
    if (this.dataSource) {
      this.updateCurrentFilter();
    }
    this.refreshJobs(false, this.userDays);

    this.setSortDefault();
  }

  reload(): void {
    this.refreshJobs(true, this.userDays);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.jobs &&
      changes.jobs.currentValue) {
      // refresh filter drop lists with whatever the currentValue is
      this.jobTypes = Array.from(new Set(this.jobs.map((item: BackupJob) => item.type)));
      this.jobStatuses = Array.from(new Set(this.jobs.map((item: BackupJob) => item.statusIconTitle)));
      this.errorTypes = Array.from(new Set(this.jobs.map((item: BackupJob) => item.errorType)));

      // some previousValue indicates that currentValue has changed - refresh grid source
      if (changes.jobs.previousValue) {
        this.dataSource.data = this.jobs;

        // no previousValue means it has just been initialized
      } else {
        this.dataSource = new MatTableDataSource(this.jobs);
        this.dataSource.sortingDataAccessor = (job, property) => this.getProperty(job, property);
        this.dataSource.filterPredicate = this.filterData;
        this.setSortDefault();
      }
    }
  }

  initFilter(): void {
    let existingFilter = this.filterService.getFilter('jobHistoryFilter') as BackupJobFilter;
    if (!existingFilter) {
      existingFilter = new BackupJobFilter();
    }

    this.filterForm = new FormGroup({
      jobType: new FormControl(existingFilter.jobType),
      jobStatus: new FormControl(existingFilter.jobStatus),
      errorType: new FormControl(existingFilter.errorType)
    });

    this.filterForm.valueChanges
      .subscribe(() => this.updateCurrentFilter());
  }

  filterData(job: BackupJob, filterString: string): boolean {
    // to figure out what's in this delimited list, check
    // wherever the MatTableDataSource.filter is set.
    const filters = filterString.split(',');

    // check type
    const selectedType = filters[0];
    if (selectedType !== 'All' && selectedType !== job.type) {
      return false;
    }

    // check status
    const selectedStatus = filters[1];
    if (selectedStatus !== 'All' && selectedStatus !== job.statusIconTitle) {
      return false;
    }

    // check error type
    const errorType = filters[2];
    if (errorType !== 'All' && errorType !== job.errorType) {
      return false;
    }

    return true;
  }

  updateCurrentFilter(): void {
    const filter = new BackupJobFilter();
    filter.jobStatus = this.selectedJobStatus;
    filter.jobType = this.selectedJobType;
    filter.errorType = this.selectedErrorType;

    this.filterService.setFilter('jobHistoryFilter', filter);
    this.dataSource.filter = `${this.selectedJobType},${this.selectedJobStatus},${this.selectedErrorType}`;
  }

  resetCurrentFilter(): void {
    this.filterService.clearFilter('jobHistoryFilter');
    this.initFilter();
    this.updateCurrentFilter();
  }

  refreshJobs(reload: boolean, days: number): void {
    this.refreshEvent.emit({ refresh: reload, days });
  }

  getProperty(job: BackupJob, columnName: string): string {
    const propertyName = columnName.replace(/\s/g, '');
    return job[propertyName];
  }

  selectJob(job: BackupJob): string {
    if (job) {
      const orderId = this.activatedRoute.snapshot.paramMap.get('id');
      if (orderId) {
        return this.router.url + `/${job.id}`;
      } else {
        // hacky workaround for detecting if you're viewing a job in the context of the normal portal
        // or a stand alone page (like the restore dash)
        const rootUrl = window.location.href.replace(this.router.url, '');
        const subscriptionUrl = rootUrl +
          `/orders/${job.orderId}/subscriptions/${job.subscriptionId}/${job.resourceType}/jobs/${job.id}`;
        return subscriptionUrl;
      }
    }
  }

  setSortDefault(): void {
    if (this.dataSource && !this.dataSource.sort) {
      this.sort?.sort({
        id: 'created',
        start: 'desc',
        disableClear: false
      });
      this.dataSource.sort = this.sort;
    }
  }

  onDaySelected(days: number): void {
    this.userDays = days;
    this.refreshJobs(false, this.userDays);
  }

  showStackTrace(stackTrace: string): void {
    this.messageService.openCustomModal(GenericModalComponent, stackTrace, "Stack Trace");
    // event.preventDefault();
  }
}
