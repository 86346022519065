import { Moment } from 'moment';

import { OrderState } from '@sk-models';

import { BackupOrderProduct } from './backup-order-product';

export class BackupOrder {
    /* fields */
    id: string;
    name: string;
    salesOrderId: string;
    salesOrderUrl: string;
    migrationSalesOrderUrl: string;
    managingPartnerId: string;
    managingPartnerName: string;
    managingPartnerUrl: string;
    createdOn: Moment;
    placedOn: Moment;
    disabledDueToNonPaymentOn: Moment;
    tenantLocale: string;
    dataCenterName: string;
    searchClusterName: string;
    domain: string;
    stateChanged: Moment;
    orderType?: OrderType;

    /* flags */
    isIur: boolean;
    isTest: boolean;
    state: OrderState;
    sharePointEnabled: boolean;
    exchangeEnabled: boolean;

    /* products (eg Exchange, Sharepoint) */
    products: BackupOrderProductCollection;

    /* Computed properties */
    placementSource: PlacementSource;
    placementSourceTitle: string;
    placementSourceAbbreviation: string;
    stateIcon: string;
    stateIconClass: string;
    stateIconTitle: string;

    public constructor(init?: Partial<BackupOrder>) {
      Object.assign(this, init);
    }
}

export enum PlacementSource {
  WebPlanner = 'WebPlanner',
  Attach = 'Attach',
  Syndication = 'Syndication',
  PI = 'PI',
  PIAmbigueous = 'PIAmbigueous',
  Marketplace = 'Marketplace',
  SourceNotDefined = 'SourceNotDefined'
}

export enum OrderType {
  Comprehensive = 'Comprehensive',
  ExchangeOnly = 'ExchangeOnly',
}

export class BackupOrderProductCollection {
  exchange: BackupOrderProduct;
  sharePoint: BackupOrderProduct;
  oneDrive: BackupOrderProduct;
  groups: BackupOrderProduct;
  teamsChats: BackupOrderProduct;
  users: BackupOrderProduct;
}
