
<sk-side-drawer [open]="drawerOpened" (cancel)="closeDrawer()" class="license-drawer">
  <sk-licensing-drawer *ngIf="drawerOpened" [item]="selectedItem" [itemType]="selectedItem.itemType" (navigateToItem)="onNavigateToItem($event)">
  </sk-licensing-drawer>
</sk-side-drawer>

<div class="order-licensing bsp-content-container">

  <div [hidden]="!dataSource" class="licensing-boxes">
    <div class="license-item-box clickable" [class.inactive]="!licensesIsActive" [class.active]="licensesIsActive"
      (click)="activateItemType('license')">
      <h4>Licenses</h4>
      <h1>{{licensesList.length}}</h1>
    </div>
    <div class="license-item-box clickable" [class.inactive]="!skusIsActive" [class.active]="skusIsActive"
      (click)="activateItemType('sku')">
      <h4>SKUs</h4>
      <h1>{{skusList.length}}</h1>
    </div>
    <div class="license-item-box clickable" [class.inactive]="!usersIsActive" [class.active]="usersIsActive"
      (click)="activateItemType('user')">
      <h4>Users</h4>
      <h1>{{usersList.length}}</h1>
    </div>
  </div>
  <div  class="message-container">
    <!-- Note: We limited the number of times Graph gets iteratively hit on this endpoint to reduce number of Graph calls.
      Engineers, find PageIterator maxReturnCount in LicensingService.cs and increase it locally and run to view all users. -->
    <h5 *ngIf="isOversize" class="max-list-exceeded-warning">! This Order exceeded the max number of Users for our Graph API call, contact SWAT Team to query full list !</h5>
  </div>

  <div [hidden]="!dataSource" class="type-title">
    <span >{{itemType}}s</span>
  </div>

  <div [hidden]="!dataSource" class="table-container mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort matSortActive="displayName" matSortDirection="asc">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </mat-header-cell>
        <mat-cell [ngClass]="{'missing-sku': item.isMissingLicense}" *matCellDef="let item"> {{item[column] || "--"}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable" (click)="selectItem(row)">
      </mat-row>
    </mat-table>
  </div>

  <div *ngIf="!isLoading && (!licenseDetails || licenseDetails?.length == 0)" class="no-results">
    <span>No License Details Found</span>
  </div>

  <div *ngIf="isLoading" class="loading-spinner-modal">
    <mat-spinner></mat-spinner>
  </div>

</div>
