<div [class.mat-elevation-z8]="!dataSource" class="grid-container">
	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					Filters
				</mat-panel-title>
			</mat-expansion-panel-header>
			<form *ngIf="!isLoading" [formGroup]="filterForm" class="filter-form-content">
				<mat-grid-list [cols]="5" rowHeight="75px" class="filter-selectors">
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
              <mat-label>Entity Type</mat-label>
							<mat-select formControlName="entityType">
								<mat-option [value]="'All'">All</mat-option>
								<mat-option *ngFor="let entityType of entityTypes" [value]="entityType">
									{{entityType ? entityType: '--'}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</mat-grid-tile>
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option [value]="'All'">All</mat-option>
								<mat-option *ngFor="let restoreStatus of restoreStatuses" [value]="restoreStatus">
									{{restoreStatus}}
								</mat-option>
              </mat-select>
						</mat-form-field>
					</mat-grid-tile>
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
              <input matInput type="number" placeholder="Minimum size" formControlName="minimumSize">
						</mat-form-field>
					</mat-grid-tile>
          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <mat-form-field>
              <input matInput type="number" placeholder="Maximum size" formControlName="maximumSize">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <button mat-fab extended (click)="resetFilters()">
              <mat-icon>clear</mat-icon>
              Reset Filters
            </button>
          </mat-grid-tile>
          <mat-grid-tile [rowspan]="1" [colspan]="4">
            <mat-form-field class="search-form-field">
                <input matInput placeholder="Search by Name, SkyKick Id, or Full Path..." formControlName="searchInput" #input>
            </mat-form-field>
          </mat-grid-tile>
				</mat-grid-list>
			</form>
		</mat-expansion-panel>
	</mat-accordion>
  <div class="table-container">
    <mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
      <ng-container matColumnDef="expand">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <mat-icon *ngIf="!item.isExpanded">add_box</mat-icon>
          <mat-icon *ngIf="item.isExpanded">indeterminate_check_box</mat-icon>
        </mat-cell>
      </ng-container>
      <!-- need to check -->
      <ng-container matColumnDef="entityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.entityName}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Status</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{getStatus(item)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="entityType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Type</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.entityType}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="numberOfAttempts">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Attempts</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.numberOfAttempts}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="size">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Size</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.size | fileSize}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="skyKickId">
        <mat-header-cell *matHeaderCellDef>
          <span>SkyKickId</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
						<sk-click-to-copy
							hoverText="{{item.skyKickId}}"
							[copyText]="item.skyKickId">
						</sk-click-to-copy>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="parentSkyKickId">
        <mat-header-cell *matHeaderCellDef>
          <span>Parent SkyKickId</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
						<sk-click-to-copy
							hoverText="{{item.parentSkyKickId}}"
							[copyText]="item.parentSkyKickId">
						</sk-click-to-copy>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let item">
          <div>
            <div><b>Full Path: </b>{{item.friendlyUrl}}</div>
            <div *ngIf="item.abandonedReason"><b>Abandoned Reason: </b>{{item.abandonedReason}}</div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row *matRowDef="let item; columns: displayedColumns;" (click)="expandCollapse(item)">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail'];"
        [@detailExpand]="row.isExpanded == true ? 'expanded' : 'collapsed'"
        style="overflow: hidden"
        [class.hidden]="true">
      </mat-row>
    </mat-table>
		<mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons></mat-paginator>
    <div *ngIf="!restoredItems || isLoading" class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
