import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Partner } from '@sk-models';
import { ApiService } from './../api-service/api.service';

@Component({
  selector: 'sk-partner-finder',
  templateUrl: './partner-finder.component.html',
  styleUrls: ['./partner-finder.component.scss']
})
export class PartnerFinderComponent implements OnInit {
  @Output() partnerSelectionEvent: EventEmitter<Partner> = new EventEmitter<Partner>();
  searchForm: FormGroup;
  filteredPartners: Partner[] = [];
  isLoading = false;
  timeDelay: 300;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchInput: new FormControl('')
    });

    this.searchForm.get('searchInput')
      .valueChanges
      .pipe(
        debounceTime(this.timeDelay),
        tap(() => this.isLoading = true),
        switchMap(() => this.search())
      )
      .subscribe(partners => {
        this.filteredPartners = partners;
        this.isLoading = false;
      });
  }

  search(): Observable<Partner[]> {
    const searchTerm = this.searchForm.get('searchInput').value;
    if (searchTerm &&
      typeof(searchTerm) == 'string') {
      return this.apiService.getPartners(searchTerm);
    } else {
      return of([]);
    }
  }

  displayFn(partner: Partner): string {
    if (partner) {
      return partner.name;
    }
  }

  partnerSelected(event: MatAutocompleteSelectedEvent): void {
    this.partnerSelectionEvent.emit(event.option.value);
  }
}
