
export interface AuthUser {
  isAuthenticated: boolean;
  username: string;
  roles: string[];
}

export enum AuthRoles {
  Engineer = 'Engineer'
}

export function authUserInRole(user: AuthUser, role: string): boolean {
  return user?.roles?.filter((x: string) => x == role)?.length > 0;
}
