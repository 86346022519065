import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sk-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {
  @Input() routerLink: string[] = [];
  @Input() label: string;
  @Input() iconName: string;
  @Input() imgSrc: string;
  @Input() isExpanded: boolean;
  @Input() hideTooltip = false;
  @Output() customMouseEnter: EventEmitter<void> = new EventEmitter<void>();
  @Output() customClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {}

  canNavigate = false;
  ngOnInit(): void {
    if (this.routerLink.length > 0) {
      this.canNavigate = true;
    }
  }

  onClick(event: MouseEvent): void {
    // We were getting a weird issue with bubbling. This helps.
    event.preventDefault();
    event.stopPropagation();
    this.customClick.emit();
  }

  onMouseEnter(): void {
    this.customMouseEnter.emit();
  }

  onLinkClick(event: MouseEvent, link: string[]): void {
    // Ctrl (Windows/Linux) or Cmd (Mac)
    if (event.ctrlKey || event.metaKey) {
      // Prevent the default navigation
      event.preventDefault();
      event.stopPropagation();

      // Manual new tab navigation
      const url = this.router.serializeUrl(this.router.createUrlTree(link));
      window.open(url, '_blank');
    }
  }
}
