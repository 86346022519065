<div class="warning-container d-flex">
  <span>
    <mat-icon inline class="error">
      warning
    </mat-icon>
    IMPORTANT: Resource search capabilities will soon be moving to a new home under the <a routerLink="/orders/{{orderId}}/usersgroups/" routerLinkActive="active" class="clickable">Resources</a> tab.
  </span>
</div>

<div class="filter-container d-flex flex-row">
  <sk-subscriptions-filter class="d-flex flex-row flex-grow-1">
  </sk-subscriptions-filter>
  <mat-form-field class="export">
    <mat-select title="Export to File" placeholder="Export">
      <mat-option (click)="exporter.exportTable('xlsx', {fileName: exportFileName, sheet:'Subcriptions'})">Excel</mat-option>
      <mat-option (click)="exporter.exportTable('csv', {fileName: exportFileName, sheet:'Subcriptions'})">Csv</mat-option>
      <mat-option (click)="exporter.exportTable('json', {fileName: exportFileName, sheet:'Subcriptions'})">Json</mat-option>
      <mat-option (click)="exporter.exportTable('txt', {fileName: exportFileName, sheet:'Subcriptions'})">Txt</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="grid-container mat-elevation-z8">
  <sk-click-to-refresh
    class="refresh"
    [shouldDisplay]="subscriptionsData"
    [spin]="this.loading$ | async"
    (clickEvent)="updateSubscriptions(true)">
  </sk-click-to-refresh>

  <div class="table-container">
    <div [hidden]="!subscriptionsData">
      <mat-table [dataSource]="subscriptionsData"
        matSort matSortActive="friendlyName" matSortDirection="asc"
        matSortDisableClear matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]">

        <ng-container matColumnDef="id" >
					<mat-header-cell *matHeaderCellDef>
						<span>Id</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let subscription">
						<sk-click-to-copy
							hoverText="{{subscription.id}}"
							[copyText]="subscription.id">
						</sk-click-to-copy>
					</mat-cell>
				</ng-container>

        <ng-container matColumnDef="idExportText">
					<mat-header-cell *matHeaderCellDef >
						<span class="cdk-visually-hidden">Id</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let subscription">
            <span class="cdk-visually-hidden">{{subscription.id}}</span>
					</mat-cell>
				</ng-container>

        <ng-container matColumnDef="resourceType">
          <mat-header-cell *matHeaderCellDef>
            <span>Resource Type</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let subscription">
            <span>{{subscription.resourceKey.resourceType | startCase}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="friendlyName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Resource Name</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let subscription">
            <a routerLink="/orders/{{subscription.backupServiceId}}/subscriptions/{{subscription.id}}/{{subscription.resourceKey.resourceType}}/subscription-details" routerLinkActive="active" class="clickable" (click)="subscriptionClicked(subscription)">
              {{subscription.friendlyName | decodeUri}}
            </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="enabled">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Enabled</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let subscription">
            <mat-icon inline
              [class.success]="subscription.enabled && !subscription.disabledDueToAccess"
              [class.warning]="subscription.enabled && subscription.disabledDueToAccess"
              [class.muted]="!subscription.enabled"
              [title]="getEnabledIconTitle(subscription)">
              check_circle
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Created</span></mat-header-cell>
          <mat-cell *matCellDef="let subscription"><span>{{subscription.created | friendlyDate}}</span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="disabledDueToAccess">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Lost Access</span></mat-header-cell>
          <mat-cell *matCellDef="let subscription"><span>{{(subscription.disabledDueToAccess | friendlyDate) || "--"}}</span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="sequentialSnapshotErrors">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Failed Snaps</span></mat-header-cell>
          <mat-cell *matCellDef="let subscription"><span>{{subscription.sequentialSnapshotErrors}}</span></mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let subscription; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>

    <div *ngIf="this.loading$ | async" class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <mat-paginator [hidden]="!this.loading$ | async"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [length]="paginatorLength"
    showFirstLastButtons>
  </mat-paginator>
</div>
