import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';

import { BackupSubscription, SubscriptionsFilter, PaginatorState, GetPropertyName } from '@sk-models';
import { ApiService } from '../../api-service/api.service';

import { Guid } from 'guid-typescript';

@Injectable()
export class SubscriptionSearchService {
  public paginatorState: PaginatorState = new PaginatorState(true);
  public filters: SubscriptionsFilter = new SubscriptionsFilter();

  private subscriptionsSubject = new BehaviorSubject<BackupSubscription[]>(null);
  public subscriptions$ = this.subscriptionsSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  public updateFilter(orderId: string, event: SubscriptionsFilter): void {
    if (this.subscriptionsSubject.value) {
      this.filters = event;
      this.paginatorState.pageIndex = 0;
      this.updateSubscriptions(orderId);
    }
  }

  public updateSort(orderId: string, event: Sort): void {
    if (this.subscriptionsSubject.value) {
      this.paginatorState.sortByColumn = event.active;
      this.paginatorState.sortDirection = event.direction;
      this.paginatorState.pageIndex = 0;
      this.updateSubscriptions(orderId);
    }
  }

  public updatePage(orderId: string, event: PageEvent): void {
    if (this.subscriptionsSubject.value) {
      this.paginatorState.pageIndex = event.pageIndex;
      this.paginatorState.pageSize = event.pageSize;
      this.updateSubscriptions(orderId);
    }
  }

  public updateSubscriptions(orderId: string, reload?: boolean): void {
    this.apiService
      .findSubscriptions(this.getODataQuery(orderId), reload)
      .subscribe(x => {
        if (x) {
          this.paginatorState.length = x['@odata.count'];
          this.subscriptionsSubject.next(x.value);
        } else {
          this.subscriptionsSubject.next(null)
        }
      });
  }

  private getODataQuery(orderId: string) {
    const paginationQuery = this.getOdataPaginatorQuery();
    const filterQuery = this.getOdataFilterQuery(orderId);
    return `${paginationQuery}&${filterQuery}`;
  }

  private getOdataPaginatorQuery(): string {
    const queryFilters: string[] = [];

    queryFilters.push(`$count=true`);

    queryFilters.push(`$skip=${this.paginatorState.pageIndex * this.paginatorState.pageSize}`);

    queryFilters.push(`$top=${this.paginatorState.pageSize}`);

    if (this.paginatorState.sortByColumn && this.paginatorState.sortDirection) {
      queryFilters.push(`$orderBy=${this.paginatorState.sortByColumn} ${this.paginatorState.sortDirection}`);
    } else {
      queryFilters.push(`$orderBy=${GetPropertyName<BackupSubscription>('friendlyName')} asc`);
    }

    return queryFilters.join('&');
  }

  private getOdataFilterQuery(orderId: string): string {
    const queryFilters: string[] = [];

    queryFilters.push(`${GetPropertyName<BackupSubscription>('backupServiceId')} eq ${orderId}`)

    if (this.filters.enabled !== null && this.filters.enabled !== undefined) {
      queryFilters.push(`${GetPropertyName<BackupSubscription>('enabled')} eq ${this.filters.enabled}`)
    }

    if (this.filters.subscriptionType) {
      queryFilters.push(`${GetPropertyName<BackupSubscription>('subscriptionType')} eq '${this.filters.subscriptionType}'`)
    }

    if (this.filters.searchString) {
      const s = this.filters.searchString;
      if (Guid.isGuid(s)) {
        const idQuery =
          `(${GetPropertyName<BackupSubscription>('id')} eq ${s} or ` +
          `${GetPropertyName<BackupSubscription>('o365Id')} eq '${s}')`;
        queryFilters.push(idQuery);
      } else if (this.isListOfGuids(s)) {
        // build query with a list of ids
        const idsQuery = `${GetPropertyName<BackupSubscription>('id')} in (${s})`;
        queryFilters.push(idsQuery);
      } else {
        const searchStringQuery =
          `(contains(${GetPropertyName<BackupSubscription>('friendlyName')}, '${s}') or ` +
          `contains(${GetPropertyName<BackupSubscription>('fullName')}, '${s}') or ` +
          `contains(${GetPropertyName<BackupSubscription>('impersonationEmailAddress')}, '${s}'))`;
        queryFilters.push(searchStringQuery);
      }
    }

    return '$filter=' + queryFilters.join(' and ');
  }

  private isListOfGuids(input: string): boolean {
    return input.split(',').every(x => Guid.isGuid(x));
  }
}
