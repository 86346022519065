<div *ngIf="job && !isLoading" class="details-container">
    <div class="details-title has-back has-menu">
        <div>
          <mat-icon
            class="clickable back"
            title="Back"
            (click)="exitClicked()">
            keyboard_backspace
          </mat-icon>
        </div>
        <div>
          {{job.type | startCase}}
        </div>
        <div>
          <mat-icon
            class="clickable menu"
            title="Job Actions"
            [matMenuTriggerFor]="jobOptions">
            menu
          </mat-icon>
        </div>
    </div>

    <div class="details-badges">
        <mat-icon inline
            [class]="job.statusIconClass"
            title="{{job.statusIconTitle}}">
            {{job.statusIcon}}
        </mat-icon>
    </div>

    <div class="details-table">
        <div class="id">
            <span class="label">Job Id:</span>
            <span class="d-flex"> {{job.id}}
                <sk-click-to-copy
                    hoverText="Click to copy id"
                    [copyText]="job.id">
                </sk-click-to-copy>
            </span>
        </div>
        <div class="attempts">
            <span class="label">Resource Director Composite Id:</span>
            <span class="d-flex"> {{getResourceCompositeId(job.resourceKey)}}
                <sk-click-to-copy
                    hoverText="Click to copy id"
                    [copyText]="getResourceCompositeId(job.resourceKey)">
                </sk-click-to-copy>
            </span>
        </div>
        <div class="attempts">
            <span class="label">Attempts:</span>
            <span>{{job.attempts}}</span>
        </div>
        <div class="requester">
            <span class="label">Requested By:</span>
            <span>
                <sk-job-requested-by *ngIf="job != null && job != undefined"
                    [job]="job">
                </sk-job-requested-by>
            </span>
        </div>
        <div class="created">
            <span class="label">Created:</span>
            <span>{{(job.created | friendlyDate) || '--'}}</span>
        </div>
        <div class="heartbeat">
            <span class="label">Heartbeat:</span>
            <span>{{(job.lastHeartbeat | friendlyDate) || '--'}}</span>
        </div>
        <div class="started">
            <span class="label">Started:</span>
            <span>{{(job.started | friendlyDate) || '--'}}</span>
        </div>
        <div class="suspended">
            <span class="label">Suspended:</span>
            <span>{{(job.suspended | friendlyDate) || '--'}}</span>
        </div>
        <div class="resumed">
            <span class="label">Resumed:</span>
            <span>{{(job.resumed | friendlyDate) || '--'}}</span>
        </div>
        <div class="finished">
            <span class="label">Finished:</span>
            <span>{{(job.finished | friendlyDate) || '--'}}</span>
        </div>
        <div class="duration">
            <span class="label">Duration (Total):</span>
            <span>{{job.durationTotal}}</span>
        </div>
        <div class="duration">
            <span class="label">Duration (Last Iteration):</span>
            <span>{{job.durationLastIteration}}</span>
        </div>
        <div *ngIf="job.failed === true && job.errorStackTrace" class="exception-message">
            <span class="label">Exception:</span>
            <span>{{job.errorType}}</span>
        </div>
    </div>

    <div class="card-container">
        <button *ngIf="canShowExtendedRangeQueryButton()"
            type="button"
            [disabled]="isLoadingRestoreTargets"
            class="ext-query-btn"
            (click)="extendedRangeQuery()">
            Query Older Job Details
        </button>
    </div>

    <mat-menu #jobOptions="matMenu">
        <button mat-menu-item
            *ngIf="job.type.toLowerCase().includes('restore') && !isOldJob()"
            (click)="showRestoreDetails()">
            Open Restore Job Progress
        </button>
        <button mat-menu-item
            *ngIf="job.type.toLowerCase().endsWith('snapshot')"
            (click)="showSnapshotDetails()">
            Open Snapshot Job Progress
        </button>
        <button mat-menu-item
            *ngIf="job.type.toLowerCase().includes('restore')"
            [matMenuTriggerFor]="restoreResults">
            Restore Results Report
        </button>
        <button mat-menu-item
            *ngIf="!isOldJob()"
            (click)="openDataDogLogs()">
            <span class="d-flex">
              <span>Open in DataDog</span>
              <mat-icon class="right-icon">open_in_new</mat-icon>
            </span>
        </button>
        <button mat-menu-item
            *ngIf="hasActions()"
            [matMenuTriggerFor]="actions">
            Actions
        </button>
        <button mat-menu-item
            *ngIf="job.failed === true && job.errorStackTrace"
            (click)="showErrorStackTrace()">
            Show Exception Stack Trace
        </button>
        <button mat-menu-item
            *ngIf="job.rawJobSerialization"
            (click)="showJobSerialization()">
            Show Job Serialization
        </button>
        <button mat-menu-item
            *ngIf="job.rawJobSerialization && job.type.toLowerCase().includes('restore') && !isOldJob()"
            (click)="showRestoreTargets()">
            Show Restore Targets
        </button>
        <button mat-menu-item
            *ngIf="canShowShowEvents() && !isOldJob()"
            type="button"
            (click)="collapseExpandedSections(); rawEventsCollapsed = !rawEventsCollapsed;">
            Show Events
        </button>
        <button mat-menu-item
            *ngIf="job.type.toLowerCase().includes('restore') && job.errorType == 'AbandonedItemsException'"
            [matMenuTriggerFor]="skippedItems">
            Skipped Items Report
        </button>
    </mat-menu>
    <mat-menu #actions="matMenu">
        <button mat-menu-item
            *ngIf="hasForceFinishAction()"
            (click)="cancelJob()">
            Force Finish
        </button>
        <button mat-menu-item
            *ngIf="hasRequeueRestoreAction()"
            (click)="requeueRestoreJob(true)">
            Requeue Restore (NEW job)
        </button>
        <button mat-menu-item
            *ngIf="hasRequeueRestoreAction()"
            (click)="requeueRestoreJob(false)">
            Requeue Restore (same job)
        </button>
        <button mat-menu-item
            *ngIf="canReindexRestore()"
            (click)="reindexRestore()">
            Reindex Restore
        </button>
    </mat-menu>
    <mat-menu #restoreResults="matMenu">
        <button mat-menu-item
            *ngIf="job.type.toLowerCase().includes('restore')"
            (click)="showExportRestoreResultDialog()">
            Export
        </button>
        <button mat-menu-item
            *ngIf="canShowRestoredItems()"
            (click)="showRestoredItems()">
            Show
        </button>
    </mat-menu>
    <mat-menu #skippedItems="matMenu">
        <button mat-menu-item
            *ngIf="job.type.toLowerCase().includes('restore') && job.errorType == 'AbandonedItemsException'"
            (click)="showSkippedItemsReport();">
            Show
        </button>
        <button mat-menu-item
            *ngIf="job.type.toLowerCase().includes('restore') && job.errorType == 'AbandonedItemsException'"
            (click)="showExportSkippedItemsReportDialog();">
            Export
        </button>
    </mat-menu>

    <sk-resource-events
        *ngIf="!rawEventsCollapsed"
        class="card-container"
        [ngbCollapse]="rawEventsCollapsed"
        [jobId]="job.id">
    </sk-resource-events>
</div>

<div *ngIf="isLoading" class="loading-spinner">
    <mat-spinner></mat-spinner>
</div>
