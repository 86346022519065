<ng-template #template>
  <tr class="widget-row">
    <td class="widget-row-label">{{label}}:</td>
    <td class="widget-row-value">
      <ng-container *ngIf="dataType == 'date'">
        {{value | friendlyDate:"MM/DD/YY hh:mm A"}}
      </ng-container>
      <ng-container *ngIf="dataType == 'text'">
        {{value}}
      </ng-container>
      <ng-content></ng-content>
      <sk-click-to-copy class="break-word" hoverText="Click to copy" [copyText]="value" *ngIf="clickToCopy && value"></sk-click-to-copy>
    </td>
  </tr>
</ng-template>
