import { Component, ContentChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { WidgetDataRowComponent } from './widget-data-row/widget-data-row.component';

@Component({
  selector: 'sk-widget-data',
  templateUrl: './widget-data.component.html',
  styleUrls: ['./widget-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetDataComponent {
  @ContentChildren(WidgetDataRowComponent) rows: QueryList<WidgetDataRowComponent>;
}
