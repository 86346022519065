import { Injectable } from '@angular/core';

import * as moment from 'moment';

import {
  BackupJob,
  JobState,
  ResourceEventType,
  RestoreParameters,
  SnapshotParameters
} from '@sk-models';

@Injectable()
export class JobsService {
  computeJobProperties(job: BackupJob): BackupJob {
    if (!job) {
      return null;
    }

    if (job.lastEventType) {
      switch  (job.lastEventType) {
      case ResourceEventType.RestoreRequested:
      case ResourceEventType.RestoreRequestPublished:
      case ResourceEventType.SnapshotRequested:
      case ResourceEventType.SnapshotRequestPublished:
        job.statusIcon = 'schedule';
        job.statusIconTitle = 'Pending (not started)';
        break;
      case ResourceEventType.SnapshotDelayed:
        job.statusIcon = 'schedule';
        job.statusIconTitle = 'Delayed (job limit reached)';
        break;
      case ResourceEventType.RestoreSuspended:
      case ResourceEventType.SnapshotSuspended:
        job.statusIcon = 'restore';
        job.statusIconTitle = 'Scheduled Retry';
        break;
      case ResourceEventType.RestoreStarted:
      case ResourceEventType.RestoreHeartbeat:
      case ResourceEventType.SnapshotStarted:
      case ResourceEventType.SnapshotHeartbeat: {
        job.statusIcon = 'favorite';
        job.statusIconTitle = 'Running';
        const elapsed = moment.duration(moment().diff(job.lastHeartbeat)).asMinutes();
        job.statusIconClass = this.GetHeartbeatIconClass(job.type, elapsed);
        break;
      }
      case ResourceEventType.RestoreSucceeded:
      case ResourceEventType.SnapshotSucceeded:
        job.statusIcon = 'done';
        job.statusIconClass = 'success';
        job.statusIconTitle = 'Succeeded';
        break;
      case ResourceEventType.RestoreCancelled:
      case ResourceEventType.SnapshotCancelled:
        job.statusIcon = 'close';
        job.statusIconClass = 'warning';
        job.statusIconTitle = 'Cancelled';
        break;
      case ResourceEventType.RestoreFailed:
      case ResourceEventType.SnapshotFailed:
        job.statusIcon = 'close';
        job.statusIconClass = 'error';
        job.statusIconTitle = 'Failed';
        break;
      case ResourceEventType.RestoreStalled:
      case ResourceEventType.SnapshotStalled:
        job.statusIcon = 'close';
        job.statusIconClass = 'error';
        job.statusIconTitle = 'Stalled';
        break;
      case ResourceEventType.RestoreLost:
      case ResourceEventType.SnapshotLost:
        job.statusIcon = 'search_off';
        job.statusIconClass = 'error';
        job.statusIconTitle = 'Lost';
        break;
      case ResourceEventType.SnapshotSkipped:
        job.statusIcon = 'remove';
        job.statusIconClass = 'success';
        job.statusIconTitle = 'Skipped';
        break;
      case ResourceEventType.RestoreCompleteWithSkips:
        job.statusIcon = 'done';
        job.statusIconClass = 'warning';
        job.statusIconTitle = 'Succeeded with skipped items';
        break;
      default:
        job.statusIcon = 'help_outline';
        job.statusIconTitle = 'Latest Event Type Unknown. Ping SWAT for a fix.';
      }
    } else {
      // once we can deprecate job status (db based job status), this section can go bye bye
      switch  (this.getJobState(job)) {
      case JobState.Pending:
        job.statusIcon = 'hourglass_empty';
        job.statusIconTitle = 'Pending (not started)';
        break;
      case JobState.StartedRunning: {
        job.statusIcon = 'heartbeat';
        job.statusIconTitle = 'Running';
        const elapsed = moment.duration(moment().diff(job.lastHeartbeat)).asMinutes();
        job.statusIconClass = this.GetHeartbeatIconClass(job.type, elapsed);
        break;
      }
      case JobState.StartedSuspended:
        job.statusIcon = 'undo-alt';
        job.statusIconTitle = 'Scheduled for retry';
        break;
      case JobState.FinishedNotStarted:
        job.statusIcon = 'window-minimize';
        job.statusIconTitle = 'Finished (never started)';
        break;
      case JobState.FinishedSuccess:
        job.statusIcon = 'check';
        job.statusIconClass = 'success';
        job.statusIconTitle = 'Succeeded';
        break;
      case JobState.FinishedFailed:
        job.statusIcon = 'close';
        job.statusIconClass = 'error';
        job.statusIconTitle = 'Failed';
        break;
      case JobState.FinishedSuccessWithSkipped:
        job.statusIcon = 'check';
        job.statusIconClass = 'warning';
        job.statusIconTitle = 'Succeeded with skipped items';
        break;
      case JobState.Unknown:
      default:
        job.statusIcon = 'question';
        job.statusIconTitle = 'Unknown - we did not handle this case. Ping SWAT for a fix.';
      }
    }

    if (!job.started || !job.created) {
      job.durationTotal = '';
      job.durationLastIteration = '';
    } else if (job.suspended && !job.resumed) {
      const startTime = job.started;
      const endTime = moment();
      job.durationTotal = moment.duration(endTime.diff(startTime)).humanize();
      job.durationLastIteration = 'suspended';
    } else {
      const startTimeTotal = job.started;
      const endTime = !job.finished ? moment() : moment(job.finished);
      job.durationTotal = moment.duration(endTime.diff(startTimeTotal)).humanize();
      const startTimeLast = job.resumed || job.started;
      job.durationLastIteration = moment.duration(endTime.diff(startTimeLast)).humanize();
    }

    job.lastUpdated = job.finished ?? job.lastHeartbeat;

    if (job.errorStackTrace) {
      const typesAndMessages = /Type:\s+(.*)[\S\s]*?Message:\s+(.*)[\s\S]*Stack\s?Trace:/;
      const errorHeaders = job.errorStackTrace.match(typesAndMessages);
      if (errorHeaders && errorHeaders[1] && errorHeaders[2]) {
        job.errorStackTraceHeaders = {
          type: errorHeaders[1],
          message: errorHeaders[2]
        };
      }

      const atIn = /\s*at\s+(.*?)\s+in\s+(.*?)\s+\n/g;
      job.errorStackTraceContent = job.errorStackTrace
        .replace(typesAndMessages, '')
        .replace(atIn, 'at $1\n\t\tin $2\n')
        .trim();
    }

    if (job.rawJobSerialization) {
      job.rawJobSerializationFormatted = JSON.stringify(JSON.parse(job.rawJobSerialization), null, 4);
    }

    job.restoreParameters = <RestoreParameters>job.parameters;
    job.snapshotParameters = <SnapshotParameters>job.parameters;

    return job;
  }

  private getJobState(job: BackupJob): JobState {
    const stalledJobError = 'Stalled';
    const lostJobError = 'Lost';

    // Started and Finished
    if (job.started && job.finished) {
      // If marked as successful, it was
      if (!job.failed) {
        return JobState.FinishedSuccess;
      } else if (job.failed && job.errorStackTrace && job.errorType) {
        // Failed with error type and stack trace
        return JobState.FinishedFailed;
      } else if (job.failed && !job.errorStackTrace && !job.errorType) {
        // Marked as failed, but no error type or stack trace. Means successful with skipped items
        return JobState.FinishedSuccessWithSkipped;
      } else if (job.failed && job.errorType && !job.errorStackTrace) {
        // Marked as failed, with only an error type and no stack trace.
        // These fall into 2 buckets
        // Lost or Stalled jobs have an error type with no stack trace
        if (job.errorType === stalledJobError || job.errorType === lostJobError) {
          return JobState.FinishedFailed;
        } else if (job.attempts > 0) {
          // The job was successful on a retry, but we had skipped items
          return JobState.FinishedSuccessWithSkipped;
        }
      }
    }

    // Started and NOT Finished
    if (job.started && !job.finished) {
      // ...and Suspended
      if (job.suspended) {
        return JobState.StartedSuspended;
      // ...and NOT Suspended
      } else {
        return JobState.StartedRunning;
      }
    }

    // NOT Started
    if (!job.started) {
      if (job.finished) {
        // ...and Finished
        return JobState.FinishedNotStarted;
      } else {
        // ...and NOT Finished
        return JobState.Pending;
      }
    }

    // Some unknown state, add as needed
    return JobState.Unknown;
  }

  private GetHeartbeatIconClass(jobType: string, elapsed: number): string {
    // Err on the side of too much time if we don't know what the job type is set for.
    // This should avoid displaying alarming colored heartbeats prematurely.
    let heartbeatDuration = 45;

    // Snapshot heartbeats were increased to 30 minutes in order to take some load off of sql
    if (jobType.toLowerCase().includes('snapshot')) {
      heartbeatDuration = 30;
    } else if (jobType.toLowerCase().includes('restore')) {
      heartbeatDuration = 10;
    } else if (jobType.toLowerCase() === 'createcredentials') {
      heartbeatDuration = 20;
    }

    // pad with 1 min
    if (elapsed < heartbeatDuration + 1) {
      return 'success';
    } else if (elapsed < heartbeatDuration + 16) {
      // warning at 15 min padded by 1 min
      return 'warning';
    } else {
      return 'error';
    }
  }
}
