import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthRoles, AuthUser, authUserInRole, ReindexSubscriptionRequest } from '@sk-models';
import { AuthService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';
import { filter, finalize, take } from 'rxjs/operators';

@Component({
  selector: 'sk-reindex-modal',
  templateUrl: './reindex-modal.component.html',
  styleUrls: ['./reindex-modal.component.scss']
})
export class ReindexModalComponent {

  reindexQueuing = false;
  reindexSuccessful: boolean;
  includeExcludedItems = false;
  isHardReindex = false;
  isUserEngineer = false;
  includeDeletedItems = true;
  backupServiceId: string;
  subscriptionId: string;
  resourceType: string;
  advancedOptionsHide = true;
  private _authUser: AuthUser;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { backupServiceId: string, subscriptionId: string, resourceType: string },
    private apiService: ApiService, private authService: AuthService

  ) {
    if (data) {
      this.backupServiceId = data.backupServiceId;
      this.subscriptionId = data.subscriptionId;
      this.resourceType = data.resourceType;
      this.authService.authData$.pipe(filter(x => x != null), take(1)).subscribe((data) => this._authUser = data);
      this.isUserEngineer = authUserInRole(this._authUser, AuthRoles.Engineer);
    }
  }

  reindexSubscription(): void {
    this.reindexQueuing = true;
    const reindexSubscriptionRequest = this.buildReindexSubscriptionRequest();
    this.apiService.reindexSubscription(
      this.backupServiceId,
      this.subscriptionId,
      this.resourceType,
      reindexSubscriptionRequest)
      .pipe(finalize(() => this.reindexQueuing = false))
      .subscribe({
        next: () => {
          this.reindexSuccessful = true;
        },
        error: () => {
          this.reindexSuccessful = false;
        },
      });
  }

  buildReindexSubscriptionRequest(): ReindexSubscriptionRequest {
    const reindexSubscriptionRequest = new ReindexSubscriptionRequest();
    reindexSubscriptionRequest.includeExcludedItems = this.includeExcludedItems;
    reindexSubscriptionRequest.ingestDirectly = true;
    reindexSubscriptionRequest.includeDeletedItems = this.includeDeletedItems;
    reindexSubscriptionRequest.isHardReindex = this.isHardReindex;

    return reindexSubscriptionRequest;
  }

  excludedBoxChecked(checked: boolean): void {
    this.includeExcludedItems = checked;
  }

  isHardReindexChecked(checked: boolean): void {
    this.isHardReindex = checked;
  }

  deletedBoxChecked(checked: boolean): void {
    this.includeDeletedItems = checked;
  }

  toggleAdvancedOptions(): void {
    this.advancedOptionsHide = !this.advancedOptionsHide;
    if (this.advancedOptionsHide === true) {
      this.excludedBoxChecked(false);
    }
  }
}
