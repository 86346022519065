import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackupOrder, BackupSubscription, BackupJob } from '@sk-models';

@Component({
  selector: 'sk-search-overview',
  templateUrl: './search-overview.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./search-overview.component.scss']
})
export class SearchOverviewComponent implements OnInit {
  @HostBinding('class') class ='search-overview';

  selected = 'backup';
  isSubscriptionSearch: boolean;
  isJobSearch: boolean;
  defaultSubscriptionId: string = null;
  defaultJobId: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const queryParam = params.subscriptionId;
      if (queryParam != null) {
        this.selected = 'subscription';
        this.defaultSubscriptionId = queryParam;
        this.isSubscriptionSearch = true;
      }
      const queryParamJob = params.requestId;
      if (queryParamJob != null) {
        this.selected = 'jobs';
        this.defaultJobId = queryParamJob;
        this.isJobSearch = true;
      }
    });
  }

  resetSearch(): void {
    this.isSubscriptionSearch = this.selected === 'subscription';
    this.isJobSearch = this.selected === 'jobs';
  }

  onBackupSelect(data: BackupOrder): void {
    this.router.navigateByUrl(`orders/${data.id}`);
  }

  onSubscriptionSelect(data: BackupSubscription): void {
    const url = `orders/${data.backupServiceId}/subscriptions/${data.id}/${data.resourceKey.resourceType}/subscription-details`;
    this.router.navigateByUrl(url);
  }

  onJobSelect(data: BackupJob): void {
    const url = `/orders/${data.orderId}/subscriptions/${data.subscriptionId}/${data.resourceType}/jobs/${data.id}`;
    this.router.navigateByUrl(url);
  }

}
