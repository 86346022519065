import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import {
  BackupSubscription,
  BackupSubscriptionTypes,
  GetPropertyName,
  ResourceKey,
  SubscriptionsFilter
} from '@sk-models';
import { SubscriptionsFilterComponent } from '../subscriptions-filter/subscriptions-filter.component';
import { SubscriptionSearchService, SubscriptionService } from '@sk-services';
import { startCase } from 'lodash';

@Component({
  selector: 'sk-subscription-grid',
  templateUrl: './subscription-grid.component.html',
  styleUrls: ['./subscription-grid.component.scss'],
})
export class SubscriptionGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() orderId: string;
  @ViewChild(SubscriptionsFilterComponent) filter: SubscriptionsFilterComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    GetPropertyName<BackupSubscription>('id'),
    'idExportText',
    GetPropertyName<ResourceKey>('resourceType'),
    GetPropertyName<BackupSubscription>('friendlyName'),
    GetPropertyName<BackupSubscription>('enabled'),
    GetPropertyName<BackupSubscription>('created'),
    GetPropertyName<BackupSubscription>('disabledDueToAccess'),
    GetPropertyName<BackupSubscription>('sequentialSnapshotErrors')
  ];

  subscriptionsSubscription: Subscription;
  subscriptionsData: MatTableDataSource<BackupSubscription>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private subscriptionSearchService: SubscriptionSearchService,
    private changeDetector: ChangeDetectorRef
  ) { }
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    get pageSize(): number { return this.subscriptionSearchService.paginatorState?.pageSize; }
    get pageSizeOptions(): number[] { return this.subscriptionSearchService.paginatorState?.pageSizeOptions; }
    get pageIndex(): number { return this.subscriptionSearchService.paginatorState?.pageIndex; }
    get paginatorLength(): number { return this.subscriptionSearchService.paginatorState?.length; }
    get exportFileName(): string { return this.buildExportFileName();}

    ngOnInit(): void {
      this.loadingSubject.next(true);
      this.subscriptionsSubscription = this.subscriptionSearchService.subscriptions$
        .subscribe(subs => {
          if (subs) {
            this.subscriptionsData = new MatTableDataSource(subs);
            this.subscriptionsData.sort = this.sort;
          }
          this.loadingSubject.next(false);
        });
    }

    ngOnDestroy(): void {
      this.subscriptionsSubscription.unsubscribe();
    }

    initFilter(): void {
      if (this.route.snapshot?.queryParamMap?.get('subscriptionType')) {
        this.subscriptionSearchService.filters.subscriptionType = BackupSubscriptionTypes[this.route.snapshot.queryParamMap.get('subscriptionType')];
      }

      if (this.route.snapshot?.queryParamMap?.get('enabled')) {
        this.subscriptionSearchService.filters.enabled = this.route.snapshot.queryParamMap.get('enabled') === 'true';
      }

      if (this.route.snapshot?.queryParamMap?.get('searchString')) {
        this.subscriptionSearchService.filters.searchString = this.route.snapshot.queryParamMap.get('searchString');
      }

      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            searchString: this.subscriptionSearchService.filters.searchString,
            enabled: this.subscriptionSearchService.filters.enabled,
            subscriptionType: this.subscriptionSearchService.filters.subscriptionType
          },
          replaceUrl: true
        });

      this.filter.resetFilterControl(this.subscriptionSearchService.filters);
    }

    initSort(): void {
      if (this.subscriptionSearchService.paginatorState?.sortByColumn != '' &&
          this.subscriptionSearchService.paginatorState.sortDirection != '') {
        // This will sort correctly based on any existing pagination state, but the UI
        // won't update. This is an Angular bug that was recently moved to "in progress"
        // tracked here: https://github.com/angular/components/issues/19467.
        this.sort?.sort({
          id: this.subscriptionSearchService.paginatorState.sortByColumn,
          start: this.subscriptionSearchService.paginatorState.sortDirection == 'desc' ? 'desc' : 'asc',
          disableClear: false });
        // See bug for more info, but suffice it to say that you should trigger changes when
        // UI elements are being changed.
        this.changeDetector.detectChanges();
      }
    }

    ngAfterViewInit(): void {
      this.initFilter();
      this.initSort();

      this.sort.sortChange
        .subscribe((e: Sort) => {
          this.loadingSubject.next(true);
          this.subscriptionSearchService.updateSort(this.orderId, e)
        });

      this.filter.filterChange
        .subscribe((e: SubscriptionsFilter) => {
          this.loadingSubject.next(true);
          // update query param with the latest filters
          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: {
                searchString: e.searchString,
                enabled: e.enabled,
                subscriptionType: e.subscriptionType
              },
              replaceUrl: true
            });
          this.subscriptionSearchService.updateFilter(this.orderId, e)
        });

      this.paginator.page
        .subscribe((e: PageEvent) => {
          this.loadingSubject.next(true);
          this.subscriptionSearchService.updatePage(this.orderId, e)
        });

      if (!this.subscriptionsData?.data) {
        this.updateSubscriptions();
      }
    }

    updateSubscriptions(reload?: boolean): void {
      this.loadingSubject.next(true);
      this.subscriptionSearchService.updateSubscriptions(this.orderId, reload);
    }

    subscriptionClicked(subscription: BackupSubscription): void {
      this.subscriptionService.setSubscription(subscription);
    }

    private buildExportFileName(): string {
      return `Subscriptions_${this.orderId ?? ''}`;
    }

    getEnabledIconTitle(subscription: BackupSubscription): string {
      let titleText = startCase(subscription.resourceKey.resourceType);

      if (subscription.enabled && !subscription.disabledDueToAccess) {
        titleText += ' Enabled';
      } else if (subscription.enabled && subscription.disabledDueToAccess) {
        titleText += ' Lost Access';
      } else {
        titleText += ' Disabled';
      }

      return titleText;
    }
}
