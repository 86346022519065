import { Component, HostBinding, Input } from '@angular/core';
import { GenericModalOptions, MachineState } from '@sk-models';
import { MessageService } from '@sk-services';

@Component({
  selector: 'sk-machine-details',
  templateUrl: './machine-details.component.html',
  styleUrls: ['./machine-details.component.scss']
})
export class MachineDetailsComponent {
  @HostBinding('class') class ='d-flex flex-grow-1';

  @Input() machineState: MachineState;
  @Input() machineTitle: string;

  constructor(private messageService: MessageService) { }

  openDialog(machineState: MachineState): void {
    this.messageService.openModal(machineState.stateBag, this.machineTitle, GenericModalOptions.ShowJson);
  }

  friendlyMachineId(): string {
    const firstDot = this.machineState.machineId.indexOf('.');
    const secondDot = this.machineState.machineId.indexOf('.', firstDot + 1);
    if (secondDot !== -1) {
      return this.machineState.machineId.substring(0, secondDot + 1) + '..';
    }

    // If the second dot doesn't exist, return the entire string
    return this.machineState.machineId;
  }
}
