<mat-dialog-content>
  <div>
    <h3>Subscription Reindex</h3>
    <h6>This will reindex the subscription which can take some time. This option can be helpful if there are files
      available within the BSP that are not visible in the Partner Portal.</h6>
    <div class="reindexOptions">
      <mat-checkbox id="includeDeletedItems" checked="includeDeletedItems" (change)="deletedBoxChecked($event.checked)">
        Include deleted items
      </mat-checkbox>
    </div>
    <div class="advancedOptions">
      <a class="button advancedOptionsButton" (click)="toggleAdvancedOptions()">Advanced Options</a>
      <div [hidden]="advancedOptionsHide">
        <section>
          <mat-checkbox id="includeExcludedItems" [checked]="includeExcludedItems"
            (change)="excludedBoxChecked($event.checked)">
            Include excluded items
          </mat-checkbox>
          <p class="excludeItemsDescription">Selecting this option is uncommon. This will include items that are
            traditionally not visible to the SKP through the Partner Portal. For example, files created by a System User
            fall into this category.</p>
        </section>
        <section *ngIf="isUserEngineer">
          <mat-checkbox id="isHardReindex" [checked]="isHardReindex" (change)="isHardReindexChecked($event.checked)">
            Hard Re-index (read below before selecting)
          </mat-checkbox>
          <p class="isHardReindexDescription"><b>This option should only be selected as a last resort, having notified
              the partner beforehand, as the partner will not be able to see their data while the full index is being
              rebuilt.</b>It works by first deleting the index before starting the re-index and is used primarily where
            there are items visible in the Partner Portal which are no longer in the mirror state.</p>
        </section>
      </div>
    </div>
    <div>
      <button mat-raised-button (click)="reindexSubscription()"
        [disabled]="reindexQueuing || reindexSuccessful === false || reindexSuccessful === true">
        Reindex
      </button>
    </div>
  </div>
  <div class="reindexResult">
    <div *ngIf="reindexSuccessful === false">
      <p>Reindex failed, please try again later</p>
      <button mat-raised-button [mat-dialog-close]="true">
        Okay
      </button>
    </div>
    <div *ngIf="reindexSuccessful" class="">
      <p>Reindex successfully queued</p>
      <button mat-raised-button [mat-dialog-close]="true">
        Okay
      </button>
    </div>
  </div>
</mat-dialog-content>