<div class="page-container">
  <div class="page-content" *ngIf="userDetails != null && isLoading == false">
    <sk-page-header [title]="userDetails.name" icon="person" [breadcrumb]="[{
        'title': 'Resources',
        'link': '../../'
      }, {
        'title': 'User'
      }]">
      <div slot="header-content" class="header-content">
        <sk-users-groups-status-indicator
          [enabled]="userDetails.enabled"
          [overridden]="userDetails.overridden"
          [disabledDueToAccess]="userDetails.disabledDueToAccess"
          scope="User">
        </sk-users-groups-status-indicator>
        <mat-icon *ngIf="userDetails.isV2 === false"
          title="This is a V1 user. Related resources will NOT be synced with its protection status."
          class="warning">repeat_one</mat-icon>
      </div>
      <ng-container slot="header-actions">
        <button mat-icon-button [matMenuTriggerFor]="userOptions">
          <mat-icon class="clickable">menu</mat-icon>
        </button>
        <mat-menu #userOptions="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="userActions">Actions</button>
          <a mat-menu-item [routerLink]="['audit']">Audit</a>
        </mat-menu>
        <mat-menu #userActions="matMenu">
          <button mat-menu-item (click)="confirmUserPurge()">Purge User</button>
        </mat-menu>
      </ng-container>
    </sk-page-header>
    <div class="page-content" *ngIf="!isLoading">
      <div class="widget-container">
        <sk-widget title="Details" [collapsible]="true" [expanded]="true">
          <sk-widget-data>
            <sk-widget-data-row label="Name" [value]="userDetails.name" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="ID" [value]="userDetails.id" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="O365 ID" [value]="userDetails.nativeId" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="Created On" [value]="userDetails.created" dataType="date"></sk-widget-data-row>
            <sk-widget-data-row *ngIf="userDetails.disabledDueToAccess" label="Lost Access" [value]="userDetails.disabledDueToAccess" dataType="date"></sk-widget-data-row>
            <sk-widget-data-row label="EmailAddress" [value]="userDetails.emailAddress" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="UPN" [value]="userDetails.userPrincipalName" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="SMTP" [value]="userDetails.smtpAddress" clickToCopy="true"></sk-widget-data-row>
          </sk-widget-data>
        </sk-widget>
      </div>
      <div class="widget-container">
        <sk-widget title="Group Membership" [collapsible]="true" [noBorder]="true" (expandEvent)="refreshGroupMembership()">
          <sk-users-groups-search-filter (filterChange)="onGroupSearchFilterChanged($event)"></sk-users-groups-search-filter>
          <sk-groups-search-grid #groupMembershipSearch
            [backupServiceId]="backupServiceId"
            [userId]="userId">
          </sk-groups-search-grid>
        </sk-widget>
      </div>
      <div class="widget-container">
        <sk-users-groups-related-resources [backupServiceId]="backupServiceId" [resourceKeys]="availableResources"></sk-users-groups-related-resources>
      </div>
    </div>
    <div class="page-content" *ngIf="userDetails == null && isLoading == false">
      No data available
    </div>
  </div>
  <div class="page-content flex-container" *ngIf="isLoading == true">
    <div class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<sk-loading-overlay [loadingMessage]="userActionMessage" *ngIf="isUserActionRunning"></sk-loading-overlay>
