import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ResourceEvent } from '@sk-models';
import { MessageService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'sk-resource-events',
  templateUrl: './resource-events.component.html',
  styleUrls: ['./resource-events.component.scss']
})
export class ResourceEventsComponent implements OnInit {

  @Input() jobId: string;
  @Input() isLoading: boolean;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'id',
    'eventTime',
    'eventType',
    'properties'
  ];
  resourceEvents: ResourceEvent[];
  dataSource: MatTableDataSource<ResourceEvent>;

  constructor(private apiService: ApiService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.loadEvents(false);
  }

  loadEvents(reload: boolean): void {
    this.isLoading = true;
    this.apiService.fetchEventsForRequest(this.jobId, reload)
      .subscribe(results => {
        results.sort(this.eventCompareFn);
        this.resourceEvents = results;
        this.dataSource = new MatTableDataSource(this.resourceEvents);
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  eventCompareFn(left: ResourceEvent, right: ResourceEvent): number {
    return right.eventTime.valueOf() - left.eventTime.valueOf();
  }

  showModal(message: string, title: string): void {
    this.messageService.openCustomModal(GenericModalComponent, message, title);
  }
}
