import { Component, Input } from '@angular/core';
import { ApiService } from '../../api-service/api.service';
import { SnapshotFrequency } from '@sk-models';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'sk-snapshot-health',
  templateUrl: './snapshot-health.component.html',
  styleUrls: ['./snapshot-health.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SnapshotHealthComponent {
  snapshotHealth: SnapshotFrequency[];
  isLoading: boolean;
  fromDate = new FormControl(moment().add(-7, 'days'));
  toDate = new FormControl(moment());
  maxDate = moment();
  minDate = moment().add(-21, 'days');

  private _orderId: string;

  @Input()
  set orderId(id: string) {
    this._orderId = id;
    this.loadSnapshotRates();
  }

  constructor(
      private apiService: ApiService
  ) { }

  loadSnapshotRates(): void {
    this.isLoading = true;
    this.apiService.listSnapshotRates(this._orderId, this.fromDate.value, this.toDate.value)
      .subscribe(ratesMap => {
        this.snapshotHealth = ratesMap;
        this.isLoading = false;
      });
  }
}
