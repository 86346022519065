<div class="content-container">
    <div class="page-container">
      <div class="page-content" *ngIf="partnerDetails != null && isLoading == false">
        <sk-page-header [title]="partnerDetails.name" [breadcrumb]="[{
          'title': 'Partners',
          'link': '../'
        }]">
          <span slot="header-content" *ngIf="transitionStatus == 'full'">
            <sk-badge text="V2 Partner" colorClass="blue" title="This partner has been transitioned to V2">
            </sk-badge>
          </span>
          <span slot="header-content" *ngIf="transitionStatus == 'partial'">
            <sk-badge text="V2 Partial Transition" colorClass="red" title="This partner has been partially transitioned to V2">
            </sk-badge>
          </span>
          <span slot="header-content" *ngIf="transitionStatus == 'none'">
            <sk-badge text="V1 Partner" colorClass="teal" title="V1 Partner">
            </sk-badge>
          </span>
          <ng-container slot="header-actions">
            <mat-icon class="clickable menu" [matMenuTriggerFor]="subscriptionOptions">
              menu
            </mat-icon>
            <mat-menu #subscriptionOptions="matMenu">
              <button mat-menu-item [matMenuTriggerFor]="dataDog">DataDog</button>
              <button mat-menu-item [matMenuTriggerFor]="partnerActions" *ngIf="v2TransitionAllowed">Actions</button>
            </mat-menu>
            <mat-menu #dataDog="matMenu">
              <button
                mat-menu-item
                (click)="openDataDogLogs()">
                DataDog Logs
              </button>
              <button mat-menu-item
                (click)="openDataDogRum()">
                DataDog Rum
              </button>
            </mat-menu>
            <mat-menu #partnerActions="matMenu">
              <button mat-menu-item (click)="confirmTransitionModal()">Transition to V2</button>
            </mat-menu>
          </ng-container>
        </sk-page-header>
        <div class="page-content" *ngIf="!isLoading">
          <div class="widget-container">
            <sk-widget>
              <sk-widget-data>
                <sk-widget-data-row label="Name" [value]="partnerDetails.name" clickToCopy="true"></sk-widget-data-row>
                <sk-widget-data-row label="ID" [value]="partnerDetails.id" clickToCopy="true"></sk-widget-data-row>
                <sk-widget-data-row label="CRM">
                  <a href="{{partnerCrmUrl}}" target="_blank">CRM Order</a>
                </sk-widget-data-row>
                <sk-widget-data-row label="Email Address" [value]="partnerDetails.emailAddress" clickToCopy="true"></sk-widget-data-row>
                <sk-widget-data-row label="Test Partner">
                  <mat-icon *ngIf="partnerDetails.isTestPartner == true">check_circle</mat-icon>
                  <mat-icon *ngIf="partnerDetails.isTestPartner == false">cancel</mat-icon>
                </sk-widget-data-row>
              </sk-widget-data>
            </sk-widget>
          </div>
          <div>
            <sk-widget title="Orders">
              <div slot="header-actions">
                <button mat-raised-button id="exportCSVButton" (click)="exportCSV()"
                  [disabled]="!partnerOrdersPage || !partnerOrdersPage.partnerOrders || partnerOrdersPage.partnerOrders.length == 0">
                  Export CSV
                </button>
              </div>
              <sk-orders-grid [partnerOrdersPage]="partnerOrdersPage" [apiFilters]="apiFilters"
                [isRefreshing]="isOrdersLoading" (refreshEvent)="loadPartnerOrders(partnerId, true)">
              </sk-orders-grid>
            </sk-widget>
          </div>
        </div>
        <div class="page-content" *ngIf="partnerDetails == null && isLoading == false">
          No data available
        </div>
      </div>
      <div class="page-content flex-container" *ngIf="isLoading == true">
        <div class="loading-spinner">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
</div>
<sk-loading-overlay loadingMessage="Transitioning Partner to V2..." *ngIf="isTransitioningPartnerToV2"></sk-loading-overlay>

