<div class="drawer-table" *ngFor="let child of item.childItems">
  <div class="clickable item-type-header" (click)="clickedType(child.type)">
    <span class="item-type-name">{{child.type}}s </span>
    <div class="icon item-type-icon">
      <mat-icon
        class="expand-icon"
        title="Click to see more details."
        *ngIf="selectedType != child.type">
        keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="expand-icon"
        title="Click to close."
        *ngIf="selectedType == child.type">
        keyboard_arrow_up
      </mat-icon>
    </div>
  </div>

  <mat-table [dataSource]="child.details" *ngIf="openLists.includes(child.type)">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
      <mat-cell *matCellDef="let grandChild" (click)="onNavigateToItem(grandChild, child.type)"> {{grandChild[column] || grandChild.id}} </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable">
    </mat-row>
  </mat-table>
</div>

