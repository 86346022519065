export class BackupJobFilter {
  jobType: string;
  jobStatus: string;
  errorType: string;
  expanded: boolean;

  constructor() {
    this.jobType = 'All';
    this.jobStatus = 'All';
    this.expanded = false;
    this.errorType = 'All';
  }
}
