import { Component, Input } from '@angular/core';

export enum BannerType {
  info = 'info'
}

@Component({
  selector: 'sk-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() header: string;
  @Input() subtext: string;
  @Input() bannerType: BannerType = BannerType.info;  
}