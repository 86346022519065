
  <sk-users-groups-search-filter (filterChange)="onFilterChanged($event)">
    <div class="search-prefix-container" slot="first-grid-tile">
      <mat-icon class="search-prefix-icon" fontSet="material-icons-outlined">polyline</mat-icon>
      <div class="search-prefix-content">
        <div class="search-prefix-text">{{ prefixText }}</div>
        <mat-tab-group mat-stretch-tabs #searchTabGroup class="small-tab-group" (selectedTabChange)="onSubTabChange()">
          <mat-tab [label]="teamsLabel" #teams>
            <ng-template mat-tab-label><img [src]="TeamsIcon" alt="Teams" class="shake" /></ng-template>
          </mat-tab>
          <mat-tab [label]="groupsLabel" #groups>
            <ng-template mat-tab-label><img [src]="GroupsIcon" alt="Groups" class="shake" style="width: 35px;" /></ng-template>
          </mat-tab>
          <mat-tab [label]="sharePointLabel" #sharepoint>
            <ng-template mat-tab-label><img [src]="SharePointIcon" alt="SharePoint" class="shake" /></ng-template>
          </mat-tab>
          <mat-tab [label]="publicFoldersLabel" #publicfolders>
            <ng-template mat-tab-label><img [src]="PublicFoldersIcon" alt="PublicFolder" class="shake" /></ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </sk-users-groups-search-filter>

  <sk-teamsgroups-search-grid #teamsSearch
    [ngStyle]="{ display: !teams.isActive ? 'none' : null }"
    [backupServiceId]="backupServiceId"
    [isTeam]="true"
    [filterSearchTerm]="filter.term">
  </sk-teamsgroups-search-grid>

  <sk-teamsgroups-search-grid #groupsSearch
    [ngStyle]="{ display: !groups.isActive ? 'none' : null }"
    [backupServiceId]="backupServiceId"
    [isTeam]="false"
    [filterSearchTerm]="filter.term">
  </sk-teamsgroups-search-grid>

  <sk-collaboration-resources-search-grid #sharePointSearch
    [ngStyle]="{ display: !sharepoint.isActive ? 'none' : null }"
    [backupServiceId]="backupServiceId"
    [filterSubscriptionType]="'O365SharePoint'"
    [filterSearchTerm]="filter.term">
  </sk-collaboration-resources-search-grid>

  <sk-collaboration-resources-search-grid #publicFoldersSearch
    [ngStyle]="{ display: !publicfolders.isActive ? 'none' : null }"
    [backupServiceId]="backupServiceId"
    [filterSubscriptionType]="'O365Exchange'"
    [filterSearchTerm]="filter.term">
  </sk-collaboration-resources-search-grid>
