import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sk-tab-link',
  templateUrl: './tab-link.component.html',
  styleUrls: ['./tab-link.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TabLinkComponent {
  @Input() name: string;
  @Input() link: string;
  @Input() lightTheme: boolean;
}
