import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { SearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { GroupsSearchGridComponent } from './groups-search-grid/groups-search-grid.component';

@Component({
  selector: 'sk-users-groups-group-search',
  templateUrl: './users-groups-group-search.component.html',
  styleUrls: ['./users-groups-group-search.component.scss']
})
export class UsersGroupsGroupSearchComponent  extends SkyKickComponent implements OnInit, AfterViewInit {
  @Input() backupServiceId: string;

  filter: SearchFilter;

  @ViewChild('searchTabGroup') searchTabGroup: MatTabGroup;
  @ViewChild('groupsSearch') groupsSearch: GroupsSearchGridComponent;

  groupsLabel = 'groups';

  ngOnInit(): void {
    this.filter = { term: '' };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.refreshSelectedTab();
    });
  }

  onFilterChanged(filter: SearchFilter): void {
    this.filter = {
      ...this.filter,
      term: filter.term
    };
    this.refreshSelectedTab();
  }

  onSubTabChange(): void {
    this.refreshSelectedTab();
  }

  private refreshSelectedTab(): void {
    const selectedTab = this.searchTabGroup._tabs.toArray()[this.searchTabGroup.selectedIndex];
    if (selectedTab.textLabel === this.groupsLabel) {
      this.groupsSearch.refresh({ term: this.filter.term });
    }
  }
}
