<div class="page-content" *ngIf="hasData">
  <sk-widget>
    <div class="table-container">
      <div [hidden]="!dataSource">
        <mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear
          (matSortChange)="onSortChanged($event)" [hiddenColumns]="[0]">

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
              <span>Id</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <sk-click-to-copy hoverText="{{uag.id}}" [copyText]="uag.id">
              </sk-click-to-copy>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <a routerLink="/orders/{{backupServiceId}}/usersgroups/users/{{uag.id}}" routerLinkActive="active"
                class="clickable">
                {{uag.name}}
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="protected">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Protected</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <sk-users-groups-status-indicator
                [enabled]="uag.enabled"
                [overridden]="uag.overridden"
                [disabledDueToAccess]="uag.disabledDueToAccess"
                scope="User">
              </sk-users-groups-status-indicator>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef mat-sort-header><span>Created</span></mat-header-cell>
            <mat-cell *matCellDef="let uag"><span>{{uag.created | friendlyDate}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="disabledDueToAccess">
            <mat-header-cell *matHeaderCellDef mat-sort-header><span>Lost Access</span></mat-header-cell>
            <mat-cell *matCellDef="let ur"><span>{{ur.disabledDueToAccess | friendlyDate}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="mailboxType">
            <mat-header-cell *matHeaderCellDef mat-sort-header><span>Type</span></mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <span *ngIf="uag.providerInfo.exchange" [title]="uag.providerInfo.exchange.resourceKey.resourceType">
                {{ uag.providerInfo.exchange.resourceKey.resourceType[0] }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hasMailbox">
            <mat-header-cell *matHeaderCellDef>
              <img [src]="ExchangeIcon" alt="Exchange" />
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <ng-container *ngIf="uag.providerInfo.exchange; else missingResource">
                <sk-user-resource-status-indicator
                  [enabled]="uag.providerInfo.exchange.enabled"
                  type="Mailbox"
                  [disabledDueToAccess]="uag.providerInfo.exchange.disabledDueToAccess">
                </sk-user-resource-status-indicator>
              </ng-container>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="hasOneDrive">
            <mat-header-cell *matHeaderCellDef>
              <img [src]="OneDriveIcon" alt="OneDrive" />
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <ng-container *ngIf="uag.providerInfo.oneDrive; else missingResource">
                <sk-user-resource-status-indicator
                  [enabled]="uag.providerInfo.oneDrive.enabled"
                  type="OneDrive"
                  [disabledDueToAccess]="uag.providerInfo.oneDrive.disabledDueToAccess">
                </sk-user-resource-status-indicator>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hasTeamsChats">
            <mat-header-cell *matHeaderCellDef>
              <img [src]="TeamsChatsIcon" alt="TeamsChats" />
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <ng-container *ngIf="uag.providerInfo.teamsChats; else missingResource">
                <sk-user-resource-status-indicator
                  [enabled]="uag.providerInfo.teamsChats.enabled"
                  type="TeamsChats"
                  [disabledDueToAccess]="uag.providerInfo.teamsChats.disabledDueToAccess">
                </sk-user-resource-status-indicator>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-template #missingResource>
            <mat-icon
              class="muted"
              title="Resource is undiscovered, unlicensed, or does not exist.">
              radio_button_unchecked
            </mat-icon>
          </ng-template>

          <ng-container matColumnDef="isv2">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-cell"><span>V2</span></mat-header-cell>
            <mat-cell *matCellDef="let uag" class="flex-cell">
              <mat-icon
                [ngClass]="{'muted': !uag.isV2, 'success': uag.isV2}"
                [title]="uag.isV2 ? 'V2 User' : 'V1 User'">
                check_circle
              </mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let uag; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>

      <div class="loading-spinner" *ngIf="isLoading == true">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="filter.size" [pageIndex]="filter.page"
      [length]="totalResults" (page)="onPageChanged($event)" showFirstLastButtons>
    </mat-paginator>
  </sk-widget>
</div>
<div class="page-content flex-container" *ngIf="isLoading && hasData == false">
  <div class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
