import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import {
  BackupEntity,
  BackupEntityType,
  BackupJob,
} from '@sk-models';

import { ApiService } from '../../api-service/api.service';
import { EntityDownloadService } from '@sk-services';

export class RestoreTargetEntity extends BackupEntity {
  iconName: string;
  displayName: string;
  browseSubscriptionLink: string;
}

@Component({
  selector: 'sk-target-restore-items',
  templateUrl: './target-restore-items.component.html',
  styleUrls: ['./target-restore-items.component.scss']
})
export class TargetRestoreItemsComponent implements OnInit {
  @Input() job: BackupJob;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'type',
    'displayName',
    'browseSubscription'
  ];

  dataSource: MatTableDataSource<{ iconName: string; displayName: string; }>;
  restoreTargets: RestoreTargetEntity[];
  isLoading = false;

  constructor(private apiService: ApiService, private entityDownloadService: EntityDownloadService) { }

  ngOnInit(): void {
    // if it's the root there's no need to look it up
    if (this.job.parameters &&
      this.job.restoreParameters?.isRoot &&
      this.job.restoreParameters?.isRoot === true) {
      this.restoreTargets = [{
        iconName: 'globe',
        displayName: 'Full Restore',
        browseSubscriptionLink: this.getBrowseSubscriptionLink('00000000-0000-0000-0000-000000000000'),
        skyKickId: '',
        parentSkyKickId: '',
        friendlyUrl: '',
        type: -1,
        size: -1,
        name: "",
        relativeUrl: "",
        nativeId: ""
      }];
      this.dataSource = new MatTableDataSource(this.restoreTargets);
      this.dataSource.sort = this.sort;

      // need to go get all the target metadata
    } else {
      this.isLoading = true;

      this.apiService.getBackupEntities(
        this.job.orderId,
        this.job.subscriptionId,
        this.job.resourceType,
        this.job.restoreParameters?.restoreTargets)
        .subscribe({
          next: result => {
            if (result != null) {
              // alphabetize by the fields in this array starting with the first one
              const sortedRestoreTargets = _.sortBy(result, ['friendlyUrl', 'name']);
              this.restoreTargets = sortedRestoreTargets.map(x => ({
                iconName: BackupEntityType.iconName(x.type),
                displayName: this.getDisplayUrl(x),
                browseSubscriptionLink: this.getBrowseSubscriptionLink(x.skyKickId),
                skyKickId: x.skyKickId,
                parentSkyKickId: x.parentSkyKickId,
                friendlyUrl: x.friendlyUrl,
                relativeUrl: x.relativeUrl,
                type: x.type,
                size: x.size,
                name: x.name,
                nativeId: x.nativeId
              }),
              );
              this.dataSource = new MatTableDataSource(this.restoreTargets);
              this.dataSource.sort = this.sort;
            }
          },
          complete: () => this.isLoading = false
        });
    }
  }

  getBrowseSubscriptionLink(skyKickId: string): string {
    const baseUrl = `${window.location.href.substring(0, window.location.href.indexOf('/jobs/'))}/subscription-browser`;
    if (skyKickId === '00000000-0000-0000-0000-000000000000') {
      return baseUrl;
    }
    const jsonObject = JSON.parse(this.job.rawJobSerialization);
    const restoreTargets = jsonObject['NormalRestoreParameters']['RestoreTargets'];
    for (const i in restoreTargets) {
      if (restoreTargets[i]['SkyKickId'] === skyKickId) {
        return `${baseUrl}?parentSkId=${restoreTargets[i]['ParentSkyKickId']}&skId=${skyKickId}`;
      }
    }
  }

  getDisplayUrl(restoreTarget: BackupEntity): string {
    const directoryTypes = [
      BackupEntityType.Web,
      BackupEntityType.Folder,
      BackupEntityType.List,
      BackupEntityType.Mailbox];

    if (directoryTypes.includes(restoreTarget.type)) {
      return restoreTarget.friendlyUrl;
    } else {
      const fileName = restoreTarget.name || '__blank__';
      return `${restoreTarget.friendlyUrl}/${fileName}`;
    }
  }

  downloadData(): void {
    this.entityDownloadService.downloadPartialEntitiesAsCsv(this.restoreTargets, "RestoreTargets.csv");
  }
}
