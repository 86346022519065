<div *ngIf="subscription" class="details-container">
  <div class="details-title has-menu">
    <div class="d-flex">
      {{subscription.friendlyName}}
      <sk-click-to-copy
        hoverText="Click to copy name"
        [copyText]="subscription.friendlyName">
      </sk-click-to-copy>
    </div>

    <div>
      <mat-icon
        class="clickable menu"
        [matMenuTriggerFor]="subscriptionOptions">
        menu
      </mat-icon>
    </div>
  </div>

  <mat-menu #subscriptionOptions="matMenu">
    <ng-container *ngIf="subscription.relatedResourceId as resource">
      <ng-container *ngIf="getRelatedResource() as relatedResource">
        <button mat-menu-item (click)="navigateTo(relatedResource.url)">
          <span class="d-flex">
            <mat-icon style="color: inherit; margin-right: unset; font-size: 21px;">
              {{ relatedResource.icon }}
            </mat-icon>
            <span>{{ relatedResource.name }}</span>
            <mat-icon class="right-icon">open_in_new</mat-icon>
          </span>
        </button>
      </ng-container>
    </ng-container>
    <button mat-menu-item
      (click)="openDataDogLogs()">
      <span class="d-flex">
        <span>DataDog Logs</span>
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </span>
    </button>
    <button mat-menu-item
      (click)="openAlerts()">
      <span class="d-flex">
        <span>Open Alerts</span>
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="triageData">Triage Data</button>
    <button mat-menu-item [matMenuTriggerFor]="partnerPortal" *ngIf="isInProdOrLocal">Partner Portal</button>
    <button mat-menu-item (click)="getUserLicenseDetails()">
      Get User License Details
    </button>
    <button mat-menu-item
      (click)="displayElasticSearchModal()">
      Get Last Indexed Date
    </button>
    <button mat-menu-item *ngIf="userIsEngineer"
      (click)="displayBlobContainerSize()">
      Get Blob Container Size
    </button>
    <button mat-menu-item
      (click)="displayPurgeModal()">
      Purge Subscription
    </button>
  </mat-menu>

  <mat-menu #triageData="matMenu">
    <button mat-menu-item *ngIf="getGraphExplorerUri()"
      routerLink="/orders/{{subscription.backupServiceId}}/graph-explorer" routerLinkActive="true"
      [queryParams]="{uri:getGraphExplorerUri(), version: 'v1.0'}">
      Graph Explorer
    </button>
    <button mat-menu-item *ngIf="graphJourneyAvailable"
      routerLink="/orders/{{subscription.backupServiceId}}/subscriptions/{{subscription.id}}/{{subscription.resourceKey.resourceType}}/graph-journey">
      Graph Journey
    </button>
    <button mat-menu-item *ngIf="userIsEngineer"
      routerLink="/orders/{{subscription.backupServiceId}}/subscriptions/{{subscription.id}}/{{subscription.resourceKey.resourceType}}/subscription-search">
      Elastic Search
    </button>
  </mat-menu>

  <mat-menu #partnerPortal="matMenu">
    <a mat-menu-item
      href="https://crm.skykick.com/crmiframes/jumptopartnerportal.aspx?page=backup&backupServiceId={{subscription.backupServiceId}}&backupSubscriptionId={{subscription.id}}&backupSubscriptionType={{subscription.subscriptionType}}">
      <span class="d-flex">
        <span>Impersonation</span>
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </span>
    </a>
    <a mat-menu-item
      href="https://crm.skykick.com/crmiframes/jumptopartnerportal.aspx?page=backup&backupServiceId={{subscription.backupServiceId}}&backupSubscriptionId={{subscription.id}}&backupSubscriptionType={{subscription.subscriptionType}}&local=true">
      <span class="d-flex">
        <span>Impersonation (local)</span>
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </span>
    </a>
  </mat-menu>

  <div class="details-badges">
    <mat-icon inline
      [class.success]="subscription.enabled && !subscription.disabledDueToAccess"
      [class.warning]="subscription.enabled && subscription.disabledDueToAccess"
      [class.muted]="!subscription.enabled"
      [title]="getEnabledIconTitle()">
      check_circle
    </mat-icon>

    <mat-icon *ngIf="subscription.lockState > 0"
      inline
      [class.error]="true"
      title="Locked by engineering team">
      lock
    </mat-icon>
  </div>

  <div class="details-table">
    <div id="skId">
      <span class="label">Subscription Id:</span>
      <span class="d-flex">{{subscription.id}}
        <sk-click-to-copy
          class="clickable break-word"
          hoverText="Click to copy unique SkyKick Subscription Id"
          [copyText]="subscription.id">
        </sk-click-to-copy>
      </span>
    </div>
    <div id="o365Id">
      <span class="label">O365 Id:</span>
      <span class="d-flex">{{subscription.o365Id || '-'}}
        <sk-click-to-copy
          class="clickable break-word"
          hoverText="Click to copy unique O365 Id"
          [copyText]="subscription.o365Id || '-'">
        </sk-click-to-copy>
      </span>
    </div>
    <div id="type">
      <span class="label">Resource Type:</span>
      <span>
        {{subscription.resourceKey.resourceType | startCase}}
        <span *ngIf="subscription.subscriptionType != subscription.resourceKey.resourceType"> ({{subscription.subscriptionType | startCase}})</span>
      </span>
    </div>
    <div id="prepState">
      <span class="label">Preparation State:</span>
      <span>{{preparationState}}</span>
    </div>
    <div id="name">
      <span class="label">Resource Address:</span>
      <span class="d-flex">{{subscription.fullName}}
        <sk-click-to-copy
          class="clickable break-word"
          hoverText="Click to copy address"
          [copyText]="subscription.fullName">
        </sk-click-to-copy>
      </span>
    </div>
    <div *ngIf="subscription.impersonationEmailAddress" id="name">
      <span class="label">Impersonation Email Address:</span>
      <span class="d-flex">{{subscription.impersonationEmailAddress}}
        <sk-click-to-copy
          class="clickable break-word"
          hoverText="Click to copy address"
          [copyText]="subscription.impersonationEmailAddress">
        </sk-click-to-copy>
      </span>
    </div>
    <div id="created">
      <span class="label">Created On:</span>
      <span>{{(subscription.created | friendlyDate)}}</span>
    </div>
    <div id="initsnap">
      <span class="label">Initial Snapshot Completed On:</span>
      <span>{{(subscription.initialSnapshotCompletedOn | friendlyDate)}}</span>
    </div>
    <div *ngIf="subscription.disabledDueToAccess">
      <span class="label">Disabled Due To Access:</span>
      <span>{{(subscription.disabledDueToAccess | friendlyDate)}}</span>
    </div>
    <div *ngIf="subscription.disabledReason
      && subscription.disabledReason == 99999">
      <span class="label">Disabled Reason:</span>
      <span>{{subscription.disabledReason}}</span>
    </div>
    <div>
      <span class="label">Snapshot Frequency:</span>
      <a *ngIf="subscription.snapshotFrequency"
        class="clickable pop-out break-word"
        routerLink="/orders/{{subscription.backupServiceId}}/snapshot-frequencies">
        {{subscription.snapshotFrequency | startCase}} - (Queue: {{subscription.snapshotQueue}})
        <mat-icon>open_in_new</mat-icon>
      </a>
      <span *ngIf="!subscription.snapshotFrequency">--</span>
    </div>
    <div id="errors">
      <span class="label">Sequential Snapshot Errors:</span>
      <span>{{subscription.sequentialSnapshotErrors}}</span>
    </div>
  </div>

  <div class="machine-state-container">
    <div *ngIf="subscription.resourceKey.resourceType === 'TeamsChats'" class="d-flex info-container">
      <span>
        <mat-icon inline class="blue">info</mat-icon>
        All Teams Chats Users are represented by a single resouce, and are snapshot within a single job.
      </span>
    </div>

    <div class="d-flex flex-row">
      <sk-machine-details
        [machineTitle]="(subscription.resourceKey.resourceType|startCase) + ' Restores'"
        [machineState] = "subscription.restoreState">
      </sk-machine-details>
      <sk-machine-details
        [machineTitle]="(subscription.resourceKey.resourceType|startCase) + ' Snapshots'"
        [machineState] = "subscription.snapshotState">
        <sk-queue-job *ngIf="subscription.snapshotState?.machineId"
          class="queue-job-menu"
          [subscription]="subscription"
          [jobDefinitions]="[
            {type: 'FullSnapshot'}
            ]">
        </sk-queue-job>
      </sk-machine-details>
    </div>
    <div class="d-flex flex-row">
      <sk-machine-details
        [machineTitle]="(subscription.resourceKey.resourceType|startCase) + ' Purges'"
        [machineState] = "subscription.purgeState">
      </sk-machine-details>
      <sk-machine-details
          [machineTitle]="(subscription.resourceKey.resourceType|startCase) + ' Maintenance'"
          [machineState] = "subscription.maintenanceState">
      </sk-machine-details>
    </div>
  </div>

    <div *ngIf="relatedResources?.length > 0" class="related-resources">
      <span class="title">Related Resources</span>
      <div class="resource-items">
        <div class="resource" *ngFor="let resource of relatedResources">
          <a href="/orders/{{subscription.backupServiceId}}/subscriptions/{{subscription.id}}/{{resource}}/subscription-details"
            class="clickable" >
            {{resource}}
          </a>
        </div>
      </div>
    </div>

  <div *ngIf="subscription">
    {{events}}
  </div>
</div>
<div *ngIf="!subscription" class="loading-spinner">
  <mat-spinner></mat-spinner>
</div>
