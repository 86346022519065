import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sk-license-details-modal',
  templateUrl: './license-details-modal.component.html',
  styleUrls: ['./license-details-modal.component.scss']
})
export class LicenseDetailsModalComponent {
  orderId: string;
  o365Id: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { orderId: string, o365Id: string }) {
    if (data) {
      this.orderId = data.orderId;
      this.o365Id = data.o365Id;
    }
  }
}
