<div class="bsp-content-container bsp-content-container-audit">
  <div class="d-flex flex-grow-1 title-container">
    <span class="title">Audit Log</span>
  </div>

  <div class="grid-container mat-elevation-z8">
    <sk-click-to-refresh
      class="refresh"
      [shouldDisplay]="auditData"
      [spin]="this.loading$ | async"
      (clickEvent)="loadAuditPage(true)">
    </sk-click-to-refresh>

    <div class="table-container">
      <mat-table [hidden]="!auditData" [dataSource]="auditData" matSort>
        <ng-container matColumnDef="domainEntity_Id">
          <mat-header-cell *matHeaderCellDef class="id">
            <span>Id</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row"  class="id">
            <sk-click-to-copy
              hoverText="{{row.domainEntity_Id}}"
              [copyText]="row.domainEntity_Id">
            </sk-click-to-copy>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actor_UserIdentifier">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>User Identifier</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span [copyText]="row.actor_UserIdentifier">{{row.actor_UserIdentifier}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actor_Source">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Source</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.actor_Source}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subCategory">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>SubCategory</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.subCategory || '--'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eventInfo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Event Info</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.eventInfo || '--'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eventDetails">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Event Details</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span id="event-details-span">{{row.eventDetails || '--'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
          <mat-header-cell *matHeaderCellDef mat-sort-header id="dateCreatedHeaderCell">
            <span>Date Created</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" id="date-created-cell">
            <span>{{(row.dateCreated | friendlyDate) || '--'}}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>

      <div *ngIf="!auditData" class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>

    <mat-paginator [hidden]="!auditData"
      [pageSizeOptions]="this.pageSizeOptions"
      [pageSize]="this.pageSize"
      [length]="this.pageTotal"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
