import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { TeamsGroups, TeamsGroupsProviderInfo } from 'app/users-groups/models/teamsgroups.model';
import { CollaborationService } from 'app/users-groups/services/collaboration.service';
import { ResourceKey } from '@sk-models';

@Component({
  selector: 'sk-teamsgroups-detail',
  templateUrl: './teamsgroups-detail.component.html',
  styleUrls: ['./teamsgroups-detail.component.scss']
})
export class TeamsGroupsDetailComponent extends SkyKickComponent implements OnInit{
  public isLoading = true;
  public backupServiceId: string;
  public teamsgroupsId: string;
  public teamsgroupsDetails: TeamsGroups = null;
  public availableResources: ResourceKey[] = [];

  constructor(
    private route: ActivatedRoute,
    private collaborationService: CollaborationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.backupServiceId = this.route.parent.snapshot.paramMap.get('id');
    this.teamsgroupsId = this.route.snapshot.paramMap.get('teamsgroupsId');
    this.loadTeamsGroupsData(this.backupServiceId, this.teamsgroupsId);
  }

  loadTeamsGroupsData(backupServiceId: string, teamsgroupsId: string, reload = false): void {
    this.isLoading = true;
    this.subs.sink = this.collaborationService
      .getTeamsGroups(backupServiceId, teamsgroupsId, reload)
      .subscribe((details) => {
        this.teamsgroupsDetails = details.value[0];
        this.availableResources = this.buildAvailableResources(this.teamsgroupsDetails.providerInfo);
      },
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      });
  }

  buildAvailableResources(providerInfo: TeamsGroupsProviderInfo): ResourceKey[] {
    const resources: ResourceKey[] = [];
    if (providerInfo.team !== null) {
      resources.push(providerInfo.team);
    }
    if (providerInfo.teamChannelFiles !== null) {
      resources.push(providerInfo.teamChannelFiles);
    }
    if (providerInfo.teamChannelPosts !== null) {
      resources.push(providerInfo.teamChannelPosts);
    }

    if (providerInfo.files !== null) {
      resources.push(providerInfo.files);
    }
    if (providerInfo.planner !== null) {
      resources.push(providerInfo.planner);
    }

    if (providerInfo.conversations !== null) {
      resources.push(providerInfo.conversations);
    }

    return resources;
  }
}
