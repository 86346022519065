import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SkyKickApiService } from 'app/services/skykick-api/skykick-api.service';
import { UsersGroupsSearchFilter, UsersSearchResult, GroupsSearchResult } from '../models/users-groups-search.model';
import { User } from '../models/user.model';
import { Group } from '../models/group.model';
import { UserResource } from '../models/user-resource.model';
import { ODataResponse } from '@sk-models';
import { odataQuery } from 'odata-fluent-query';

@Injectable()
export class UsersGroupsService {
  constructor(private apiService: SkyKickApiService) { }

  public searchUsers(orderId: string, filter: UsersGroupsSearchFilter, reload = true): Observable<ODataResponse<UsersSearchResult>> {
    const path = `${environment.odata_url()}/users`;
    return this.searchUsersCommon(path, orderId, filter, reload);
  }

  public searchGroupUserMemberships(orderId: string, groupId: string, filter: UsersGroupsSearchFilter, reload = true): Observable<ODataResponse<UsersSearchResult>> {
    const path = `${environment.odata_url()}/users/${groupId}/group-user-memberships`;
    return this.searchUsersCommon(path, orderId, filter, reload);
  }

  private searchUsersCommon(requestUrl: string, orderId: string, filter: UsersGroupsSearchFilter, reload: boolean): Observable<ODataResponse<UsersSearchResult>> {
    let query = odataQuery<User>()
      .filter(x => x.backupServiceId.equals(orderId))
      .paginate(filter.size, filter.page);

    if (filter.term && filter.term.length > 0) {
      const encodedSearchText = encodeURIComponent(filter.term);
      query = query
        .filter(user => user.name.contains(encodedSearchText).or(
          user.userPrincipalName.contains(encodedSearchText)).or(
          user.smtpAddress.contains(encodedSearchText)).or(
          user.emailAddress.contains(encodedSearchText)).or(
          user.id.equals(this.isGuid(encodedSearchText) ? encodedSearchText : null)).or(
          user.nativeId.equals(this.isGuid(encodedSearchText) ? encodedSearchText : null)));
    }

    query = query.select(
      x => x.id,
      x => x.name,
      x => x.created,
      x => x.disabledDueToAccess,
      x => x.enabled,
      x => x.overridden,
      x => x.isV2,
      x => x.mailboxType,
      x => x.providerInfo);

    if (filter.sortDirection === 'desc' ||
        filter.sortDirection === 'asc') {
      if (filter.sort == "protected"){
        query = query
          .orderBy("enabled" as keyof User, filter.sortDirection)
          .orderBy("overridden" as keyof User, filter.sortDirection);
      } else {
        query = query.orderBy(filter.sort as keyof User, filter.sortDirection)
      }
    }

    return this.apiService.makeRequest('GET',
      `${requestUrl}?${query.toString()}`,
      null,
      null,
      reload);
  }

  public searchUsersResources(orderId: string, filter: UsersGroupsSearchFilter, reload = true): Observable<ODataResponse<UserResource>> {
    const path = `${environment.odata_url()}/userresources`;

    let query = odataQuery<UserResource>()
      .filter(x => x.backupServiceId.equals(orderId))
      .paginate(filter.size, filter.page);

    if (filter.term && filter.term.length > 0) {
      const encodedSearchText = encodeURIComponent(filter.term);

      if (this.isGuid(encodedSearchText)) {
        query = query
          .filter(userRes => userRes.name.contains(encodedSearchText).or(
            userRes.searchableMetadata.contains(encodedSearchText)).or(
            userRes.id.equals(encodedSearchText)).or(
            userRes.nativeId.equals(encodedSearchText)));
      } else {
        query = query
          .filter(userRes => userRes.name.contains(encodedSearchText).or(
            userRes.searchableMetadata.contains(encodedSearchText)));
      }
    }

    if (filter.subscriptionType) {
      query = query
        .filter(userRes => userRes.resourceKey.subscriptionType.equals(filter.subscriptionType));
    }

    if (filter.sortDirection === 'desc' ||
      filter.sortDirection === 'asc') {
      if (filter.sort == "protected"){
        query = query
          .orderBy("enabled" as keyof UserResource, filter.sortDirection);
      } else {
        query = query.orderBy(filter.sort as keyof UserResource, filter.sortDirection)
      }
    }

    return this.apiService.makeRequest('GET',
      `${path}?${query.toString()}`,
      null,
      null,
      reload);
  }

  public searchUserGroupMemberships(orderId: string, userId: string, filter: UsersGroupsSearchFilter, reload = true): Observable<ODataResponse<GroupsSearchResult>> {
    const path = `${environment.odata_url()}/groups/${userId}/user-group-memberships`;
    return this.searchGroupsCommon(path, orderId, filter, reload);
  }

  public searchGroups(orderId: string, filter: UsersGroupsSearchFilter, reload = true): Observable<ODataResponse<GroupsSearchResult>> {
    const path = `${environment.odata_url()}/groups`;
    return this.searchGroupsCommon(path, orderId, filter, reload);
  }

  public searchGroupGroupMemberships(orderId: string, groupId: string, filter: UsersGroupsSearchFilter, reload = true): Observable<ODataResponse<GroupsSearchResult>> {
    const path = `${environment.odata_url()}/groups/${groupId}/group-group-memberships`;
    return this.searchGroupsCommon(path, orderId, filter, reload);
  }

  private searchGroupsCommon(requestUrl: string, orderId: string, filter: UsersGroupsSearchFilter, reload: boolean): Observable<ODataResponse<GroupsSearchResult>> {
    let query = odataQuery<Group>()
      .filter(x => x.backupServiceId.equals(orderId))
      .paginate(filter.size, filter.page);

    if (filter.term && filter.term.length > 0) {
      const encodedSearchText = encodeURIComponent(filter.term);
      query = query
        .filter(group => group.name.contains(encodedSearchText).or(
          group.emailAddress.contains(encodedSearchText)).or(
          group.id.equals(this.isGuid(encodedSearchText) ? encodedSearchText : null)).or(
          group.nativeId.equals(this.isGuid(encodedSearchText) ? encodedSearchText : null)));
    }

    query = query.select(
      x => x.id,
      x => x.name,
      x => x.groupType,
      x => x.created,
      x => x.disabledDueToAccess,
      x => x.enabled,
      x => x.overridden,
      x => x.collaborationResourceInfo);

    if (filter.sortDirection === 'desc' ||
      filter.sortDirection === 'asc') {
      if (filter.sort == "protected"){
        query = query
          .orderBy("enabled" as keyof Group, filter.sortDirection)
          .orderBy("overridden" as keyof Group, filter.sortDirection);
      } else {
        query = query.orderBy(filter.sort as keyof Group, filter.sortDirection)
      }
    }

    return this.apiService.makeRequest('GET',
      `${requestUrl}?${query.toString()}`,
      null,
      null,
      reload);
  }

  public getGroup(orderId: string, groupId: string, reload = false): Observable<ODataResponse<Group>> {
    const path = `${environment.odata_url()}/groups`;

    const query = odataQuery<Group>()
      .filter(x =>
        x.backupServiceId.equals(orderId).and(
          x.id.equals(groupId))
      );

    return this.apiService.makeRequest('GET',
      `${path}?${query.toString()}`,
      null,
      null,
      reload);
  }

  public getUser(orderId: string, userId: string, reload = false): Observable<ODataResponse<User>> {
    const path = `${environment.odata_url()}/users`;

    const query = odataQuery<User>()
      .filter(x =>
        x.backupServiceId.equals(orderId).and(
          x.id.equals(userId))
      );

    return this.apiService.makeRequest('GET',
      `${path}?${query.toString()}`,
      null,
      null,
      reload);
  }

  public purgeUser(orderId: string, userId: string): Observable<boolean> {
    return this.apiService.makeRequest('DELETE',
      `${environment.api_url()}/orders/${orderId}/users/${userId}/purge`,
      null,
      'text',
      true,
      null,
      false);
  }

  private isGuid(str: string): boolean {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return regex.test(str);
  }
}
