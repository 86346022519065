import { Moment } from 'moment';

export class RestoredItem  {
  skyKickId: string;
  parentSkyKickId: string;
  finished: Moment;
  started: Moment;
  isSuccessfullyRestored: boolean;
  isAbandoned: boolean;
  isChanged: boolean;
  isSkipped: boolean;
  abandonedReason: string;
  numberOfAttempts: number;
  entityType: string;
  createdBy: string;
  friendlyUrl: string;
  relativeUrl: string;
  entityName: string;
  size: number;
  isExpanded: boolean;
}

export enum RestoredItemStatus {
  Successful,
  Skipped,
  Abandoned,
}
