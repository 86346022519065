import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'sk-users-groups',
  templateUrl: './users-groups.component.html',
  styleUrls: ['./users-groups.component.scss']
})
export class UsersGroupsComponent {
  @HostBinding('class') class ='d-flex flex-grow-1';
}
