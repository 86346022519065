import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  constructor( private matSnackBar: MatSnackBar) { }

  public success(message: string, durationSecs = 1) : void {
    this.matSnackBar.open(message, 'OK', {
      duration: durationSecs * 100000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    })
  }
}
