import { SortDirection } from '@angular/material/sort';

export class PaginatorState {
  private includeLength: boolean
  private defaultMaxPageSize: number;

  constructor(includeMaxPageSize: boolean) {
    this.includeLength = includeMaxPageSize;
    this.defaultMaxPageSize = 500;
  }

  get defaultPageSizeOptions(): number[] { return [12, 50, 100]; }
  get pageSizeOptions(): number[] {
    if (this.includeLength) {
      if (this.length <= this.defaultMaxPageSize) {
        return this.defaultPageSizeOptions
          .filter(x => x <= this.length)
          .concat(this.length);
      } else {
        return this.defaultPageSizeOptions.concat(this.defaultMaxPageSize);
      }
    } else {
      return this.defaultPageSizeOptions;
    }
  }

  pageIndex = 0;
  pageSize: number = this.defaultPageSizeOptions[0];
  length = 0;
  sortByColumn: string;
  sortDirection: SortDirection;
}
