import { Moment } from 'moment';

import {
  ResourceEventType,
  ResourceKey
} from '@sk-models';

export class BackupJob {
  id: string;
  type: string;
  orderId: string;
  orderName: string;
  resourceType: string;
  subscriptionId: string;
  lastHeartbeat: Moment;
  lastEventType: ResourceEventType;
  created: Moment;
  started: Moment;
  suspended: Moment;
  resumed: Moment;
  finished: Moment;
  attempts: number;
  failed: boolean;
  errorType: string;
  errorStackTrace: string;
  executingMachine: string;
  rawJobSerialization: string;
  resourceKey: ResourceKey;
  requester: Requester;
  parameters: IJobParameters;

  // Computed properties
  statusIcon: string;
  statusIconClass: string;
  statusIconTitle: string;
  durationTotal: string;
  durationLastIteration: string;
  errorStackTraceHeaders: { type: string, message: string };
  errorStackTraceContent: string;
  rawJobSerializationFormatted: string;
  lastUpdated: Moment;
  restoreParameters: RestoreParameters;
  snapshotParameters: SnapshotParameters;
}

export enum JobState {
  Pending,
  StartedRunning,
  StartedSuspended,
  FinishedNotStarted,
  FinishedSuccess,
  FinishedFailed,
  FinishedSuccessWithSkipped,
  Unknown
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IJobParameters { }

export class RestoreParameters implements IJobParameters {
  restoreTargets: string[];
  pointInTime: Moment;
  isRoot: boolean;
  sourceKey: ResourceKey;
  backupSubscriptionData: BackupSubscriptionData;
  backupOrderData: BackupOrderData;
}

export class BackupSubscriptionData {
  sourceName: string;
  destinationName: string;
}

export class BackupOrderData {
  orderName: string;
}

export class SnapshotParameters implements IJobParameters {
  isFullSnapshot: boolean;
}

export class Requester {
  userId: string;
  initiatedFrom: string;
}
