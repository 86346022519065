/**
 * Attempts to determine the environment
 * @param host host name to validate
 * @returns environment indicator - localhost, prod, dev1, qa1, etc...
 */
export function getEnvironment(host: string): string {
  let normalizedHost = host.toLocaleLowerCase();
  if (normalizedHost === 'localhost') {
    return 'localhost';
  } else if (normalizedHost.startsWith('backupsupportportal.')) {
    return 'prod';
  } else {
    // Non-prod environments look like 'sk-xxx-backupsupportportal.skykick.com'
    // See if we can pull off the environment    
    normalizedHost = normalizedHost.replace('sk-', '');
    const sep = normalizedHost.indexOf('-');
    if (sep >= 0) {
      return normalizedHost.substring(0, sep);
    } else {
      return 'unknown';
    }
  }
}

/**
 * returns a boolean indicating if the current environment is PROD
 * @param host host name to test against
 * @returns true if the current environment is PROD, otherwise false
 */
export function isProdEnvironment(host:string): boolean {
  return getEnvironment(host) === 'prod';
}

/**
 * determines if string value is intended for environment variable replacement
 * @param value value to test is eligible for replacement - in the form of ${xxxx}
 * @returns boolean indicating if the value is an environment variable or not.
 */
export function isEnvironmentReplacement(value: string): boolean {
  if (!value) {
    return false;
  } else {
    // Checks if the value is in the form of ${xxxx}
    // If so, the variable has NOT been replaced.
    return value.startsWith('${') && value.endsWith('}');
  }
}