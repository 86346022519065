<div class="details-container">
    <div class="details-title">
      <div>SkyKick Azure Application</div>
    </div>

    <div *ngFor="let accessType of this.accessTypes" class="summary-container">
      <sk-widget class="summary-widget" [title]="accessType" [collapsible]="true">
        <div class="d-flex" style="padding-right: 10px;" slot="title-prefix">
          <ng-container *ngIf="this.isLoading[accessType] === false; else spinner">
            <mat-icon class="md-34"
              [class.success]="this.accessStatuses[accessType]  && this.accessStatuses[accessType].hasGeneralAccess"
              [class.muted]="!this.accessStatuses[accessType]  || !this.accessStatuses[accessType].hasGeneralAccess"
              title="{{ this.accessStatuses[accessType] && this.accessStatuses[accessType].hasGeneralAccess
                          ? 'Enabled'
                          : accessType + ' is disabled for this order.' }}">
              power_settings_new
            </mat-icon>
          </ng-container>
        </div>
        <div *ngIf="this.isLoading[accessType] === false" class="d-flex align-items-center claims-summary" slot="header-actions">
          <mat-icon [ngClass]="this.statusIcon[accessType]">
            {{getAccessTypeStatusIcon(accessType)}}
          </mat-icon>
          <span class="total">
            {{successCount[accessType]}}/{{totalCount[accessType]}}
          </span>
        </div>

        <ng-container *ngIf="this.isLoading[accessType] === false; else spinner">
          <div *ngIf="this.accessStatuses[accessType] &&
                      this.accessStatuses[accessType].roleValidationStatuses">
            <div *ngFor="let roleStatus of this.accessStatuses[accessType].roleValidationStatuses" class="d-flex claims-row">
              <span class="align-content-center">{{roleStatus.roleName}}</span>
              <div>
                <mat-icon *ngIf="roleStatus != null && roleStatus.roleStatus" class="success">done
                </mat-icon>
                <mat-icon *ngIf="roleStatus != null && !roleStatus.roleStatus" class="error">close
                </mat-icon>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #spinner>
          <mat-spinner [diameter]="30" class="loading-spinner"></mat-spinner>
        </ng-template>
      </sk-widget>
    </div>
</div>
