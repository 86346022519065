import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filters = {};

  getFilter<T>(filterName: string): T {
    return this.filters[filterName];
  }

  setFilter<T>(filterName: string, filterObject: T): void {
    this.filters[filterName] = filterObject;
  }

  clearFilter(filterName: string): void {
    this.filters[filterName] = null;
  }
}
