import {
  Injector,
  ApplicationRef,
  ViewRef,
  ComponentRef,
  EmbeddedViewRef,
  ComponentFactoryResolver,
  ComponentFactory,
  Type,
  Injectable
} from "@angular/core";
import { assign } from "lodash";
import { SideDrawerComponent } from './side-drawer.component';

export class SideDrawerRequest {
  drawerContentComponent: Type<unknown>;
  data?: unknown;
  appendTo?: string;
  drawerWidth?: string;
}

@Injectable()
export class SideDrawerService {

  constructor(
    // Once we move to Angular 14, we should be able to use "createComponent" here
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) { }

  openDrawer({
    drawerContentComponent,
    data,
    appendTo,
    drawerWidth
  }: SideDrawerRequest): { containerComponent: ComponentRef<SideDrawerComponent>, contentComponent: ComponentRef<unknown> } {

    const containerSelector = appendTo || 'body';

    const drawerRef = this.buildDrawerContainerComponent({ open: true, drawerWidth: drawerWidth }, containerSelector);

    const contentRef = this.buildDrawerContent(drawerRef, drawerContentComponent, data);

    const closeDrawer = (drawerRef) => {
      drawerRef.hostView.destroy();

      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('overflow-hidden');
    }

    drawerRef.instance.onHidden = () => closeDrawer(drawerRef);
    drawerRef.instance.destroy = () => closeDrawer(drawerRef);

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('overflow-hidden');

    drawerRef.instance.show();

    return {
      containerComponent: drawerRef,
      contentComponent: contentRef
    };
  }

  createAndInsert<C>(componentFactory: ComponentFactory<C>, containerSelector: string, injector?: Injector, projectableNodes?: Node[][]): ComponentRef<C> {
    const componentRef = componentFactory.create(injector || this.injector, projectableNodes) as ComponentRef<C>;
    this.insert(componentRef.hostView, containerSelector);
    return componentRef;
  }

  insert(viewRef: ViewRef, containerSelector: string): ViewRef {
    this.appRef.attachView(viewRef);

    const containerElement = document.querySelector(containerSelector);

    containerElement?.appendChild((viewRef as EmbeddedViewRef<ViewRef>).rootNodes[0]);

    return viewRef;
  }

  buildDrawerContainerComponent(input: unknown, containerSelector: string): ComponentRef<SideDrawerComponent> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SideDrawerComponent);
    const drawerRef = this.createAndInsert(componentFactory, containerSelector, this.injector);
    assign(drawerRef.instance, input);
    return drawerRef;
  }

  buildDrawerContent(drawerRef: ComponentRef<SideDrawerComponent>, content: Type<unknown>, data: unknown): ComponentRef<unknown> {
    const drawerContentRef = drawerRef.instance.sideDrawerContentHost.viewContainerRef.createComponent(content, { index: 0, injector: this.injector });
    assign(drawerContentRef.instance, data);
    return drawerContentRef;
  }
}
