import { Component, Input, OnInit } from '@angular/core';

import { BackupEntityType, RetentionPurgeState, TreeEntity } from '@sk-models';
import { ApiService } from '../../../api-service/api.service';

@Component({
  selector: 'sk-tree-details',
  templateUrl: './tree-details.component.html',
  styleUrls: ['./tree-details.component.scss']
})
export class TreeDetailsComponent implements OnInit {

  @Input() title: string;
  @Input() treeRoot: TreeEntity;
  @Input() orderId: string;
  @Input() subscriptionId: string;
  @Input() subscriptionNativeId: string;
  @Input() resourceType: string;

  revisionsLoaded = false;
  revisionsLoading = false;
  graphExplorerUri: string;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.graphExplorerUri = this.getGraphExplorerUri();
  }

  getType(): string {
    return BackupEntityType[this.treeRoot.type];
  }

  getPurgeState(): string {
    return RetentionPurgeState[this.treeRoot.purgeState];
  }

  getGraphExplorerUri(): string {
    // so far only mailbox type is supported since nativeId for other types are not graphId
    if (this.resourceType === 'IndividualMailbox') {
      switch (this.treeRoot.type) {
      case BackupEntityType.Mailbox:
        return `users/${this.treeRoot.nativeId}`;
      case BackupEntityType.Folder:
        return `users/${this.subscriptionNativeId}/mailFolders/${this.treeRoot.nativeId.replace(/\//g, '-')}`;
      case BackupEntityType.Message:
        return `users/${this.subscriptionNativeId}/messages/${this.treeRoot.nativeId.replace(/\//g, '-')}`;
      default:
        return '';
      }
    }

    return '';
  }

  loadRevisions(): void {
    if (!this.treeRoot || this.revisionsLoaded) {
      return;
    }
    this.revisionsLoading = true;
    this.apiService.getRevisions(this.orderId, this.subscriptionId, this.resourceType,
      this.treeRoot.skyKickId, this.treeRoot.parentId)
      .subscribe(revisions => {
        if (revisions) {
          this.treeRoot.revisions = revisions;
          this.revisionsLoaded = true;
        }
        this.revisionsLoading = false;
      });
  }
}
