import { Component, Input, OnInit } from '@angular/core';
import { IBuildInfo } from 'environments/config/environment.model';
import { AppThemeService } from '../../services/app-theme/app-theme.service';

@Component({
  selector: 'sk-version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.scss']
})
export class VersionDialogComponent implements OnInit {
  darkTheme: boolean;

  constructor(private appThemeService: AppThemeService) {
  }

  @Input() ngBuildInfo: IBuildInfo = {
    buildDate: null,
    version: null
  };

  ngOnInit(): void {
    this.darkTheme = this.appThemeService.isDarkTheme();
  }

  switchTheme(): void {
    this.darkTheme = this.appThemeService.switchTheme();
  }
}
