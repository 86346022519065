<div class="job-progress-details d-flex flex-column">
  <div class="details-container">
    <div class="details-title has-back">
      <div>
        <mat-icon
          class="clickable back"
          title="Back"
          (click)="exitClicked()">
          keyboard_backspace
        </mat-icon>
      </div>
      <div>{{jobType}} Progress</div>
    </div>
  </div>

  <div *ngIf="metricsSorted">
    <div class="graph-container">
      <div class="main-graph">
        <ngx-charts-line-chart
          [view]="view"
          [results]="dataPoints"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [maxXAxisTickLength]="maxXAxisTickLength"
          [showXAxisLabel]="showXAxis"
          [showYAxisLabel]="showYAxis"
          [timeline]="showTimeline"
          [autoScale]="autoScale"
          [xAxisTickFormatting]="formatDateTime">
        </ngx-charts-line-chart>
      </div>

      <div class="sidebar">
        <mat-card class="legend">
          <form *ngIf="isInitialized" [formGroup]="graphControlForm" class="legend-form">
            <h5>Legend</h5>
            <mat-form-field>
              <mat-label>Entity Type</mat-label>
              <mat-select formControlName="entityType">
                <mat-option *ngFor="let entityType of allEntityTypes" [value]="entityType">
                  {{entityType}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Metric</mat-label>
              <mat-select formControlName="metric">
                <mat-option *ngFor="let metric of allMetricTypes" [value]="metric">
                  {{metric}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </mat-card>

        <div class="loading-buffer">
          <button mat-raised-button [disabled]="isLoading" (click)="refreshData()">{{ isLoading || isRefreshing ? "Refreshing..." : "Refresh Data" }}</button>
        </div>
      </div>
    </div>

    <h3 class="header">Totals Over Entire {{jobType}}</h3>
    <div class="summation-cards">
      <mat-card *ngFor="let summary of getSummationDetails()" class="sum-card">
        <h5>{{summary.entityType}}</h5>
        <div class="sum-metric" *ngFor="let metric of summary.metrics">
          <div class="metric-row">
            <span class="metric-label">{{metric.metricName}}</span>
            <span class="metric-value" *ngIf="!metric.metricName.toLowerCase().includes('size')">{{metric.sum}}</span>
            <span class="metric-value" *ngIf="metric.metricName.toLowerCase().includes('size')">{{metric.sum | fileSize}}</span>
          </div>
        </div>

      </mat-card>
    </div>
  </div>

  <mat-spinner class="loading-spinner" *ngIf="isLoading || isRefreshing"></mat-spinner>
</div>
