import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IGraphJourney } from "../../models/graph-journey.interface";

@Component({
  selector: 'sk-graph-journey-explorer',
  templateUrl: './graph-journey-explorer.component.html',
  styleUrls: ['./graph-journey-explorer.component.scss']
})
export class GraphJourneyExplorerComponent {

  @Input() displayName: string;
  @Input() journey: IGraphJourney = null;
  @Input() params: { [key: string]: unknown }
  @Input() orderId: string;

  constructor(private router: Router) {}

  goHome(event: Event): void {
    event.preventDefault();

    // We want to be able to reload the page here without a full refresh
    // so we need to do some trickery...
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.router.navigate([]);
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = prev;
      this.router.onSameUrlNavigation = prevOSN;
    }, 0);
  }
}
