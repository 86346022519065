import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { SearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { TeamsGroupsSearchGridComponent } from '../collaboration-search/teamsgroups-search-grid/teamsgroups-search-grid.component'
import { CollaborationResourcesSearchGridComponent } from './collaboration-resources-search-grid/collaboration-resources-search-grid.component';

@Component({
  selector: 'sk-collaboration-search',
  templateUrl: './collaboration-search.component.html',
  styleUrls: ['./collaboration-search.component.scss']
})
export class CollaborationSearchComponent extends SkyKickComponent implements OnInit, AfterViewInit {
  @Input() backupServiceId: string;

  filter: SearchFilter;

  public TeamsIcon = '../../../../../assets/images/icon-teams.svg';
  public GroupsIcon = '../../../../../assets/images/icon-team-and-group.svg';
  public SharePointIcon = '../../../../../assets/images/icon-sharepoint.svg';
  public PublicFoldersIcon = '../../../../../assets/images/icon-exchange.svg';

  @ViewChild('searchTabGroup') searchTabGroup: MatTabGroup;
  @ViewChild('teamsSearch') teamsSearch: TeamsGroupsSearchGridComponent;
  @ViewChild('groupsSearch') groupsSearch: TeamsGroupsSearchGridComponent;
  @ViewChild('sharePointSearch') sharePointSearch: CollaborationResourcesSearchGridComponent;
  @ViewChild('publicFoldersSearch') publicFoldersSearch: CollaborationResourcesSearchGridComponent;

  teamsLabel = 'collaborationTeams';
  groupsLabel = 'collaborationGroups';
  sharePointLabel = 'collaborationSharePoint';
  publicFoldersLabel = 'collaborationPublicFolders';

  private titleTextPrefix = "Team Search"
  prefixText = this.titleTextPrefix;

  ngOnInit(): void {
    this.filter = { term: '' };
  }

  ngAfterViewInit(): void {
    this.refreshSelectedTab();
  }

  onFilterChanged(filter: SearchFilter): void {
    this.filter = {
      ...this.filter,
      term: filter.term
    };
    this.refreshSelectedTab();
  }

  get selectedTab(): MatTab {
    return this.searchTabGroup._tabs.toArray()[this.searchTabGroup.selectedIndex];
  }

  onSubTabChange(): void {
    this.updateTitleText();
    this.refreshSelectedTab();
  }

  private updateTitleText(): void {
    const textLabel = this.selectedTab?.textLabel
    if (textLabel === this.teamsLabel) {
      this.prefixText = 'Team Search';
    } else if (textLabel === this.groupsLabel) {
      this.prefixText = 'M365 Group Search';
    } else if (textLabel === this.sharePointLabel) {
      this.prefixText = 'SharePoint Search';
    } else if (textLabel === this.publicFoldersLabel) {
      this.prefixText = 'Public Folder Search';
    }
  }

  private refreshSelectedTab(): void {
    const textLabel = this.selectedTab?.textLabel
    if (textLabel === this.teamsLabel) {
      this.teamsSearch.refresh({ term: this.filter.term });
    } else if (textLabel === this.groupsLabel) {
      this.groupsSearch.refresh({ term: this.filter.term });
    } else if (textLabel === this.sharePointLabel) {
      this.sharePointSearch.refresh({ term: this.filter.term });
    } else if (textLabel === this.publicFoldersLabel) {
      this.publicFoldersSearch.refresh({ term: this.filter.term });
    }
  }
}
