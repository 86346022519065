<div class="content-container d-flex flex-column">
    <div class="page-container">
      <sk-page-header title="Partner Search">
      </sk-page-header>
      <div class="page-content">
        <sk-partner-finder (partnerSelectionEvent)="partnerSelected($event)">
        </sk-partner-finder>
      </div>
    </div>
</div>
