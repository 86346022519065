import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/api-service/api.service';

@Component({
  selector: 'sk-graph-token-modal',
  templateUrl: './graph-token-modal.component.html',
  styleUrls: ['./graph-token-modal.component.scss']
})
export class GraphTokenModalComponent implements OnInit {
  private orderId: string;
  token: string;
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { orderId: string },
    private apiService: ApiService) {
    this.orderId = data.orderId;
  }

  ngOnInit(): void {
    this.apiService.getGraphToken(this.orderId)
      .subscribe({
        next: x => this.token = x,
        complete: () => this.isLoading = false
      });
  }
}
