<div class="page-container">
  <div class="page-content" *ngIf="teamsgroupsDetails != null && isLoading == false">
    <sk-page-header [title]="teamsgroupsDetails.displayName" icon="polyline" [breadcrumb]="[{
      'title': 'Resources',
      'link': '../../'
      }, {
        'title': teamsgroupsDetails.isTeam ? 'Team' : 'M365 Group'
      }]">
      <div slot="header-content" class="header-content">
        <sk-users-groups-status-indicator
          [enabled]="teamsgroupsDetails.enabled"
          scope="TeamsGroups">
        </sk-users-groups-status-indicator>
      </div>
    </sk-page-header>
    <div class="page-content" *ngIf="!isLoading">
      <div class="widget-container">
        <sk-widget title="Details" [collapsible]="true" [expanded]="true">
          <sk-widget-data>
            <sk-widget-data-row label="Name" [value]="teamsgroupsDetails.displayName" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="Alias" [value]="teamsgroupsDetails.alias" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="Group">
              <a [routerLink]="['/orders', backupServiceId, 'usersgroups', 'groups', teamsgroupsDetails.parentGroupId]"
                routerLinkActive="active" class="clickable">
                {{ teamsgroupsDetails.parentGroupId }}
                <mat-icon style="font-size: 18px">open_in_new</mat-icon>
              </a>
            </sk-widget-data-row>
            <sk-widget-data-row label="ID" [value]="teamsgroupsDetails.id" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="O365 ID" [value]="teamsgroupsDetails.nativeId" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="Created On" [value]="teamsgroupsDetails.created" dataType="date"></sk-widget-data-row>
            <sk-widget-data-row *ngIf="teamsgroupsDetails.disabledDueToAccess" label="Lost Access" [value]="teamsgroupsDetails.disabledDueToAccess" dataType="date"></sk-widget-data-row>
            <sk-widget-data-row label="Auto-Discovered" [value]="teamsgroupsDetails.autoDiscovered"></sk-widget-data-row>
            <sk-widget-data-row label="Preperation State" [value]="teamsgroupsDetails.preparationState ?? '-'"></sk-widget-data-row>
            <sk-widget-data-row label="Snapshot Paused Until" [value]="teamsgroupsDetails.snapshotPausedUntil ?? '-'"></sk-widget-data-row>
            <sk-widget-data-row label="Lock State" [value]="teamsgroupsDetails.lockState"></sk-widget-data-row>
            <sk-widget-data-row label="Purged" [value]="teamsgroupsDetails.isPurged"></sk-widget-data-row>
          </sk-widget-data>
        </sk-widget>
      </div>
      <div class="widget-container">
        <sk-users-groups-related-resources [backupServiceId]="backupServiceId" [resourceKeys]="availableResources"></sk-users-groups-related-resources>
      </div>
    </div>
    <div class="page-content" *ngIf="teamsgroupsDetails == null && isLoading == false">
      No data available
    </div>
  </div>
  <div class="page-content flex-container" *ngIf="isLoading == true">
    <div class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
