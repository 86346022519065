import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

import {
  of,
  Subscription,
} from 'rxjs';

import {
  BackupOrder,
  OrderState,
  Partner,
} from '@sk-models';

import { ApiService } from '../../api-service/api.service';
import { ActionDefinition } from './action-definition';
import {
  BulkOperationsComponent,
} from './bulk-operations/bulk-operations.component';

@Component({
  selector: 'sk-order-actions',
  templateUrl: './order-actions.component.html',
  styleUrls: ['./order-actions.component.scss']
})
export class OrderActionsComponent implements OnInit {
  @Input()
  order: BackupOrder;

  actions: ActionDefinition[];
  selectedAction: ActionDefinition;
  isActionInProgress: boolean; // Boolean to set for our loading spinner
  oldPartner: Partner;
  newPartner: Partner;
  newPartnerIsLoading: boolean;
  newPartnerErrorMessage: string;

  completeOrderHoverText: string =
    'Set CRM order status to Backup Order Placed' +
    '\n' +
    'Set the order placed date in the database' +
    '\n' +
    'If the partner had enabled Exchange, set snapshot frequency and turn it on' +
    '\n' +
    'If the partner had enabled SharePoint, set snapshot frequency and turn it on' +
    '\n' +
    'Show the order in the website' +
    '\n' +
    'Add audit log entry';

    revertDeactivationRequestHoverText: string =
    'If the Backup service is currently disabled and in a DeactivationRequestPending state: ' +
    '\n' +
    'Set it to a cancelled state' +
    '\n' +
    'Enable' +
    '\n' +
    'Add audit log entry';

  orderRestoresHoverText =
    'Restores for order';

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog
  ) {

  }


  ngOnInit(): void {
    this.actions = [
      {
        id: 'changePartner',
        name: 'Change Partner',
        hoverText: 'Change Partner',
        dangerLevel: 'high',
        selectedFn: this.loadPartner,
        canExecuteFn: this.canChangePartner.bind(this),
        executeFn: this.changePartner.bind(this)
      },
      {
        id: 'completeOrder',
        name: 'Complete Order',
        hoverText: this.completeOrderHoverText,
        dangerLevel: 'high',
        selectedFn: null,
        canExecuteFn: this.canCompleteOrder.bind(this),
        executeFn: this.completeOrder.bind(this)
      },
      {
        id: 'revertDeactivationRequest',
        name: 'Revert Deactivation Request',
        hoverText: this.revertDeactivationRequestHoverText,
        dangerLevel: 'high',
        selectedFn: null,
        canExecuteFn: this.canRevertDeactivationRequest.bind(this),
        executeFn: this.revertDeactivationRequest.bind(this)
      },
      {
        id: 'orderRestores',
        name: 'Order Restores',
        hoverText: this.orderRestoresHoverText,
        dangerLevel: 'low',
        selectedFn: this.sendToOrderRestores.bind(this),
        canExecuteFn: () => true,
        executeFn: null
      },
      {
        id: 'bulkOperations',
        name: 'Bulk Operations',
        hoverText: 'Choose and take actions on many subscriptions at once',
        dangerLevel: 'high',
        selectedFn: null,
        canExecuteFn: () => true,
        executeFn: this.showBulkOperationsDialog.bind(this)
      },
      {
        id: 'checkIp',
        name: 'Check IP Address',
        hoverText: 'Tool to check if IP Address is coming from Azure',
        dangerLevel: 'low',
        selectedFn: null,
        canExecuteFn: () => true,
        executeFn: this.goToIpChecker.bind(this)
      }
    ];
  }

  selectAction(act: ActionDefinition): void {
    if (act.selectedFn) {
      act.selectedFn.bind(this)();
    }
    this.selectedAction = act;
  }

  get canExecuteSelectedAction(): boolean {
    return this.selectedAction && this.selectedAction.canExecuteFn();
  }

  executeSelectedAction(): void {
    if (this.selectedAction) {
      this.isActionInProgress = true;
      this.selectedAction.executeFn()
        .add(() => {
          this.isActionInProgress = false;
        });
    }
  }

  loadPartner(): void {
    this.apiService.fetchPartner(this.order.managingPartnerId)
      .subscribe(p => this.oldPartner = p);
  }

  loadNewPartner(newPartnerId: string): void {
    const guidRegex = /[A-Fa-f0-9]{8}-?[A-Fa-f0-9]{4}-?[A-Fa-f0-9]{4}-?[A-Fa-f0-9]{4}-?[A-Fa-f0-9]{12}/;
    const newPartnerGuid = newPartnerId.match(guidRegex);
    if (!newPartnerGuid || !newPartnerGuid.length) {
      this.newPartnerErrorMessage = 'New partner id must be a guid!\nGet it using the \'id\' button\nfrom SKEX in CRM.';
      return;
    }

    //test
    if (this.newPartnerIsLoading == true) {
    }

    this.newPartnerIsLoading = true;
    this.newPartnerErrorMessage = null;
    this.apiService.fetchPartner(newPartnerId)
      .subscribe(p => {
        this.newPartner = p;
        this.newPartnerIsLoading = false;
      });
  }

  clearNewPartner(): void {
    this.newPartner = null;
  }

  canChangePartner(): boolean {
    return !!(this.oldPartner && this.newPartner);
  }

  changePartner(): Subscription {
    return this.apiService.replacePartner(this.order.id, this.newPartner.id)
      .subscribe(o => {
        this.order = o;
      });
  }

  canCompleteOrder(): boolean {
    // unplaced makes sense, but so does active since this would potentially "true up" CRM
    return !this.order.placedOn ||
    this.order.state == OrderState.Active;
  }

  completeOrder(): Subscription {
    return this.apiService.completeOrder(this.order.id)
      .subscribe(o => this.order = o);
  }

  canRevertDeactivationRequest(): boolean {
    return this.order.state === OrderState.DeactivationRequestPending;
  }

  revertDeactivationRequest(): void {
    this.apiService.revertDeactivationRequest(this.order.id)
      .subscribe();
  }

  sendToOrderRestores(): void {
    this.router.navigate(['restores'], { queryParams: { orderId: this.order.id } } );
  }

  showBulkOperationsDialog(): Subscription {
    return of(() => true).subscribe(() => {
      const dialogConfig = new MatDialogConfig<BackupOrder>();
      dialogConfig.data = this.order;
      dialogConfig.disableClose = true;

      const dialogRef = this.dialog.open<BulkOperationsComponent, BackupOrder>(BulkOperationsComponent, dialogConfig);
      dialogRef.afterClosed();
    });
  }

  goToIpChecker(): void {
    this.router.navigate(['ip-checker']);
  }
}
