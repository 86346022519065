import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IfIframeRemoveDirective } from './if-iframe-remove.directive';
import { IfIframeShowDirective } from './if-iframe-show.directive';

const directives = [
  IfIframeRemoveDirective,
  IfIframeShowDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [directives],
  exports: [directives]
})
export class SkSharedDirectivesModule { }
