<div *ngIf="treeRoot" class="truncate">
  <mat-icon
      [class.hidden]="!canBeExpanded()"
      class="clickable inlineIcon md-18"
      (click)="treeEntityClicked()">
      {{getExpandableIcon()}}
  </mat-icon>
  <mat-icon class="inlineIcon md-18" matTooltip="{{childrenTooltip}}">{{getIconNameByType()}}</mat-icon>
  <span class="clickable" (click)="togglePopover()">
    {{treeRoot.name ? treeRoot.name : '/'}}
  </span>
  <div class="item-error-message-container" *ngIf="treeRoot.errorMessage">
    <mat-icon class="inlineIcon md-18" [matTooltip]="treeRoot.errorMessage">error</mat-icon>
    <span>{{treeRoot.errorMessage}}</span>
  </div>
  <span *ngIf="childrenTooltip" title="Total children, hover over icon for breakdown">
    <span class="openFolderCounts">({{treeRoot.children.length}})</span>
  </span>
  <sk-tree-details
      *ngIf="showPopover"
      [treeRoot]="treeRoot"
      [title]="'Details'"
      [orderId]="orderId"
      [subscriptionId]="subscriptionId"
      [subscriptionNativeId]="subscriptionNativeId"
      [resourceType]="resourceType">
  </sk-tree-details>
  <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
  <div *ngIf="treeRoot.children" class="tree-viewer-children">
    <div class="tree-viewer-utilities">
      <sk-tree-viewer-search *ngIf="children.length > pageSize" (onSearch)="applySearch($event)" (onDownload)="downloadData()">
      </sk-tree-viewer-search>
      <ngb-pagination class="tree-view-pagination"
        size="sm"
          *ngIf="children.length > pageSize"
        [(page)]="page"
        [pageSize]="pageSize"
        [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
          [collectionSize]="children.length">
        </ngb-pagination>
    </div>
    <sk-tree-viewer *ngFor="let child of children | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
          [treeRoot]="child"
          [orderId]="orderId"
          [subscriptionId]="subscriptionId"
        [subscriptionNativeId]="subscriptionNativeId"
          [resourceType]="resourceType"
      (entitySelectedEvent)="rethrowEvent($event)">
      </sk-tree-viewer>
    <div class="tree-viewer-utilities">
      <ngb-pagination class="tree-view-pagination"
                      size="sm"
                      *ngIf="children.length > pageSize"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                      [collectionSize]="children.length">
      </ngb-pagination>
  </div>
  </div>
</div>

