<sk-users-groups-search-filter (filterChange)="onFilterChanged($event)">
  <div class="search-prefix-container" slot="first-grid-tile">
    <mat-icon class="search-prefix-icon">group</mat-icon>
    <div class="search-prefix-content">
      <div class="search-prefix-text">Group Search</div>
      <mat-tab-group mat-stretch-tabs #searchTabGroup class="small-tab-group" (selectedTabChange)="onSubTabChange()">
        <mat-tab [label]="groupsLabel" #groups>
          <ng-template mat-tab-label><mat-icon>group</mat-icon></ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</sk-users-groups-search-filter>

<sk-groups-search-grid #groupsSearch
  [ngStyle]="{ display: !groups.isActive ? 'none' : null }"
  [backupServiceId]="backupServiceId"
  [filterSearchTerm]="filter.term">
</sk-groups-search-grid>
