<div>
  <h1 mat-dialog-title>Order Bulk Reindex</h1>
  <div mat-dialog-content>
    <div>
      <div>Please select the target subscription and resource types:</div>
      <div class="form-fields">
        <mat-form-field class="bulkOperationFormField">
          <mat-label>Enabled/Disabled</mat-label>
          <mat-select [(value)]="selectedState" name="state" (selectionChange)="onFormChanged()" [disabled]="disableFields">
            <mat-option *ngFor="let option of enabledOptions" [value]="option.value">
              {{option.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="bulkOperationFormField">
          <mat-label>Subscription Type</mat-label>
          <mat-select [(value)]="selectedSubscriptionType" name="subscriptionOptions" (selectionChange)="onFormChanged()" [disabled]="disableFields">
            <mat-option *ngFor="let option of subscriptionOptions" [value]="option.value">
              {{option.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="bulkOperationFormField">
          <mat-label>Resource Type</mat-label>
          <mat-select [(value)]="selectedResourceType" name="resourceTypeOptions" (selectionChange)="onFormChanged()" [disabled]="disableFields">
            <mat-option *ngFor="let option of resourceTypeOptions" [value]="option.value">
              {{option.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        Total Subscriptions: <b>{{selectedCount}}</b>
      </div>
      <div class="reindex-options" *ngIf="selectedCount > maxSuggestedAmount">
        <i>For requests larger than {{maxSuggestedAmount}} subscriptions, consider breaking up by Subscription Type.</i>
      </div>
      <div class="reindex-options">
        <mat-checkbox id="includeDeletedItems" checked="includeDeletedItems" (change)="deletedBoxChecked($event.checked)" [disabled]="disableFields">
          Include deleted items
        </mat-checkbox>
      </div>
      <div class="advanced-options">
        <a class="button" (click)="toggleAdvancedOptions()">Advanced Options</a>
        <div [hidden]="advancedOptionsHide">
          <mat-checkbox id="includeExcludedItems" [checked]="includeExcludedItems"
            (change)="excludedBoxChecked($event.checked)" [disabled]="disableFields">
            Include excluded items
          </mat-checkbox>
          <p class="option-description">
            <i>
              Selecting this option is uncommon. This will include items that are
              traditionally not visible to the SKP through the Partner Portal. For example, files created by a System User
              fall into this category.
            </i>
          </p>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="reindexResult == null" class="action-container">
        <button mat-raised-button (click)="reindexSubscription()"
            [disabled]="reindexQueuing || reindexResult != null">
            Reindex
          </button>
          <button mat-raised-button [mat-dialog-close]="true" color="accent" [disabled]="reindexQueuing || reindexResult != null">
            Cancel
          </button>
          <span *ngIf="reindexQueuing">
            <mat-spinner [diameter]="18" style="display:inline-block"></mat-spinner> Queueing Reindexes...
          </span>
      </div>
      <div *ngIf="reindexResult != null" class="result-container">
        <button mat-raised-button [mat-dialog-close]="true">
          OK
        </button>
        <span *ngIf="reindexResult">Reindex successfully queued</span>
        <span *ngIf="reindexResult == false && reindexMessage == null" class="error">
          Reindex failed, please try again later
        </span>
        <span *ngIf="reindexResult == false && reindexMessage != null"  class="error">
          Bulk Reindex Failed - {{reindexMessage}}
        </span>
      </div>
    </div>
  </div>
</div>
