import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BackupJob } from '@sk-models';
import { ApiService } from '../../api-service/api.service';
import { RestoreHistoryDetails } from '@sk-models';

@Component({
  selector: 'sk-restore-history-details',
  templateUrl: './restore-history-details.component.html',
  styleUrls: ['./restore-history-details.component.scss']
})
export class RestoreHistoryDetailsComponent implements OnInit  {
  @Input() job: BackupJob;
  @Output() expandEvent = new EventEmitter<boolean>();
  restoreStatsSummaryPage: RestoreHistoryDetails;
  dataSource: RestoreHistoryDetails[];
  isGettingRequster: boolean;
  isGettingRestoreStatsSummary: boolean;

  displayedColumns: string[] = [
    'requester',
    'attempts',
    'created',
    'lastItemAttempted',
    'totalItemsAttempted',
    'totalItemsRestored',
    'totalBytesRestored',
    'pointInTime'
  ];

  constructor(private apiService: ApiService ) { }

  ngOnInit(): void {
    this.getAndCombineData();
  }

  getAndCombineData(): void {
    this.loadDataFromJob();
    this.getRestoreStatsSummaryForJob();
    this.getRequester();
    this.dataSource = [this.restoreStatsSummaryPage];
  }

  loadDataFromJob(): void {
    this.restoreStatsSummaryPage = new RestoreHistoryDetails();
    this.restoreStatsSummaryPage.attempts = this.job.attempts;
    this.restoreStatsSummaryPage.created = this.job.created;
  }

  getRestoreStatsSummaryForJob(): void {
    this.isGettingRestoreStatsSummary = true;
    this.apiService.getRestoreStatsSummaryForJob(this.job.id, false)
      .subscribe({
        next: result => {
          if (result) {
            this.restoreStatsSummaryPage.lastItemAttempted = result.lastItemAttempted;
            this.restoreStatsSummaryPage.totalBytesRestored = result.totalBytesRestored;
            this.restoreStatsSummaryPage.totalItemsAttempted = result.totalItemsAttempted;
            this.restoreStatsSummaryPage.totalItemsRestored = result.totalItemsRestored;
          }
        },
        complete: () => {
          this.isGettingRestoreStatsSummary = false;
        }
      });
  }

  getRequester(): void {
    this.isGettingRequster = true;
    this.apiService.fetchContactName(this.job.requester.userId, '$select=ContactName')
      .subscribe({
        next: result => {
          if (result) {
            this.restoreStatsSummaryPage.requester = result.contactName;
          }
        },
        complete: () => {
          this.isGettingRequster = false;
        }
      });
  }
}
