<div class="bsp-content-container">
  <div class="graph-query">
    <div class="d-flex query-options">
      <mat-icon inline
        class="blue common-query-info"
        matTooltip="Append a common query to the URL. If there are additional queries you'd like to add to this list, please contact SWAT.">
        info
      </mat-icon>
      <mat-form-field class="common-query">
        <mat-label>Common Queries</mat-label>
        <mat-select [(value)]="selectedOption" (selectionChange)="querySelected($event)">
          <mat-option value="Users">Users</mat-option>
          <mat-option value="Groups">Groups</mat-option>
          <mat-option value="SharePoint Sites">SharePoint Sites</mat-option>
          <mat-option value="SharePoint Site Root">SharePoint Site Root</mat-option>
          <mat-option value="Applications">Applications</mat-option>
          <mat-option value="Device Roles">Device Roles</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="api-version">
        <mat-select [(value)]="betaEndpoint" placeholder="API Version">
          <mat-option [value]="false">V1.0</mat-option>
          <mat-option [value]="true">Beta</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon class="query-menu clickable" [matMenuTriggerFor]="queryMenu">
        menu
      </mat-icon>
      <mat-menu #queryMenu="matMenu">
        <button mat-menu-item
          [matMenuTriggerFor]="microsoftDocumentation">
          Microsoft Documentation
        </button>
        <button mat-menu-item
          (click)="showToken()">
          Show Graph Token
        </button>
      </mat-menu>
      <mat-menu #microsoftDocumentation="matMenu">
        <button mat-menu-item
          (click)="openLink('https://docs.microsoft.com/en-us/graph')">
          <span class="d-flex">
            <span>Graph</span>
            <mat-icon class="right-icon">open_in_new</mat-icon>
          </span>
        </button>
        <button mat-menu-item
          (click)="openLink('https://docs.microsoft.com/en-us/graph/api/overview?view=graph-rest-1.0')">
          <span class="d-flex">
            <span>API Reference</span>
            <mat-icon class="right-icon">open_in_new</mat-icon>
          </span>
        </button>
        <button mat-menu-item
          (click)="openLink('https://docs.microsoft.com/en-us/graph/query-parameters?#odata-system-query-options')">
          <span class="d-flex">
            <span>Query Parameters</span>
            <mat-icon class="right-icon">open_in_new</mat-icon>
          </span>
        </button>
      </mat-menu>
    </div>

    <div class="query-input-row d-flex flex-row">
      <mat-form-field class="query-input-form-field">
        <mat-label>{{ 'https://graph.microsoft.com/' + (betaEndpoint ? 'beta/...' : 'v1.0/...') }}</mat-label>
        <textarea #queryInput matInput class="query-input" [(ngModel)]="customUri" (keydown.enter)="sendRequestClick($event)"></textarea>
      </mat-form-field>
      <button *ngIf="customUri" matSuffix mat-icon-button (click)="customUri = ''">
        <mat-icon>close</mat-icon>
      </button>

      <button mat-raised-button (click)="sendRequest()" [disabled]="isLoading" class="query-send" [ngClass]="{ enabled: !isLoading }">
        <span *ngIf="!isLoading">Send</span>
        <mat-spinner *ngIf="isLoading" class="loading-spinner" [diameter]="36"></mat-spinner>
      </button>
    </div>
  </div>

  <div class="graph-result d-flex flex-column">
    <div class="json-viewer-container">
      <ngx-json-viewer *ngIf="queryResultsCurrentPage" [json]="queryResultsCurrentPage.content"></ngx-json-viewer>
    </div>

    <div *ngIf="queryResultsCurrentPage">
      <div class="d-flex flex-row">
        <button mat-raised-button (click)="copyJson()">
          <span>Copy</span>
        </button>

        <mat-paginator #paginator
          [length]="canPageForwards() ? queryResultPages.length + 1 : queryResultPages.length"
          [pageSize]="1"
          [pageIndex]="queryResultsCurrentPage.pageNumber - 1"
          aria-label="Select page"
          (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
