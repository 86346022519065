import {
  Component,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  BackupOrder,
  Promo,
  QueueJobResult,
  MigrationOrderType,
  authUserInRole,
  AuthRoles,
  AuthUser
} from '@sk-models';
import { AuthService, BookmarksService, MessageService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sk-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnChanges {
  @HostBinding('class') class ='d-flex flex-grow-1';

  @Input()
  order: BackupOrder;
  @Input()
  promo: Promo;
  isBookmarked = false;
  selectedJobSubType: string;
  selectedJobType: string;
  atcUrl: string;

  private _authUser: AuthUser;

  constructor(
    private bookmarksService: BookmarksService,
    private apiService: ApiService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.authService.authData$.pipe(filter(x => x != null),take(1))
      .subscribe((data) => this._authUser = data);
  }

  ngOnChanges(): void {
    if (this.order) {
      this.isBookmarked = this.bookmarksService.isOrderBookmarked(this.order.id);

      if(this.order.migrationSalesOrderUrl != null) {
        const migrationOrderId = this.order.migrationSalesOrderUrl.replace("https://cloudvisors.crm.dynamics.com/main.aspx?etn=salesorder&id=%7b", "").replace("%7d&pagetype=entityrecord", "");
        this.apiService.fetchCrmOriginalOrder(migrationOrderId)
          .subscribe({
            next: results => {
              if (results && results.orderType == MigrationOrderType.MigrationsVNext) {
                this.atcUrl = `https://sk-prod-westus2-migsvc-rg-adminpanel.azurewebsites.net/crm/order/${results.name}`;
              }
            },
            complete: () => {
              return true;
            }
          });
      }
    }
  }

  getProductTypes(): string[] {
    const productTypes: string[] = [];
    if (this.order.products.users) {
      productTypes.push('users');
    }

    if (this.order.products.exchange) {
      productTypes.push('exchange');
    }

    if (this.order.products.sharePoint) {
      productTypes.push('sharePoint');
    }

    if (this.order.products.oneDrive) {
      productTypes.push('oneDrive');
    }

    if (this.order.products.groups) {
      productTypes.push('groups');
    }

    if(this.order.products.teamsChats) {
      productTypes.push('teamsChats');
    }

    return productTypes;
  }

  toggleBookmark(): void {
    if (this.isBookmarked) {
      this.bookmarksService.removeOrder(this.order.id);
      this.isBookmarked = false;
    } else {
      this.bookmarksService.addOrder({ orderId: this.order.id, orderName: this.order.name });
      this.isBookmarked = true;
    }
  }

  getBackupRetentionPolicy(snapshotRetentionDays: number): string {
    if (!snapshotRetentionDays || snapshotRetentionDays == 0) {
      return "Indefinite";
    }
    const retentionPolicyInYears = (snapshotRetentionDays / 365);
    return retentionPolicyInYears <= 1 ? `${retentionPolicyInYears.toString()} year` :  `${retentionPolicyInYears.toString()} years`;
  }

  openDataDogLogs(): void {
    const toTimestamp =  moment().valueOf();
    const fromTimestamp = moment().add(-7, 'days').valueOf();
    const url = `https://app.datadoghq.com/logs?query=%40Properties.ServiceId%3A${this.order.id}&from_ts=${fromTimestamp}&to_ts=${toTimestamp}&live=true`;
    window.open(url);
  }

  openJumpToPartnerPortal(): void {
    const orderId = this.order.salesOrderId;
    const backupV2 = this.order.orderType == null ? 'false' : 'true';
    const url = `https://crm.skykick.com/crmiframes/jumptopartnerportal.aspx?page=backup&orderId=${orderId}&backupv2=${backupV2}`;
    window.open(url);
  }

  openJumpToPartnerPortalLocal(): void {
    const orderId = this.order.salesOrderId;
    const backupV2 = this.order.orderType == null ? 'false' : 'true';
    const url = `https://crm.skykick.com/crmiframes/jumptopartnerportal.aspx?page=backup&orderId=${orderId}&backupv2=${backupV2}&local=true`;
    window.open(url);
  }

  openAlertsPortal(): void {
    const orderId = this.order.salesOrderId;
    const url = `https://alertswebportal.skykick.com/alerts/search/${orderId}/order`;
    window.open(url);
  }

  openManagementPortal(): void {
    const orderId = this.order.salesOrderId;
    const url = `https://manage.skykick.com/BackupOrder/DetailsForSkyKick/${orderId}`;
    window.open(url);
  }

  isDeveloperLinksVisible(): boolean {
    return authUserInRole(this._authUser, AuthRoles.Engineer);
  }

  openDataDogRum(): void {
    const toTimestamp =  moment().valueOf();
    const fromTimestamp = moment().add(-7, 'days').valueOf();
    const url = `https://app.datadoghq.com/rum/sessions?query=%40type%3Asession%20%40application.id%3A6bf24784-1afb-49e1-845d-c99b1981ae82%20%40context.backupServiceId%3A` +
                `${this.order.id}&cols=&from_ts=${fromTimestamp}&to_ts=${toTimestamp}&live=true`;
    window.open(url);
  }

  enqueueJob(jobType: string, jobSubType?: string): void {
    let currentJobType = jobType;
    if (this.activatedRoute.snapshot.paramMap.has('id')) {
      const backupServiceId = this.activatedRoute.snapshot.paramMap.get('id');
      // Discovery is an outlier, where there are different jobs per product
      // instead of different parameters on a generic job. Because of this, we
      // have to handle it a bit differently
      if (currentJobType === 'discovery') {
        currentJobType = jobSubType + currentJobType;
      }

      this.apiService.queueOrderJob(
        backupServiceId,
        currentJobType)
        .subscribe(result => this.onJobQueued(result, jobType, jobSubType));
    } else {
      this.messageService.openModal(`Unable to enqueue job. Please try again or contact SWAT.`);
    }
  }

  onJobQueued(queueJobResult: QueueJobResult, jobType: string, jobSubType?: string): void {
    let displayName = _.startCase(jobType);
    if (jobSubType) {
      displayName = _.startCase(jobSubType) + ' ' + displayName;
    }

    if (queueJobResult &&
        queueJobResult.success) {
      this.messageService.openModal(`${displayName} queued up!`);
    } else {
      this.messageService.openModal(`Failed to queue up ${displayName}`);
    }
  }

  navigateMenuItem(tag: string): void{
    switch (tag) {
    case 'snapshot-frequencies':
      if (this.activatedRoute.snapshot.paramMap.has('id')) {
        const backupServiceId = this.activatedRoute.snapshot.paramMap.get('id');
        this.router.navigate(['/orders', backupServiceId, 'snapshot-frequencies'])
      }
      break;
    case 'onedrive-discovery':
      if (this.activatedRoute.snapshot.paramMap.has('id')) {
        const backupServiceId = this.activatedRoute.snapshot.paramMap.get('id');
        this.router.navigate(['/orders', backupServiceId, 'onedrive-discovery'])
      }
      break;
    }
  }

  orderTypeMismatch(): boolean {
    return this.order.orderType != this.promo.orderType;
  }
}
