import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGraphJourney, IRowActionEvent } from 'app/graph-journey/models/graph-journey.interface';
import { TableColumnDefinition } from 'app/graph-journey/models/table-column-definition.model';

@Component({
  selector: 'sk-graph-data-table',
  templateUrl: './graph-data-table.component.html',
  styleUrls: ['./graph-data-table.component.scss']
})
export class GraphDataTableComponent {

  @Input() columnData: TableColumnDefinition[] = null;
  @Input() data: Array<unknown>;
  @Output() rowClick = new EventEmitter<unknown>();
  @Output() actionClick = new EventEmitter<IRowActionEvent>();

  onRowClick(data: unknown): void {

    // Allow the user to select text -
    if(window.getSelection()?.toString()?.length === 0) {
      this.rowClick.emit(data);
    }

  }

  onActionClick(event: Event, requestedJourney: IGraphJourney, data: unknown): void {
    event.stopPropagation();
    this.actionClick.emit({ requestedJourney, data });

  }

}
