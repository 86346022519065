// see sql table BackupServiceState
// see backup enum BackupOrderState
export enum OrderState {
  OrderPending = 'OrderPending', // 0
  Active = 'Active', // 1
  Inactive = 'Inactive', // 2
  DisabledDueToNonpayment = 'DisabledDueToNonpayment', // 3
  PurgeRequested = 'PurgeRequested', // 4
  Purging = 'Purging', // 5
  Purged = 'Purged', // 6
  Suspended = 'Suspended', // 7
  Cancelled = 'Cancelled', // 8
  Archived = 'Archived', // 9
  Deactivated = 'Deactivated', // 10
  DeactivationRequested = 'DeactivationRequested', //11
  Deactivating = 'Deactivating', //12
  DeactivationRequestPending = 'DeactivationRequestPending', //13
  StateNotDefined = 'StateNotDefined' // custom for BSP
}
