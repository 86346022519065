import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';

import {
  BackupJob,
  RestoreJobFilter
} from '@sk-models';
import { FilterService, MessageService } from '@sk-services';
import { GenericModalComponent } from 'app/shared/generic-modal/generic-modal.component';

@Component({
  selector: 'sk-restore-history',
  templateUrl: './restore-history.component.html',
  styleUrls: ['./restore-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class RestoreHistoryComponent implements OnInit, OnChanges {
restoreJobs: BackupJob[];
  @Input() days: number[] = [];
  @Input() jobs: BackupJob[];
  @Input() dateRange: string = null;

  @Input() isRefreshing: boolean;
  @Input() displayedColumnsOverride: string[];
  @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dateEvent: EventEmitter<{start:string, end:string}> = new EventEmitter<{start:string, end:string}>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumnsDefault: string[] = [
    'started',
    'lastUpdated',
    'sourceName',
    'destinationName',
    'isRoot',
    'resourceType',
    'statusIconTitle',
    'durationTotal',
    'errorMessage',
    'viewRestore'
  ];

  dataSource: MatTableDataSource<BackupJob>;
  expandedRow: BackupJob;
  // filtering variables
  resourceTypes: string[];
  jobStatuses: string[];
  filterForm: FormGroup;
  debounceTime = 250;

  constructor(
    private messageService: MessageService,
    private filterService: FilterService) { }

  get selectedResourceType(): string { return this.filterForm.get('resourceType').value; }
  get selectedJobStatus(): string { return this.filterForm.get('jobStatus').value; }
  get selectedSearchQuery(): string { return this.filterForm.get('searchInput').value; }
  get displayedColumns(): string[] {
    if (this.displayedColumnsOverride) {
      return this.displayedColumnsOverride;
    } else {
      return this.displayedColumnsDefault;
    }
  }

  ngOnInit(): void {
    this.initFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jobs &&
        changes.jobs.currentValue) {
      // refresh filter drop lists with whatever the currentValue is
      this.resourceTypes = Array.from(new Set(this.jobs.map((item: BackupJob) => item.resourceType)));
      this.jobStatuses = Array.from(new Set(this.jobs.map((item: BackupJob) => item.statusIconTitle)));

      // some previousValue indicates that currentValue has changed - refresh grid source
      if (changes.jobs.previousValue) {
        this.dataSource.data = this.jobs;

      // no previousValue means it has just been initialized
      } else {
        this.dataSource = new MatTableDataSource(this.jobs);
        this.sort?.sort({
          id: 'created',
          start: 'desc',
          disableClear: false
        });
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (job, property) => this.getProperty(job, property);
        this.dataSource.filterPredicate = this.filterData;
      }
    }
  }

  initFilter(): void {
    let existingFilter = this.filterService.getFilter('restoreHistoryFilter') as RestoreJobFilter;
    if (!existingFilter) {
      existingFilter = new RestoreJobFilter();
    }

    this.filterForm = new FormGroup({
      resourceType: new FormControl(existingFilter.resourceType),
      jobStatus: new FormControl(existingFilter.jobStatus),
      searchInput: new FormControl()
    });

    this.filterForm.valueChanges
      .subscribe(() => this.updateCurrentFilter());
  }

  updateCurrentFilter(): void {
    const filter = new RestoreJobFilter();
    filter.jobStatus = this.selectedJobStatus;
    filter.resourceType = this.selectedResourceType;
    filter.searchString = this.selectedSearchQuery;

    this.filterService.setFilter('restoreHistoryFilter', filter);
    this.dataSource.filter = `${this.selectedResourceType},${this.selectedJobStatus},${this.selectedSearchQuery}`;
  }

  resetCurrentFilter(): void {
    this.filterService.clearFilter('restoreHistoryFilter');
    this.initFilter();
    this.updateCurrentFilter();
  }

  getProperty(job: BackupJob, columnName: string): string | number {
    const propertyName = columnName.replace(/\s/g, '');
    return job[propertyName];
  }

  filterData(job: BackupJob, filterString: string): boolean {
    // to figure out what's in this delimited list, check
    // wherever the MatTableDataSource.filter is set.
    const filters = filterString.split(',');

    // check type
    const selectedType = filters[0];
    if (selectedType !== 'All' && selectedType !== job.resourceType) {
      return false;
    }

    // check status
    const selectedStatus = filters[1];
    if (selectedStatus !== 'All' && selectedStatus !== job.statusIconTitle) {
      return false;
    }

    // check searchQuery
    const selectedSearchQuery = filters[2];
    if (selectedSearchQuery !== 'null'
        && job.restoreParameters?.backupSubscriptionData?.sourceName?.indexOf(selectedSearchQuery) === -1
        && job.restoreParameters?.backupSubscriptionData?.destinationName?.indexOf(selectedSearchQuery) === -1) {
      return false;
    }

    return true;
  }

  clickedChevron(row: BackupJob): void {
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  selectRestore(restoreJob: BackupJob): string {
    /*check date of order, if older than 30, call other endpoint with date range*/
    if(moment().diff(restoreJob.started, 'days') > 30){
      const startDate = moment(restoreJob.started).format('YYYY-MM-DD');
      return `/orders/${restoreJob.orderId}/subscriptions/${restoreJob.subscriptionId}/${restoreJob.resourceType}/jobs/${restoreJob.id}?startDate=${startDate}`;
    } else {
      return `/orders/${restoreJob.orderId}/subscriptions/${restoreJob.subscriptionId}/${restoreJob.resourceType}/jobs/${restoreJob.id}`;
    }
  }

  refreshJobs(): void {
    this.isRefreshing = true;
    this.refreshEvent.emit();
  }

  refreshDatesJobs(startDate: HTMLInputElement, endDate: HTMLInputElement): void {
    this.isRefreshing = true;
    this.dateEvent.emit({
      start: startDate.value,
      end: endDate.value
    });
  }

  showModal(message: string, title: string): void {
    this.messageService.openCustomModal(GenericModalComponent, message, title);
  }
}
